const IconFlagColombia = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 12.5H32V21H0V12.5Z" fill="#2B3990" />
    <path
      d="M27 5H5C1.6865 5 0 7.4625 0 10.5V16H32V10.5C32 7.4625 30.3135 5 27 5Z"
      fill="#F9CB38"
    />
    <path
      d="M0 21.5C0 24.5375 1.6865 27 5 27H27C30.3135 27 32 24.5375 32 21.5V21H0V21.5Z"
      fill="#EC1C24"
    />
  </svg>
);

export default IconFlagColombia;
