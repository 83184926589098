import { Typography } from '#atoms/index';
import { DoteSpinner } from '#components/index';
const loader = ({ title }) => {
  return (
    <div className="loader-wrapper">
      <Typography variant="h3">{title}</Typography>
      <DoteSpinner />
    </div>
  );
};

export default loader;
