import dynamic from 'next/dynamic';

const ArrowIcon = dynamic(
  import('#helpers/icons/vacation-icons').then(module => module.ArrowIcon)
);

const WhatsAppLogo = dynamic(
  import('#helpers/icons/vacation-icons').then(module => module.WhatsAppLogo)
);

const DownloadIcon = dynamic(
  import('#helpers/icons/vacation-icons').then(module => module.DownloadIcon)
);

const ButtonComponent = ({
  testid = '',
  description,
  iconWhite,
  iconBlack,
  mainColor,
  textColor,
  variantPage,
  variantButton = 'contained',
  align = 'left',
  href = null,
  download = false,
  onClick,
  external = false,
  iconArrowColor = false,
  iconWhatsApp = false,
  iconDownload = false,
  width,
  boxShadow = true,
  onMouseLeave,
  onMouseOver
}) => {
  return (
    <div
      data-testid={testid}
      className={`button-component ${
        variantButton === 'outlined'
          ? 'outlined-black-button'
          : variantButton === 'contained'
          ? ''
          : variantButton === 'outlinedColor'
          ? ''
          : 'black-button'
      }`}
      style={{
        transition: 'all 0.25s ease',
        background:
          variantButton === 'outlined'
            ? 'transparent'
            : variantButton === 'contained'
            ? mainColor
            : variantButton === 'outlinedColor'
            ? 'transparent'
            : '#000000',
        color:
          variantButton === 'outlined' && textColor === undefined
            ? '#000000'
            : variantButton === 'contained' && textColor === undefined
            ? '#000000'
            : variantButton === 'outlinedColor' && textColor === undefined
            ? mainColor
            : textColor
            ? textColor
            : '#ffffff',
        border:
          variantButton === 'outlined'
            ? '2px solid #000000'
            : variantButton === 'contained'
            ? `2px solid ${mainColor}`
            : variantButton === 'outlinedColor'
            ? `2px solid ${mainColor}`
            : '2px solid #000000',
        boxShadow:
          variantButton === 'outlined' ||
          variantButton === 'outlinedColor' ||
          boxShadow === false
            ? 'none'
            : '0px 4.5122px 6.76829px rgba(0, 0, 0, 0.1)',
        width: width && width
      }}
      onClick={onClick}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
    >
      {download === true ? (
        <a
          href={href}
          download={download}
          target="_blank"
          style={{ display: 'flex', alignItems: 'center' }}
          rel="noreferrer"
        >
          {(iconWhite || iconBlack) && (
            <img
              className={align === 'left' ? 'icon-left' : 'icon-right'}
              src={variantPage === 'curso' ? iconBlack : iconWhite}
              alt="icono"
            />
          )}
          {iconDownload === true && <DownloadIcon fill={mainColor} />}
          {iconArrowColor === true && <ArrowIcon fill={mainColor} />}
          {iconWhatsApp === true && <WhatsAppLogo fill={mainColor} />}
          <span className={align === 'left' ? 'icon-right' : 'icon-left'}>
            {description}
          </span>
        </a>
      ) : (
        <a
          href={href}
          target={external === true ? '_blank' : '_self'}
          style={{ display: 'flex', alignItems: 'center' }}
          rel="noreferrer"
        >
          {(iconWhite || iconBlack) && (
            <img
              className={align === 'left' ? 'icon-left' : 'icon-right'}
              src={variantPage === 'curso' ? iconBlack : iconWhite}
              alt="icono"
            />
          )}
          {iconDownload === true && <DownloadIcon fill={mainColor} />}
          {iconArrowColor === true && <ArrowIcon fill={mainColor} />}
          {iconWhatsApp === true && <WhatsAppLogo fill={mainColor} />}
          <span className={align === 'left' ? 'icon-right' : 'icon-left'}>
            {description}
          </span>
        </a>
      )}
    </div>
  );
};

export default ButtonComponent;
