const IconArrowRight = ({ fill = '#000000', className }) => (
  <svg
    className={className}
    width="13"
    height="11"
    viewBox="0 0 13 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.758834 4.48558H9.75726L7.00311 1.22674C6.87432 1.0741 6.81236 0.877313 6.83086 0.67967C6.84936 0.482027 6.9468 0.299717 7.10174 0.172847C7.25668 0.0459779 7.45644 -0.0150596 7.65707 0.0031628C7.85769 0.0213852 8.04275 0.117375 8.17154 0.270015L11.9651 4.75466C11.9907 4.79033 12.0135 4.82781 12.0334 4.86678C12.0334 4.90415 12.0334 4.92657 12.0865 4.96395C12.1209 5.04965 12.1389 5.14087 12.1396 5.23303C12.1389 5.32518 12.1209 5.4164 12.0865 5.5021C12.0865 5.53948 12.0865 5.5619 12.0334 5.59927C12.0135 5.63824 11.9907 5.67572 11.9651 5.71139L8.17154 10.196C8.1002 10.2804 8.01087 10.3483 7.9099 10.3948C7.80892 10.4413 7.69879 10.4653 7.58732 10.4651C7.41005 10.4655 7.23824 10.4046 7.10174 10.2932C7.02491 10.2305 6.96141 10.1534 6.91486 10.0664C6.86831 9.97947 6.83963 9.88432 6.83047 9.78642C6.82131 9.68852 6.83184 9.5898 6.86147 9.49592C6.89109 9.40204 6.93922 9.31484 7.00311 9.23931L9.75726 5.98047H0.758834C0.557609 5.98047 0.364624 5.90172 0.222336 5.76155C0.0800485 5.62137 0.000113487 5.43126 0.000113487 5.23303C0.000113487 5.03479 0.0800485 4.84468 0.222336 4.7045C0.364624 4.56433 0.557609 4.48558 0.758834 4.48558Z"
      fill={fill}
    />
  </svg>
);

export default IconArrowRight;
