import Head from 'next/head';
import cx from 'classnames';

import Typography from '#app/UI/atoms/typography-updated';
import { FaqCardUpd } from '#atoms/index';

const createEntity = faqs => {
  return faqs?.map(faq => {
    if (typeof faq?.answer === 'string') {
      return {
        '@type': 'Question',
        name: faq?.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: faq?.answer
        }
      };
    } else if (typeof faq?.answer.props.children === 'object') {
      return {
        '@type': 'Question',
        name: faq?.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: faq?.answer.props.children
            .map(message => (typeof message === 'string' ? message : null))
            .filter(el => el != null)
            .join(' ')
        }
      };
    } else {
      return {
        '@type': 'Question',
        name: faq?.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: faq?.answer.props.children
        }
      };
    }
  });
};

const FaqTabUpd = ({
  faqs,
  fill,
  belongs_to_learning_path,
  invisible = false,
  isSeo = true,
  marginBottom = true,
  title,
  sectionFaqsTitle,
  eventNameAnalytics
}) => {
  if (!faqs) return <p>Loading...</p>;

  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: createEntity(faqs)
  };

  return (
    <section
      className={cx('faq_tab_upd-Wrapperr', {
        ['faq_tab_upd-Wrapperr--invisible']: invisible,
        ['faq_tab_upd-Wrapperr--margin']: marginBottom
      })}
    >
      {isSeo && (
        <Head>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
          />
        </Head>
      )}

      {sectionFaqsTitle ? (
        <div style={{ width: '100%' }}>{sectionFaqsTitle}</div>
      ) : (
        <Typography className="mb-12" tag="h2">
          Preguntas frecuentes
        </Typography>
      )}

      <p className="text-center" style={{ maxWidth: '600px', margin: 'auto' }}>
        {title}
      </p>

      <div className="faq_list">
        {faqs?.map((faq, idx) => (
          <FaqCardUpd
            title={faq?.question}
            description={faq?.answer}
            fill={fill}
            key={idx}
            belongs_to_learning_path={belongs_to_learning_path}
            eventNameAnalytics={eventNameAnalytics}
          />
        ))}
      </div>
    </section>
  );
};

export default FaqTabUpd;
