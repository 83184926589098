import { useState } from 'react';
import { useForm } from 'react-hook-form';

import {
 Typography, Link, SelectField, TextareaField, Button 
} from '#atoms/index';
import { useUserContext } from '#hooks/index';
import { Toast, CircleSpinner, InformationBox } from '#components/index';
import { asksFrecuencyContactUs } from '#helpers/data-helper';
import {
  EmailOutlineIcon,
  InstagramIcon,
  FacebookIcon,
  LinkedinIcon,
  YoutubeIcon,
  WhatsIcon,
  TiktokIcon
} from '#helpers/icons/contact-us';
import WhatsappIcon from '#helpers/icons/whatsapp-icon';
import { postCreateSupportMessage } from '#api/enrollment';
import { queryType } from '#helpers/formsUtils';
import { event } from '#helpers/gtag';

const ContactUsCard = ({ variant }) => {
  const { data: userData } = useUserContext();
  const [sending, setSending] = useState(false);
  const [status, setStatus] = useState({ msg: null, type: null });
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async data => {
    setSending(true);
    setStatus({ msg: null, type: null });
    try {
      const response = await postCreateSupportMessage({
        tipo_de_consulta: data.type_request,
        message: data.message
      });
      if (response.status === 200 || response.status === 201) {
        setSending(false);
        setStatus({
          msg: 'Datos enviados correctamente, en el transcurso del día soporte se pondrá en contacto',
          type: 'success'
        });
        event(
          'envio-formulario-contactar-soporte',
          'contactanos-form',
          'envío satisfactorio el formulario'
        );
        if (userData) {
          event(
            `envio-formulario-contactar-soporte': ${userData.email}`,
            'contactanos-form',
            'envío satisfactorio el formulario'
          );
        }
      } else {
        setSending(false);
        setStatus({
          msg: 'Ups, algo salió mal, intente de nuevo',
          type: 'error'
        });
      }
    } catch {
      setSending(false);
      setStatus({
        msg: 'Ups, algo salió mal, intente de nuevo',
        type: 'error'
      });
    }
  };

  const eventsAnalytics = type => {
    event(
      `clic-canal-contacto-${type}`,
      'contactanos-form',
      `clic en ${type} sección contactar a soporte`
    );
    if (userData.email) {
      event(
        `clic-canal-contacto-${type}: ${userData.email}`,
        'contactanos-form',
        `clic en ${type} sección contactar a soporte`
      );
    }
  };

  if (variant === 'form-dashboard') {
    return (
      <div className="contact_us_dashboard_card">
        <Typography variant="h2">Contáctanos</Typography>
        <form className="contact_us_form" onSubmit={handleSubmit(onSubmit)}>
          <SelectField
            id="type_request"
            name="type_request"
            placeholder="Motivo de la consulta o duda"
            options={queryType}
            variant="outline"
            register={register({ required: 'Este es un campo obligatorio' })}
            error={!!errors.type_request}
          />
          {errors.type_request && <Toast message={errors.type_request.message} />}

          <TextareaField
            id="message"
            name="message"
            placeholder="Mensaje"
            size="20"
            type="text"
            variant="material-design"
            register={register({ required: 'El mensaje es un campo obligatorio' })}
            error={!!errors.message}
            onChange={e => {
              if (e.target.value.length === 1) {
                event(
                  'agregando-mensaje',
                  'contactanos-form',
                  'iniciando llenado de formulario'
                );
              }
              if (e.target.value.length === 1 && userData) {
                event(
                  `agregando-mensaje': ${userData.email}`,
                  'contactanos-form',
                  'iniciando llenado de formulario'
                );
              }
            }}
          />
          {errors.message && <Toast message={errors.message.message} mtop="-10px" />}

          <Button type="submit" variant="radius">
            {!sending ? 'Enviar' : <CircleSpinner />}
          </Button>
          {status.msg &&
            (status.type === 'error' ? (
              <Toast message={status.msg} mtop="1rem" />
            ) : (
              <Toast message={status.msg} variant="success" mtop="1rem" />
            ))}
        </form>

        <InformationBox
          type="warning"
          message="🚨 Para una atención pronta puedes contactarnos por medio de WhatsApp"
        />

        <Typography variant="h4">Nuestros canales de contacto</Typography>
        <div className="social_media_bar">
          <div onClick={() => eventsAnalytics('instagram')}>
            <Link href="https://www.instagram.com/crackthecode.la/" external>
              <InstagramIcon />
            </Link>
          </div>

          <div onClick={() => eventsAnalytics('facebook')}>
            <Link href="https://www.facebook.com/crackthecode.la" external>
              <FacebookIcon />
            </Link>
          </div>
          <div onClick={() => eventsAnalytics('linkedin')}>
            <Link
              href="https://www.linkedin.com/company/crack-the-code-per%C3%BA/"
              external
            >
              <LinkedinIcon />
            </Link>
          </div>
          <div onClick={() => eventsAnalytics('youtube')}>
            <Link
              href="https://www.youtube.com/channel/UClgCHzMraznKb6g3B8ASuPA"
              external
            >
              <YoutubeIcon />
            </Link>
          </div>
          <div onClick={() => eventsAnalytics('whatsapp')}>
            <Link
              href="https://wa.me/51975071866?text=¡Hola! Tengo la siguiente pregunta"
              external
            >
              <WhatsIcon />
            </Link>
          </div>
          <div onClick={() => eventsAnalytics('tiktok')}>
            <Link href="https://www.tiktok.com/@crackthecodelatam?lang=es" external>
              <TiktokIcon />
            </Link>
          </div>
        </div>
        <div className="mail-section">
          <EmailOutlineIcon />
          <Typography variant="body">crack@crackthecode.la</Typography>
        </div>
      </div>
    );
  }

  return (
    <div className="contact_us_card">
      <Typography variant="h4">Nuestros canales de contacto</Typography>
      <div className="mail-section">
        <EmailOutlineIcon />
        <Typography variant="body">crack@crackthecode.pe</Typography>
      </div>
      <div className="social_media_bar">
        <Link href="https://www.instagram.com/crackthecode.la/" external>
          <InstagramIcon />
        </Link>
        <Link href="https://www.facebook.com/crackthecode.la" external>
          <FacebookIcon />
        </Link>
        <Link href="https://www.linkedin.com/company/crack-the-code-per%C3%BA/" external>
          <LinkedinIcon />
        </Link>
        <Link href="https://www.youtube.com/channel/UClgCHzMraznKb6g3B8ASuPA" external>
          <YoutubeIcon />
        </Link>
        <Link
          href="https://wa.me/17867892469?text=¡Hola!, Tengo la siguiente pregunta"
          external
        >
          <WhatsIcon />
        </Link>
        <Link href="https://www.tiktok.com/@crackthecodelatam?lang=es" external>
          <TiktokIcon />
        </Link>
      </div>
      <div className="ask_frecuency-section">
        <Typography variant="h4">Preguntas frecuentes</Typography>
        {asksFrecuencyContactUs.map((ask, idx) => (
          <ul className="ask_frecuency" key={idx}>
            <li>{ask.ask}</li>
            <Typography variant="body">{ask.answer}</Typography>
          </ul>
        ))}
      </div>
      <div className="btn-whasapp">
        <Link
          variant="whatsapp"
          external
          href="https://wa.me/17867892469?text=¡Hola!, Tengo la siguiente pregunta"
        >
          ¿Tienes alguna pregunta?
          <WhatsappIcon />
        </Link>
      </div>
    </div>
  );
};

export default ContactUsCard;
