const IconCertificate = ({ fill = '#000000', className }) => (
  <svg
    width="116"
    height="76"
    viewBox="0 0 116 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_dd_626_25754)">
      <rect
        x="5"
        y="1"
        width="106"
        height="66"
        rx="4"
        fill="white"
        stroke="#9F70F4"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.3214 17.4403C65.3214 21.0289 62.3468 23.9381 58.6775 23.9381C55.0082 23.9381 52.0337 21.0289 52.0337 17.4403C52.0337 13.8516 55.0082 10.9425 58.6775 10.9425C62.3468 10.9425 65.3214 13.8516 65.3214 17.4403ZM67.2196 17.4403C67.2196 22.0543 63.3952 25.7946 58.6775 25.7946C53.9598 25.7946 50.1354 22.0543 50.1354 17.4403C50.1354 12.8263 53.9598 9.08594 58.6775 9.08594C63.3952 9.08594 67.2196 12.8263 67.2196 17.4403ZM58.6831 22.0808C61.304 22.0808 63.4287 20.0028 63.4287 17.4395C63.4287 14.8762 61.304 12.7982 58.6831 12.7982C56.0622 12.7982 53.9375 14.8762 53.9375 17.4395C53.9375 20.0028 56.0622 22.0808 58.6831 22.0808ZM51.8898 23.9297L48.1316 30.2961L52.5569 29.1364L53.7371 33.4441L57.7316 26.6775C55.4503 26.457 53.4064 25.4466 51.8898 23.9297ZM59.5213 26.6867L63.5126 33.4477L64.6936 29.1368L69.1157 30.2957L65.3965 23.9955C63.8646 25.4976 61.8096 26.4892 59.5213 26.6867ZM53.3293 34.1349L47.7314 30.974L47.7292 30.9778L53.3271 34.1387L53.3293 34.1349ZM69.5171 30.9756L63.9192 34.1365L63.9205 34.1387L69.5184 30.9778L69.5171 30.9756ZM36.875 37.1999H80.75V40.3999H36.875V37.1999ZM45 43.6003H71.8764V47.0288H45V43.6003ZM86.4533 56.3997H70.0938V58.6854H86.4533V56.3997ZM30.375 56.3997H46.7346V58.6854H30.375V56.3997Z"
        fill="url(#paint0_linear_626_25754)"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_626_25754"
        x="0"
        y="0"
        width="116"
        height="76"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_626_25754"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_626_25754"
          result="effect2_dropShadow_626_25754"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_626_25754"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_626_25754"
        x1="58.4141"
        y1="8.66667"
        x2="58.4141"
        y2="58.6854"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B15EF2" />
        <stop offset="1" stopColor="#5CAEFA" />
      </linearGradient>
    </defs>
  </svg>
);

export default IconCertificate;
