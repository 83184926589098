import {
 format, parseISO, addDays, isWithinInterval, differenceInDays 
} from 'date-fns';

const getFrequencyDay = frequency => {
  const daysOfWeek = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
  ];
  return daysOfWeek.indexOf(frequency.toLowerCase());
};

export const nextCalendarDate = (startDate, endDate, frecuencia = 'monday') => {
  const frequencyDay = getFrequencyDay(frecuencia);

  if (frequencyDay === -1) {
    // eslint-disable-next-line no-console
    console.error('Invalid frequency');
  }

  let currentDate = new Date();
  let startDateObj = new Date(startDate);
  let endDateObj = new Date(endDate);

  if (currentDate < startDateObj) {
    currentDate = startDateObj;
  }

  // Calculate the total number of specified days between startDate and endDate
  let totalDays = 0;
  for (let d = new Date(startDateObj); d <= endDateObj; d.setDate(d.getDate() + 1)) {
    if (d.getDay() === frequencyDay) {
      totalDays++;
    }
  }

  // Calculate the number of specified days that have already passed
  let pastDays = 0;
  for (let d = new Date(startDateObj); d < currentDate; d.setDate(d.getDate() + 1)) {
    if (d.getDay() === frequencyDay) {
      pastDays++;
    }
  }

  // Calculate the difference between the current day and the target day
  let difference = frequencyDay - currentDate.getDay();
  if (difference <= 0) {
    difference += 7;
  }

  // Add the difference to the current date
  currentDate.setDate(currentDate.getDate() + difference);

  // Check if the new date is within the specified range
  if (currentDate > endDateObj) {
    // eslint-disable-next-line no-console
    console.error('No available date found within the specified range');
  }

  return {
    date: currentDate.toISOString().split('T')[0],
    remainingDays: totalDays - pastDays + 1
  };
};

export const getSplitRecurrence = ({
  startDate = format(new Date(), 'yyyy-MM-dd'),
  endDate = format(new Date(), 'yyyy-MM-dd'),
  frequency = 'monday',
  name,
  description,
  startTime,
  endTime
}) => {
  const frequencyDay = getFrequencyDay(frequency);

  if (frequencyDay === -1) {
    // eslint-disable-next-line no-console
    console.error('Invalid frequency');
  }

  let currentDate = new Date();
  const startDateObj = parseISO(startDate);
  const endDateObj = parseISO(endDate);

  if (currentDate < startDateObj) {
    currentDate = startDateObj;
  }

  // Calcular la diferencia entre el día actual y el próximo día de la semana especificado
  const daysToNextFrequency = (frequencyDay - currentDate.getDay() + 7) % 7;
  currentDate = addDays(currentDate, daysToNextFrequency);

  const events = [];

  for (
    let date = currentDate;
    isWithinInterval(date, { start: startDateObj, end: addDays(endDateObj, 1) });
    date = addDays(date, 7)
  ) {
    const daysDifference = differenceInDays(date, new Date());
    events.push({
      name,
      description,
      startDate: `today+${daysDifference}`,
      startTime,
      endTime
    });
  }

  return events;
};
