const IconLogoutUser = ({ fill = '#000000', className, dataTestid = 'logout-user' }) => (
  <svg
    className={className}
    data-testid={dataTestid}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={`${className}-path`}
      data-testid={`${dataTestid}-path`}
      d="M1.98238 15.5C1.43172 15.5 0.963877 15.3083 0.578855 14.9249C0.192952 14.5425 0 14.0777 0 13.5306V4.34026C0 3.79322 0.192952 3.32845 0.578855 2.94595C0.963877 2.56258 1.43172 2.3709 1.98238 2.3709C2.53304 2.3709 3.00088 2.56258 3.3859 2.94595C3.77181 3.32845 3.96476 3.79322 3.96476 4.34026V8.60722L11.5308 1.09081C11.9273 0.696937 12.4009 0.5 12.9515 0.5C13.5022 0.5 13.9868 0.707878 14.4053 1.12363C14.8018 1.51751 15 1.99891 15 2.56783C15 3.13676 14.8018 3.61816 14.4053 4.01204L6.80617 11.5613H11.2335C11.7841 11.5613 12.252 11.753 12.637 12.1363C13.0229 12.5188 13.2159 12.9836 13.2159 13.5306C13.2159 14.0777 13.0229 14.5425 12.637 14.9249C12.252 15.3083 11.7841 15.5 11.2335 15.5H1.98238Z"
      fill={fill}
    />
  </svg>
);

export default IconLogoutUser;
