import { shape } from 'prop-types';
import Link from 'next/link';

import { Image, Typography, Icon } from '#atoms/index';
import { parseDateRange } from '#helpers/parsers';

const CourseTile = ({ data }) => (
  <Link href={`/cursos/${data.fields.slug}`}>
    <a>
      <article className="course-tile">
        <Image
          src={data.fields.backgroundimage && data.fields.backgroundimage.fields.file.url}
          alt={data.fields.name}
          variant="course-tile"
        />
        <Typography variant="h5" tag="h3">
          {data.fields.name}
        </Typography>
        <div className="course-tile__description">
          <Typography variant="body2" color="grey">
            {data.fields.shortDescriptionForGrid || data.fields.shortDescription}
          </Typography>
        </div>
        <footer className="course-tile__footer">
          <Typography variant="body2" color="grey" fontWeight="medium">
            <Icon icon="date_range" />
            {data.fields.initialDate && (
              <span>{parseDateRange(data.fields.initialDate, data.fields.endDate)}</span>
            )}
            {!data.fields.initialDate && <span>Sin fecha de inicio</span>}
          </Typography>
          <Typography variant="body2" color="grey" fontWeight="medium">
            {data.fields.ageRange.fields && (
              <>
                <Icon icon="tag_faces" />
                <span>
                  De {data.fields.ageRange.fields && data.fields.ageRange.fields.name}{' '}
                  años
                </span>
              </>
            )}
          </Typography>
        </footer>
      </article>
    </a>
  </Link>
);

CourseTile.propTypes = { data: shape().isRequired };

export default CourseTile;
