import cx from 'classnames';
import NextJsImage from 'next/image';
import { oneOf, string } from 'prop-types';

const Image = ({ src, alt, variant = 'default' }) => (
  <picture className={cx('image', `image--${variant}`)}>
    <NextJsImage src={src} alt={`${alt} | Crack The Code`} />
  </picture>
);

Image.propTypes = {
  variant: oneOf([
    'default',
    'course-tile',
    'teacher-avatar',
    'trust-in-us-logo',
    'my-course-tile'
  ]),
  src: string.isRequired,
  alt: string.isRequired
};

export default Image;
