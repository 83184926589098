const IconFlagPeru = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11 5H21V27H11V5Z" fill="#F1F1F1" />
    <path
      d="M5 5C1.6865 5 0 7.4625 0 10.5V21.5C0 24.5375 1.6865 27 5 27H11V5H5ZM27 5H21V27H27C30.3135 27 32 24.5375 32 21.5V10.5C32 7.4625 30.3135 5 27 5Z"
      fill="#EC1C24"
    />
  </svg>
);

export default IconFlagPeru;
