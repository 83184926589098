import axios from 'axios';

import { client } from './contentful/client';
import { baseUrl } from './baseUrl';
import { handleInvalidToken } from './user';

export const postEnrollCourse = async data => {
  const response = await fetch(`${baseUrl}/api/enrollments/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}`
    },
    body: JSON.stringify(data)
  });
  return response.json();
};

export const getMyEnrollments = async () => {
  try {
    const response = await fetch(`${baseUrl}/api/enrollments/`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}`
      }
    });

    const responseJson = await response.json();

    let finalResponse = [];
    const fetchs = responseJson.data.map(course => async () => {
      const responsePerType = await client.getEntry(course.course_id);
      finalResponse = [...finalResponse, { ...course, ...responsePerType }];
    });
    await Promise.all(fetchs.map(async method => await method()));

    return finalResponse;
  } catch (e) {
    handleInvalidToken({ redirect: '/' });
  }
};

export const getMyEnrolments = async () => {
  try {
    const response = await axios.get(`${baseUrl}/api/enrolments/`, {headers: { Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}` }});
    return response;
  } catch (e) {
    handleInvalidToken({ redirect: '/' });
  }
};

export const patchAssignCourse = async (id, data) => {
  try {
    const response = await axios.patch(`${baseUrl}/api/enrollments/${id}/`, data, {headers: { Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}` }});
    return response;
  } catch (e) {
    //handleInvalidToken({ redirect: '/' });
  }
};

export const patchAssignLearningPathCourse = async (id, data) => {
  try {
    const response = await axios.patch(`${baseUrl}/api/invoices/${id}/enrolments`, data, {headers: { Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}` }});
    return response;
  } catch (e) {
    //handleInvalidToken({ redirect: '/' });
  }
};

export const postSendPaymentEmail = async (id, data = '') => {
  try {
    const response = await axios.post(`${baseUrl}/api/invoices/${id}/paid-email`, data, {headers: { Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}` }});
    return response;
  } catch (e) {
    // console.log(e);
  }
};

export const patchInvoiceDone = async (id, data) => {
  try {
    const response = await axios.patch(`${baseUrl}/api/invoices/${id}/done`, data, {headers: { Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}` }});
    return response;
  } catch (e) {
    // console.log(e);
  }
};

export const postInvoiceCouponAdd = async (id, data) => {
  try {
    const response = await axios.post(`${baseUrl}/api/invoices/${id}/couponadd`, data, {headers: { Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}` }});
    return response;
  } catch (e) {
    // console.log(e);
  }
};

export const getCourseBySlugReferrer = async (slug, data) => {
  try {
    const response = await axios.post(`${baseUrl}/api/referral/courses/${slug}/`, data, {headers: { Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}` }});
    return response;
  } catch (e) {
    // console.log(e);
  }
};

export const getPathBySlugReferrer = async (slug, data) => {
  try {
    const response = await axios.post(`${baseUrl}/api/referral/paths/${slug}/`, data, {headers: { Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}` }});
    return response;
  } catch (e) {
    // console.log(e);
  }
};

export const getProgramBySlugReferrer = async (slug, data) => {
  try {
    const response = await axios.post(`${baseUrl}/api/referral/group/${slug}/`, data, {headers: { Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}` }});
    return response;
  } catch (e) {
    // console.log(e);
  }
};
export const patchReferalUpdateInvoice = async (id, data) => {
  try {
    const response = await axios.patch(`${baseUrl}/api/referral/invoice/${id}/`, data, {headers: { Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}` }});
    return response;
  } catch (e) {
    // console.log(e);
  }
};

export const postInvoiceInscriptionEmail = async (id, data) => {
  try {
    const response = await axios.post(`${baseUrl}/api/invoices/${id}/email`, data, {headers: { Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}` }});
    return response;
  } catch (e) {
    // console.log(e);
  }
};

// ? course to user recruitment
export const getCourseRecruitment = async () => {
  const response = await axios.get(`${baseUrl}/api/products/catch-users/`);
  return response;
};

// get course summer
export const getCourseSummer = async () => {
  const response = await axios.get(`${baseUrl}/api/products/campaigns/`);
  return response;
};

export const getBanners = async () => {
  try {
    const response = await axios.get(`${baseUrl}/api/banners/dashboard/`);
    return response;
  } catch (e) {
    //handleInvalidToken({ redirect: '/' });
  }
};
