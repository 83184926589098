import dynamic from 'next/dynamic';

const ArrowIcon = dynamic(
  import('#helpers/icons/vacation-icons').then(module => module.ArrowIcon)
);

const WhatsAppLogo = dynamic(
  import('#helpers/icons/vacation-icons').then(module => module.WhatsAppLogo)
);

const DownloadIcon = dynamic(
  import('#helpers/icons/vacation-icons').then(module => module.DownloadIcon)
);

const ButtonComponentVacation = ({
  description,
  iconWhite,
  iconBlack,
  mainColor,
  textColor,
  variantPage,
  variantButton = 'contained',
  align = 'left',
  href = '',
  download = false,
  onClick,
  external = false,
  iconArrowColor = false,
  iconWhatsApp = false,
  iconDownload = false,
  width,
  boxShadow = false,
  onMouseLeave,
  onMouseOver,
  hoverClassColor,
  background
}) => {
  return (
    <div
      className={`
				button-component-effect  ${
          variantButton === 'outlined'
            ? 'button-component-effect--outlined'
            : variantButton === 'outlinedColor'
            ? `button-component-effect--outlined-color-${hoverClassColor}`
            : variantButton === 'contained'
            ? 'button-component-effect--contained'
            : variantButton === 'containedColor'
            ? `button-component-effect--contained-color-${hoverClassColor}`
            : variantButton === 'containedColorAlternative'
            ? `button-component-effect--contained-color-alternative-${hoverClassColor}`
            : ''
        } `}
      style={{
        transition: 'all 0.6s ease',
        width: width && width,
        background: background && background,
        border: background && `1px solid ${background}`
      }}
      onClick={onClick}
    >
      {download === true ? (
        <a
          href={href}
          download={download}
          target="_blank"
          style={{ display: 'flex', alignItems: 'center', color: textColor }}
          rel="noreferrer"
        >
          {(iconWhite || iconBlack) && (
            <img
              className={align === 'left' ? 'icon-left' : 'icon-right'}
              src={variantPage === 'curso' ? iconBlack : iconWhite}
              alt="icono"
            />
          )}
          {iconDownload === true && (
            <DownloadIcon
              className={variantButton === 'outlined' ? 'fillColor' : ''}
              fill={variantButton === 'outlined' ? '#000000' : mainColor}
            />
          )}
          {iconArrowColor === true && (
            <ArrowIcon fill={variantButton === 'outlined' ? '#000000' : mainColor} />
          )}
          {iconWhatsApp === true && (
            <WhatsAppLogo fill={variantButton === 'outlined' ? '#000000' : mainColor} />
          )}
          <span className={align === 'left' ? 'icon-right' : 'icon-left'}>
            {description}
          </span>
        </a>
      ) : (
        <a
          href={href}
          target={external === true ? '_blank' : '_self'}
          style={{ display: 'flex', alignItems: 'center', color: textColor }}
          rel="noreferrer"
        >
          {(iconWhite || iconBlack) && (
            <img
              className={align === 'left' ? 'icon-left' : 'icon-right'}
              src={variantPage === 'curso' ? iconBlack : iconWhite}
              alt="icono"
            />
          )}
          {iconDownload === true && (
            <DownloadIcon
              className={variantButton === 'outlined' ? 'fillColor' : ''}
              fill={variantButton === 'outlined' ? '#000000' : mainColor}
            />
          )}
          {iconArrowColor === true && (
            <ArrowIcon fill={variantButton === 'outlined' ? '#000000' : mainColor} />
          )}
          {iconWhatsApp === true && (
            <WhatsAppLogo
              className={variantButton === 'outlined' ? 'fillColor' : ''}
              fill={variantButton === 'outlined' ? '#000000' : mainColor}
            />
          )}
          <span className={align === 'left' ? 'icon-right' : 'icon-left'}>
            {description}
          </span>
        </a>
      )}
    </div>
  );
};

export default ButtonComponentVacation;
