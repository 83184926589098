const IconLinkedIn = ({ fill = '#000000', className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 21H9V9.00002H13V11C13.8526 9.91527 15.1456 9.26859 16.525 9.23702C19.0056 9.25079 21.0072 11.2694 21 13.75V21H17V14.25C16.84 13.1326 15.8818 12.3036 14.753 12.306C14.2593 12.3216 13.7932 12.5378 13.4624 12.9046C13.1316 13.2715 12.9646 13.7573 13 14.25V21ZM7 21H3V9.00002H7V21ZM5 7.00002C3.89543 7.00002 3 6.10458 3 5.00002C3 3.89545 3.89543 3.00002 5 3.00002C6.10457 3.00002 7 3.89545 7 5.00002C7 5.53045 6.78929 6.03916 6.41421 6.41423C6.03914 6.7893 5.53043 7.00002 5 7.00002Z"
      fill={fill}
    />
    <path
      d="M13 21H9V9.00002H13V11C13.8526 9.91527 15.1456 9.26859 16.525 9.23702C19.0056 9.25079 21.0072 11.2694 21 13.75V21H17V14.25C16.84 13.1326 15.8818 12.3036 14.753 12.306C14.2593 12.3216 13.7932 12.5378 13.4624 12.9046C13.1316 13.2715 12.9646 13.7573 13 14.25V21ZM7 21H3V9.00002H7V21ZM5 7.00002C3.89543 7.00002 3 6.10458 3 5.00002C3 3.89545 3.89543 3.00002 5 3.00002C6.10457 3.00002 7 3.89545 7 5.00002C7 5.53045 6.78929 6.03916 6.41421 6.41423C6.03914 6.7893 5.53043 7.00002 5 7.00002Z"
      fill={fill}
    />
  </svg>
);

export default IconLinkedIn;
