import { func, node, string } from 'prop-types';

import { ButtonIcon, Typography } from '#atoms/index';

const SideModal = ({ children, title, onClose }) => {
  return (
    <div className="side-modal">
      <div className="side-modal__content">
        <header className="side-modal__header">
          <ButtonIcon icon="arrow_back" onClick={onClose} />
          <Typography>{title}</Typography>
        </header>
        <section className="side-modal__body">{children}</section>
      </div>
    </div>
  );
};

SideModal.propTypes = {
  children: node,
  title: string,
  onClose: func.isRequired
};

export default SideModal;
