const DropdownTinyIcon = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="8"
    viewBox="0 0 16 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99997 7.50002C7.73114 7.50002 7.46235 7.40998 7.25739 7.23029L0.807713 1.5729C0.397429 1.21302 0.397429 0.629535 0.807713 0.269801C1.21783 -0.0899336 1.8829 -0.0899336 2.29322 0.269801L7.99997 5.27577L13.7068 0.269976C14.117 -0.0897588 14.7821 -0.0897588 15.1921 0.269976C15.6026 0.62971 15.6026 1.21319 15.1921 1.57307L8.74256 7.23046C8.5375 7.41018 8.26871 7.50002 7.99997 7.50002Z"
      fill="black"
    />
  </svg>
);

export default DropdownTinyIcon;
