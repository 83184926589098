import { array, func, string } from 'prop-types';

const Select = ({
 label, name, placeholder = 'Todos', options, onChange 
}) => (
  <div className="select" onChange={ev => onChange(name, ev.target.value)}>
    <label htmlFor={name}>{label}</label>
    <select id={name} name={name}>
      <option value="">{placeholder}</option>
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
    <span />
  </div>
);

Select.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  placeholder: string,
  options: array.isRequired,
  onChange: func
};

export default Select;
