import React from 'react';
import { useRouter } from 'next/router';

import { Paragraph, Button, Image } from '#styleguide/index';
import { useUserContext } from '#hooks/index';
import { event } from '#helpers/gtag';

const CardCertificateDetails = ({ learning_path_next, colorsCourse }) => {
  const router = useRouter();
  const { data: userData } = useUserContext();

  const handleClickNextPath = () => {
    router.push(`/rutas/${learning_path_next[0]?.slug}`);
    event(
      'click-detalle-nivel-2',
      'zona-padres',
      'clic en botón ver detalles 2do nivel en zona de padres'
    );
    event(
      `click-detalle-nivel-2: ${userData.email}`,
      'zona-padres',
      'clic en botón ver detalles 2do nivel en zona de padres'
    );
  };

  return (
    <div className="ui-card-certificate-details">
      <div className="ui-card-certificate-details__image-container">
        {learning_path_next.length > 0 || learning_path_next[0]?.thumbnail ? (
          <Image
            src={learning_path_next[0]?.thumbnail || '/certificate/img-no-next-path.webp'}
            alt="ruta"
            width="118"
            height="107"
          />
        ) : (
          <Image
            src="/certificate/img-no-next-path.webp"
            alt="cursos"
            width="118"
            height="107"
          />
        )}
      </div>{' '}
      <div className="ui-card-certificate-details__details-text">
        {learning_path_next.length > 0 ? (
          <>
            <Paragraph size="14" align="center" color="#FFFFFF" weight={700}>
              ¡Y hay más en el{' '}
              <span style={{ color: colorsCourse?.main_color }}>2do nivel</span> de la
              ruta!
            </Paragraph>
            <Button
              level="secondary"
              size="small"
              isNightMode={true}
              onClick={handleClickNextPath}
            >
              Ver Detalles
            </Button>
          </>
        ) : (
          <>
            <Paragraph size="14" align="center" color="#FFFFFF" weight={700}>
              ¡Descubre muchos otros cursos!
            </Paragraph>
            <Button
              level="secondary"
              size="small"
              isNightMode={true}
              onClick={() => router.push('/cursos')}
            >
              Ver cursos
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default CardCertificateDetails;
