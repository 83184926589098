import dynamic from 'next/dynamic';
import React from 'react';

// Dynamic Components
const DynamicCardCertificateContent = dynamic(() => import('./card-certificate-content'));
const DynamicCardCardCertificateDetails = dynamic(() =>
  import('./card-certificate-details')
);

const CardFinalCertificateProgress = ({
  colorsCourse,
  hasCertificatePath,
  childName,
  numberLastElement,
  pathDetails
}) => {
  const { learning_path_next } = pathDetails;
  let transform = `translate(calc(${-100 * numberLastElement}% - ${
    numberLastElement * 32
  }px) ,0)`;

  return (
    <div
      className="ui-card-final-certificate-progress"
      style={{ background: colorsCourse.main_color, transform: transform }}
    >
      <DynamicCardCertificateContent
        hasCertificatePath={hasCertificatePath}
        childName={childName}
      />
      <DynamicCardCardCertificateDetails
        learning_path_next={learning_path_next}
        colorsCourse={colorsCourse}
      />
    </div>
  );
};

export default CardFinalCertificateProgress;
