import { bool } from 'prop-types';
import { useRouter } from 'next/router';

import { useWindowSize, useUserContext } from '#hooks/index';
import { Link } from '#atoms/index';
import {
  LogoHeader,
  IconDashboard,
  IconFrequentQuestions,
  IconContactUs,
  IconPayments,
  IconInstagram,
  IconFacebook,
  IconLinkedIn,
  Paragraph
} from '#styleguide/index';
import { CloseIcon } from '#helpers/icons/close-icon';
import { guardianMenu } from '#helpers/data-sidebar-menu';
import { event } from '#helpers/gtag';

const Sidebar = ({ showSidebar, setShowSidebar }) => {
  const { data: userData } = useUserContext();
  const { isMobile } = useWindowSize();
  const router = useRouter();

  const onRedirect = url => () => {
    setShowSidebar(false);
    return router.push(url);
  };

  const renderIcon = (currentPosition, status) => {
    switch (currentPosition) {
      case 0:
        return <IconDashboard fill={status === 'active' ? '#9C56E0' : '#000000'} />;
      case 1:
        return (
          <IconFrequentQuestions fill={status === 'active' ? '#9C56E0' : '#000000'} />
        );
      case 2:
        return <IconPayments fill={status === 'active' ? '#9C56E0' : '#000000'} />;
      default:
        return <IconContactUs fill={status === 'active' ? '#9C56E0' : '#000000'} />;
    }
  };

  return (
    <div className={showSidebar ? 'mobil_sidebar' : 'sidebar_Tile'}>
      <div style={{ width: '100%' }}>
        <div className="sidebar_header">
          <LogoHeader />
          {isMobile && (
            <div className="close_icon" onClick={() => setShowSidebar(false)}>
              <CloseIcon />
            </div>
          )}
        </div>
        <div className="sidebar_menu">
          {guardianMenu.map((opc, idx) => (
            <div
              key={idx}
              className={`menu_item ${opc.url === router.asPath && 'active'}`}
              onClick={onRedirect(opc.url)}
            >
              {renderIcon(idx, opc.url === router.asPath ? 'active' : 'inactive')}
              {opc.url === router.asPath ? (
                <div
                  onClick={() => {
                    event(
                      `acceso-zona-de-padres: ${opc.name.replace(' ', '-')}`,
                      'zona-padres-sidebar',
                      `clic en menu item ${opc.name}`
                    );
                    event(
                      `acceso-zona-de-padres: ${opc.name.replace(' ', '-')} ${
                        userData.email
                      }`,
                      'zona-padres-sidebar',
                      `clic en menu item ${opc.name}`
                    );
                  }}
                >
                  <Paragraph size="16" color="#9C56E0" weight={700}>
                    {opc.name}
                  </Paragraph>
                </div>
              ) : (
                <div
                  onClick={() => {
                    event(
                      `acceso-zona-de-padres-${opc.name.replace(' ', '-')}`,
                      'zona-padres',
                      `clic en menu item ${opc.name}`
                    );
                    event(
                      `acceso-zona-de-padres-${opc.name.replace(' ', '-')}: ${
                        userData.email
                      }`,
                      'zona-padres',
                      `clic en menu item ${opc.name}`
                    );
                  }}
                >
                  <Paragraph size="16" color="#000000">
                    {opc.name}
                  </Paragraph>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="sidebar_footer">
        <hr />
        <div className="sidebar_social-red">
          <Link
            href="https://www.facebook.com/crackthecode.la"
            external={true}
            isFullWidth={false}
          >
            <IconFacebook />
          </Link>
          <Link
            href="https://www.instagram.com/crackthecode.la/"
            external={true}
            isFullWidth={false}
          >
            <IconInstagram />
          </Link>
          <Link
            href="https://www.linkedin.com/company/crack-the-code-per%C3%BA/"
            external={true}
            isFullWidth={false}
          >
            <IconLinkedIn />
          </Link>
        </div>
      </div>
      {/* <div className="sidebar_footer">
        <NeedSupportCard url="/dashboard/contactar-a-soporte" />
      </div> */}
    </div>
  );
};

Sidebar.propTypes = { showSidebar: bool };

export default Sidebar;
