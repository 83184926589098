import React, { useEffect, useState, useReducer } from 'react';
import cl from 'classnames';

import { Loader } from '#components/index';
import { addFields, loadFields } from '#helpers/smart-fields-helper';
import { reducer, initialState } from '#helpers/reducer';

const ContainerCheckout = props => {
  const [fieldsBrand, setFieldsBrand] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showValidations, setShowValidations] = useState({ name: false });
  const [isEditing, setIsEditing] = useState({ name: false });

  const setEmpty = (field, value) => {
    dispatch({ type: 'set-empty', payload: { field, value } });
  };

  const setComplete = (field, value) => {
    dispatch({ type: 'set-complete', payload: { field, value } });
  };

  const setErrors = (field, value) => {
    dispatch({ type: 'set-errors', payload: { field, value } });
  };

  const setDlocalInstance = dlocalInstance => {
    dispatch({ type: 'set-dlocal-instance', payload: dlocalInstance });
  };

  const setFields = (field, value) => {
    dispatch({ type: 'set-fields', payload: { field, value } });
  };

  useEffect(() => {
    const smartFieldsConfig = {
      url: process.env.NEXT_PUBLIC_DLOCAL_URL,
      country: props.countryCode,
      api_key: process.env.NEXT_PUBLIC_DLOCAL_PE_API_KEY
    };

    addFields(
      () =>
        loadFields(
          smartFieldsConfig,
          false,
          setDlocalInstance,
          setFields,
          props.setLoading,
          setEmpty,
          setComplete,
          setErrors,
          setFieldsBrand
        ),
      smartFieldsConfig
    );
  }, []);

  const handleSubmitFormSmartFields = event => {
    event.preventDefault();
    let hasErrors =
      state.errors.pan || state.errors.expiration || state.errors.cvv || props.validate();

    if (state.empty.pan) {
      setErrors('pan', state.empty.pan);
      hasErrors = true;
    }
    if (state.empty.expiration) {
      setErrors('expiration', state.empty.expiration);
      hasErrors = true;
    }
    if (state.empty.cvv) {
      setErrors('cvv', state.empty.cvv);
      hasErrors = true;
    }

    if (
      hasErrors ||
      (!fieldsBrand && state.errors.pan) ||
      !state.complete.expiration ||
      !state.complete.cvv
    ) {
      return;
    }

    props.setLoading(true);
    state.dlocalInstance
      .createToken(state.fields.pan, { name: props.fieldsValues.name })
      .then(response => {
        const token = response.token;
        //pay with token
        props.onSubmit(token);
        props.setTokenDlocal(token);
      })
      .catch(() => {
        props.setLoading(false);
      });
  };

  const handleChangeFormSmartFields = event => {
    event.preventDefault();
    const { name, value } = event.target;
    const { fieldsValues } = props;
    const newFields = { ...fieldsValues, [name]: value };
    let newShowValidations = { ...showValidations, [name]: true };
    setShowValidations(newShowValidations);
    props.onChange(newFields);
  };

  const handleFocusFormSmartFields = (event, focus) => {
    const { name } = event.target;
    let newIsEditing = { name: false };
    newIsEditing[name] = focus;
    setIsEditing(newIsEditing);
  };

  const resetFormSmartFields = event => {
    event.preventDefault();
    var form = document.querySelector('form');
    form.reset();

    // Clear each Smart-Field.
    state.fields.pan.clear();
    state.fields.cvv.clear();
    state.fields.expiration.clear();

    props.onReset();
  };

  return (
    <div className="checkout">
      <div className="content">
        <form id="fields-form" onSubmit={handleSubmitFormSmartFields}>
          <div className="fieldset">
            <div
              className={cl('overlay opaque', {
                visible: props.isLoading || props.success,
                hidden: !(props.isLoading || props.success)
              })}
            />
            <div
              id="loading-container"
              className={cl('container loader', {
                visible: props.isLoading,
                hidden: !props.isLoading
              })}
            >
              <Loader color="blue" />
            </div>
            {props.success && (
              <div id="success-container" className={cl('container success')}>
                <div id="close-btn" onClick={event => resetFormSmartFields(event)}>
                  X
                </div>
                <div className="tick-container" key="tick-container-paid">
                  <div className="trigger" />
                </div>
                <p key="paragraph-paid">Thanks you for your payment.</p>
              </div>
            )}
            <fieldset>
              <div
                id="fieldNameContainer"
                className={cl({hasError: false && !isEditing.name && showValidations.name})}
              >
                <div className="field">
                  <input
                    id="cardholder-name"
                    className="input"
                    type="text"
                    name="name"
                    placeholder="John Doe"
                    required={true}
                    onChange={e => handleChangeFormSmartFields(e)}
                    onFocus={e => handleFocusFormSmartFields(e, true)}
                    onBlur={e => handleFocusFormSmartFields(e, false)}
                    autoComplete="name"
                  />
                </div>
              </div>
              <div id="fieldPanContainer" className={cl({ hasError: state.errors.pan })}>
                <div className="field">
                  <div id="containerPan"></div>
                </div>
              </div>
              <div className="container">
                <div
                  id="fieldExpirationContainer"
                  className={cl('half', { hasError: state.errors.expiration })}
                >
                  <div className="field">
                    <div id="containerExpiration"></div>
                  </div>
                </div>
                <div
                  id="fieldCVVContainer"
                  className={cl('half', { hasError: state.errors.cvv })}
                >
                  <div className="field">
                    <div id="containerCVV"></div>
                  </div>
                </div>
              </div>
            </fieldset>
            <div className="actions">
              <button type="submit" id="form.pay_button" className="checkout-btn">
                PAY
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContainerCheckout;
