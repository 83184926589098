import React from 'react';

import { Logo } from '#atoms/index';

const LogoHeader = () => {
  return (
    <div className="login-perfiles__container-logo">
      <Logo color="dark" />
    </div>
  );
};

export default LogoHeader;
