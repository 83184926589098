import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { IconCircleStep, IconStarStep } from '#styleguide/index';
import {
  getTimeZone,
  getTimeNumber,
  renderRecurringDaysCalendar
} from '#helpers/parsers';
import { nextCalendarDate, getSplitRecurrence } from '#helpers/getFrecuency';

import Paragraph from './typography/paragraph';

const Step = ({
  name,
  number,
  index,
  fill = '#FF900D',
  isHere = false,
  status = 'has_not_started',
  isLastChild = false,
  onClick,
  isStarShape = false,
  childName = '',
  coursesEnded,
  currentTab,
  currentCourse,
  setCurrentCourse,
  length,
  dataCourses,
  setObjectCalendar,
  fullInfoCurrentCourse,
  hasEnded,
  setCalendarRecurrence,
  setCalendarSplitRecurrence,
  setCalendarOptions
}) => {
  const lastCourseEnded =
    coursesEnded.length > 1 &&
    coursesEnded[coursesEnded.length - 1].course.order_in_learning_path;

  const [initDate, setInitDate] = useState();
  const [frequency, setFrequency] = useState(1);

  useEffect(() => {
    const startDate = fullInfoCurrentCourse?.group?.start_date;
    const endDate = fullInfoCurrentCourse?.group?.end_date;
    const frecuencia = fullInfoCurrentCourse?.group?.frequency[0].day_name;
    const result = nextCalendarDate(startDate, endDate, frecuencia);
    const today = format(new Date(), 'yyyy-MM-dd');

    setFrequency(result.remainingDays);
    setInitDate(today > startDate ? result.date : startDate);
  }, [fullInfoCurrentCourse]);

  useEffect(() => {
    handleCurrentCourse();
  }, [currentTab]);

  const handleCurrentCourse = () => {
    if (status === 'has_ended' && typeof number !== 'number') {
      setCurrentCourse(length);
    } else if (
      number === lastCourseEnded &&
      dataCourses[number]?.status !== 'in_progress'
    ) {
      setCurrentCourse(index);
    } else if (status === 'in_progress' && typeof number === 'number') {
      setCurrentCourse(index);
    } else if (isLastChild && status === 'has_ended') {
      setCurrentCourse(index);
    } else if (isLastChild && status === 'in_progress' && typeof number === 'number') {
      setCurrentCourse(index);
    }
  };

  const getRecurrence = `RRULE: FREQ=WEEKLY;INTERVAL=1;BYDAY=${renderRecurringDaysCalendar(
    fullInfoCurrentCourse?.group?.frequency
  )};COUNT=${frequency}`;

  const handleObjectCalendar = () => {
    setObjectCalendar({
      customLabels: {
        close: fullInfoCurrentCourse?.course?.name,
        ['modal.multidate.h']: fullInfoCurrentCourse?.course?.name,
        ['modal.multidate.text']: 'Añade cada una de las clases a tu calendario'
      },
      name: `🚀 ${fullInfoCurrentCourse?.course?.name} - ${fullInfoCurrentCourse?.student?.full_name} - Crack The Code`,
      description: fullInfoCurrentCourse?.course?.description,
      startDate: initDate || fullInfoCurrentCourse?.group?.start_date,
      endDate: initDate,
      startTime: getTimeNumber(fullInfoCurrentCourse?.group?.frequency[0]?.time_start),
      endTime: getTimeNumber(fullInfoCurrentCourse?.group?.frequency[0]?.time_end),
      options: ['Apple', 'Google', 'iCal|Archivo iCal'],
      timeZone: getTimeZone(),
      iCalFileName: fullInfoCurrentCourse?.course?.name,
      language: 'es'
    });
  };

  const splitRecurrence = () => {
    const splitRecurrenceData = {
      startDate: fullInfoCurrentCourse?.group?.start_date,
      endDate: fullInfoCurrentCourse?.group?.end_date,
      frequency: fullInfoCurrentCourse?.group?.frequency[0].day_name,
      name: fullInfoCurrentCourse?.course?.name,
      description: fullInfoCurrentCourse?.course?.description,
      startTime: getTimeNumber(fullInfoCurrentCourse?.group?.frequency[0]?.time_start),
      endTime: getTimeNumber(fullInfoCurrentCourse?.group?.frequency[0]?.time_end)
    };

    const result = getSplitRecurrence(splitRecurrenceData);

    setCalendarSplitRecurrence(result);
  };

  useEffect(() => {
    setCalendarOptions(['Yahoo', 'Outlook.com|Outlook']);
    setCalendarRecurrence(getRecurrence);
    splitRecurrence();
    handleObjectCalendar();
  }, [currentTab, fullInfoCurrentCourse, frequency, currentCourse]);

  return (
    <div className="ui-step">
      <div
        className={`ui-step__functional-section ${
          status === 'has_ended' || status === 'in_progress'
            ? 'ui-step__functional-section--color'
            : 'ui-step__functional-section--noColor'
        }`}
        onClick={onClick}
      >
        <div
          className={
            typeof number === 'number'
              ? `ui-step__number-course`
              : `ui-step__number-course-certificate`
          }
          style={{ fontWeight: isHere ? 700 : 400 }}
        >
          {typeof number === 'number' ? `0${number}` : number}
        </div>
        <div className="ui-step__name-course" style={{ fontWeight: isHere ? 700 : 400 }}>
          {name}
        </div>
        <div
          className="ui-step__circle"
          style={{
            background:
              isHere && (status === 'has_ended' || status === 'in_progress')
                ? hasEnded
                  ? 'none'
                  : `${fill}45`
                : isHere && status !== 'has_ended' && status !== 'in_progress'
                ? 'rgba(0, 0, 0, 0.03)'
                : 'rgba(0, 0, 0, 0)'
          }}
        >
          {isStarShape === false ? (
            <IconCircleStep status={status} fill={fill} hasEnded={hasEnded} />
          ) : (
            <IconStarStep status={status} fill={fill} />
          )}
        </div>
        {number !== 1 && !isLastChild && (
          <div
            className="ui-step__connector"
            style={{
              background:
                status === 'has_ended' || status === 'in_progress' ? fill : '#DDDDDD'
            }}
          ></div>
        )}
        {isLastChild && (
          <div
            className="ui-step__connector"
            style={{ background: status === 'has_ended' ? fill : '#DDDDDD' }}
          ></div>
        )}
      </div>
      {status === 'in_progress' && typeof number === 'number' ? (
        <div className="ui-step__student-here">
          <div
            className="ui-step__student-here-triangle"
            style={{ borderBottom: `6px solid #F96B61` }}
          ></div>
          <Paragraph>{childName} está aquí</Paragraph>
        </div>
      ) : number === 1 && status === 'has_not_started' ? (
        <div className="ui-step__student-here">
          <div
            className="ui-step__student-here-triangle"
            style={{ borderBottom: `6px solid #F96B61` }}
          ></div>
          <Paragraph>{childName} está aquí</Paragraph>
        </div>
      ) : isLastChild && status === 'has_ended' ? (
        /* ''
      ) : !hasEnded && status === 'has_ended' ? ( */
        <div className="ui-step__student-here">
          <div
            className="ui-step__student-here-triangle"
            style={{ borderBottom: `6px solid #F96B61` }}
          ></div>
          <Paragraph>{childName} está aquí</Paragraph>
        </div>
      ) : isLastChild && status === 'in_progress' ? (
        ''
      ) : (
        ''
      )}
    </div>
  );
};

export default Step;
