import * as PathIcons from '#helpers/icons/path-icons';

export const requerimentsPath = [
  'Una computadora portátil o de escritorio con camara y buena conexion a internet.',
  'Tener descargado el programa de Sketch Jr, Zoom y un navegador web.',
  'Una memoria RAM de 4GB a más, 10 GB  libres en el disco duro y Windows 7 o Mac OS 19.13 en adelante.'
];

export const ageRangeColor = range => {
  switch (range) {
    case '5 a 7':
      return {
        main_color: '#F6C546',
        secondary_color: '#FAE2A2',
        alternative_color: '#FDF3DA'
      };
    case '8 a 12':
      return {
        main_color: '#46B327',
        secondary_color: '#A2D993',
        alternative_color: '#DAF0D4'
      };
    case '12 a 18':
      return {
        main_color: '#017AD5',
        secondary_color: '#80BCEA',
        alternative_color: '#CCE4F7'
      };
    case '8 a 14':
      return {
        main_color: '#08DDCF',
        secondary_color: '#A4F3EE',
        alternative_color: '#CEF8F5'
      };
    case '8 a 15':
      return {
        main_color: '#08DDCF',
        secondary_color: '#A4F3EE',
        alternative_color: '#CEF8F5'
      };
    case '8 a 18':
      return {
        main_color: '#AE90F6',
        secondary_color: '#E1D5FF',
        alternative_color: '#F3EEFF'
      };
    case '9 a 15':
      return {
        main_color: '#08DDCF',
        secondary_color: '#A4F3EE',
        alternative_color: '#CEF8F5'
      };
    default:
      return {
        main_color: '#08DDCF',
        secondary_color: '#A4F3EE',
        alternative_color: '#CEF8F5'
      };
  }
};
/* 
export const ageRangeColor = range => {
  switch (range) {
    case '5 a 7':
      return '#F6C546';
    case '8 a 12':
      return '#46B327';
    case '12 a 18':
      return '#017AD5';
    case '8 a 14':
      return '#08DDCF';
    case '8 a 15':
      return '#08DDCF';
    case '8 a 18':
      return '#C4ABFF';
    case '9 a 15':
      return '#08DDCF';
    default:
      return '#08DDCF';
  }
}; */

export const parseAgeRange = range => {
  switch (range) {
    case '5_to_7':
      return '5 a 7 años';
    case '8_plus':
      return '8+ años';
    case '9_plus':
      return '9+ años';
    case '10_plus':
      return '10+ años';
    case '12_plus':
      return '12+ años';
    case '8_to_11':
      return '8 a 11 años';
    default:
      return '';
  }
};

export const parseDuration = duration => {
  const number = duration?.toString();
  if (duration >= 10) {
    return number && number[3] === '0'
      ? Math.trunc(duration)
      : parseFloat(duration).toFixed(1);
  }
  return number && number[2] === '0'
    ? Math.trunc(duration)
    : parseFloat(duration).toFixed(1);
};

export const parseDurationMonth = duration => {
  return parseInt(`${duration}`).toFixed(0);
};

export const parsePathIcons = icon => {
  switch (icon) {
    case 'rocket':
      return <PathIcons.RocketIcon />;
    case 'games':
      return <PathIcons.GamesIcon />;
    case 'design':
      return <PathIcons.DesignIcon />;
    case 'code':
      return <PathIcons.CodeIcon />;

    default:
      return;
  }
};

export const parsePathName = path => {
  switch (path) {
    case 'Pequeños Programadores':
      return 'little-coders';
    case 'Desarrollo de Videojuegos':
      return 'desarrollo-de-videojuegos';
    case 'Diseño y Creatividad':
      return 'disenio-y-creatividad';
    case 'Super Programadores':
      return 'super-programadores';

    default:
      return 'default';
  }
};

export const parsePathNameSlug = path => {
  switch (path) {
    case 'Pequeños Programadores':
      return '/rutas/ruta-de-aprendizaje-para-pequenos-programadores';
    case 'Desarrollo de Videojuegos':
      return '/rutas/ruta-de-aprendizaje-para-desarrollo-de-videojuegos';
    case 'Diseño y Creatividad':
      return '/rutas/ruta-de-aprendizaje-para-diseno-y-creatividad';
    case 'Super Programadores':
      return '/rutas/ruta-de-aprendizaje-para-super-programadores';

    default:
      return '';
  }
};
// if contains 2021 this function rename the name of the course or path, delete 2021
export const renameCoursePerYear = name => {
  // step.course.name.includes(' 2021') ? step.course.name.replace(' 2021', '') step.course.name
  if (name) {
    return name.includes(' 2021') ? name.replace(' 2021', '') : name;
  }
};

export const pathDescriptionInCourse = course => {
  switch (course) {
    case 'Mini Creador':
      return 'Las rutas de Crack The Code son planes de aprendizaje creados según la edad e intereses de los niños. Cada clase se basa en la anterior, lo que significa que los estudiantes obtienen un aprendizaje progresivo';
    case 'Videojuegos tipo retro':
      return 'En la ruta Desarrollador de Videojuegos tu hijo descubrirá el mundo de los videojuegos desde una perspectiva diferente y práctica. Terminará la ruta creando ingeniosos proyectos y con un amplio dominio en ciencias de la computación. ';
    case 'Diseño Digital':
    case 'Youtuber Kids':
      return 'Las rutas de aprendizaje son programas curriculares diseñados para que los niños y adolescentes logren desarrollar habilidades tech. En la ruta diseño y creatividad los niños aprenderán la magia de la creación de contenidos.';

    default:
      return 'Las rutas de aprendizaje señalan a tu hij@ el camino de cursos para alcanzar las destrezas y capacidades que requiere ser un Programador Tech.';
  }
};

export const translateDay = day_name => {
  switch (day_name) {
    case 'monday':
      return 'Lunes';
      break;
    case 'tuesday':
      return 'Martes';
      break;
    case 'wednesday':
      return 'Miércoles';
      break;
    case 'thursday':
      return 'Jueves';
      break;
    case 'friday':
      return 'Viernes';
      break;
    case 'saturday':
      return 'Sábados';
      break;
    case 'sunday':
      return 'Domingo';
      break;
  }
};

// if contains Nivel 1, Nivel 2, Nivel 3, nivel 1, nivel 2 or nivel 3 remove it
export const renameLearningPath = pathName => {
  if (pathName.includes(' Nivel 1')) {
    return pathName.replace(' Nivel 1', '');
  } else if (pathName.includes(' Nivel 2')) {
    return pathName.replace(' Nivel 2', '');
  } else if (pathName.includes(' Nivel 3')) {
    return pathName.replace(' Nivel 3', '');
  } else if (pathName.includes(' nivel 1')) {
    return pathName.replace(' nivel 1', '');
  } else if (pathName.includes(' nivel 2')) {
    return pathName.replace(' nivel 2', '');
  } else if (pathName.includes(' nivel 3')) {
    return pathName.replace(' nivel 3', '');
  } else {
    return pathName;
  }
};
