import React from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import dynamic from 'next/dynamic';

import {
 H3, Paragraph, IconCertificate, Image 
} from '#styleguide/index';

// Dynamic Components
const DynamicModal = dynamic(() => import('../../components/modal'));
const DynamicCertificate = dynamic(() =>
  import('../../components/Certificate/certificate')
);

const CardCertificateContent = ({
 hasCertificatePath, childName, child, path 
}) => {
  return (
    <div className="ui-card-certificate-content">
      <div className="ui-card-certificate-content__first-img-bg">
        <Image
          src="/certificate/first-img-bg.png"
          alt="imagen de sfondo"
          width="182"
          height="160"
        />
      </div>
      <div className="ui-card-certificate-content__second-img-bg">
        <Image
          src="/certificate/second-img-bg.png"
          alt="imagen de sfondo"
          width="182"
          height="160"
        />
      </div>
      {hasCertificatePath === true ? (
        <H3 align="center">¡Felicitaciones!</H3>
      ) : (
        <H3 align="center">¡Sigan así!</H3>
      )}
      {hasCertificatePath === true ? (
        <Paragraph size="21" align="center">
          {childName ? childName : 'Se'} completó{' '}
          <span style={{ fontWeight: 700 }}>todos los cursos</span> de la ruta.
        </Paragraph>
      ) : (
        <Paragraph size="21" align="center">
          Cuando {childName ? childName : 'se'} complete los cursos de la ruta podrás
          descargar aquí su certificado final
        </Paragraph>
      )}
      <div className="ui-card-certificate-content__container-certificate">
        <IconCertificate />
      </div>
      {hasCertificatePath === true ? (
        <>
          <Paragraph size="14" align="center">
            Puedes descargar su Certificado de Ruta
          </Paragraph>
          <div className="ui-card-certificate-content__container-btn">
            <DynamicModal
              btnText="Certificado de Ruta"
              btnIcon={<FileDownloadOutlinedIcon />}
              btnSize="small"
              isFullWidth={false}
            >
              <DynamicCertificate path={path} children={child} />
            </DynamicModal>
          </div>
        </>
      ) : (
        <Paragraph size="14" align="center">
          (Mientras tanto puedes descargar el certificado de cada curso completado)
        </Paragraph>
      )}
    </div>
  );
};

export default CardCertificateContent;
