export const CloseIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.97499 7.50047L14.6154 2.86002C15.1282 2.3473 15.1282 1.51605 14.6154 1.00413L13.9968 0.385502C13.4839 -0.127379 12.6527 -0.127379 12.1408 0.385502L7.50047 5.02579L2.86002 0.384541C2.3473 -0.12818 1.51605 -0.12818 1.00413 0.384541L0.384541 1.00317C-0.12818 1.51605 -0.12818 2.3473 0.384541 2.85922L5.02579 7.50047L0.385502 12.1408C-0.127379 12.6536 -0.127379 13.4849 0.385502 13.9968L1.00413 14.6154C1.51685 15.1282 2.3481 15.1282 2.86002 14.6154L7.50047 9.97499L12.1408 14.6154C12.6536 15.1282 13.4849 15.1282 13.9968 14.6154L14.6154 13.9968C15.1282 13.4839 15.1282 12.6527 14.6154 12.1408L9.97499 7.50047Z"
        fill="black"
      />
    </svg>
  );
};

export default CloseIcon;
