const ScheduleIcon = ({ fill }) => (
  <svg
    width="19"
    height="21"
    viewBox="0 0 19 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5962 2.9668H15.5635V0.966797H13.4982V2.9668H5.237V0.966797H3.17169V2.9668H2.13904C0.992792 2.9668 0.084057 3.8668 0.084057 4.9668L0.0737305 18.9668C0.0737305 20.0668 0.992792 20.9668 2.13904 20.9668H16.5962C17.7321 20.9668 18.6615 20.0668 18.6615 18.9668V4.9668C18.6615 3.8668 17.7321 2.9668 16.5962 2.9668ZM16.5962 18.9668H2.13904V7.9668H16.5962V18.9668ZM4.20434 9.9668H9.36761V14.9668H4.20434V9.9668Z"
      fill={fill ? fill : '#000000'}
    />
  </svg>
);

export default ScheduleIcon;
