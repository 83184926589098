import { func, string } from 'prop-types';

const ButtonIcon = ({ icon, onClick }) => (
  <button className="button-icon" onClick={onClick}>
    <i className="material-icons">{icon}</i>
  </button>
);

ButtonIcon.propTypes = {
  icon: string.isRequired,
  onClick: func.isRequired
};

export default ButtonIcon;
