import React from 'react';
import { useRouter } from 'next/router';

import { event } from '#helpers/gtag';
import useUserContext from '#hooks/useUserContext';
import { getInfoStudent } from '#api/contentful/paths';
import { Image, Paragraph, Button } from '#styleguide/index';

const CardStudentZone = ({ childrenData }) => {
  const { data: userData } = useUserContext();
  const router = useRouter();

  const handleRedirectStudent = async idStudent => {
    let refreshToken = localStorage.getItem('refreshToken');
    if (childrenData?.id) {
      try {
        const res = await getInfoStudent(childrenData?.id, refreshToken);
        router.push(`${res.redirect_login}`);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="card-student-zone">
      <div className="card-student-zone__bg">
        <div className="card-student-zone__circle-icon">
          <Image
            width="64"
            src={`/avatars-profile/student${childrenData?.icon_id}.webp`}
          />
        </div>
        <Paragraph size="21" weight="700" align="center">
          Zona de Estudiantes
        </Paragraph>
        <section className="card-student-zone__section-access">
          <div>
            <Paragraph size="16" align="center">
              Entrar a la zona de
            </Paragraph>
            <Paragraph size="16" align="center" weight="700">
              {childrenData?.first_name}
            </Paragraph>
            <br />
            <Paragraph size="16" align="center" weight="700">
              Usuario
            </Paragraph>
            <Paragraph size="16" align="center" weight="700">
              {childrenData?.email}
            </Paragraph>
            <Paragraph size="16" align="center" weight="700">
              Constraseña
            </Paragraph>
            <Paragraph size="16" align="center" weight="700">
              {childrenData?.password}
            </Paragraph>
          </div>
          <Button
            size="small"
            width="155"
            onClick={() => {
              handleRedirectStudent();
              event(
                'click-seccion-zona-estudiante',
                'zona-padres',
                'clic en boton para ingreso de alumno a zona de estudiantes'
              );
              event(
                `click-seccion-zona-estudiante: ${userData.email}`,
                'zona-padres',
                'clic en boton para ingreso de alumno a zona de estudiantes'
              );
            }}
          >
            <Paragraph size="16" color="#FFFFFF">
              Entrar
            </Paragraph>
          </Button>
        </section>
      </div>
    </div>
  );
};

export default CardStudentZone;
