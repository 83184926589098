import { useState } from 'react';
import { bool, string } from 'prop-types';
import Youtube from 'react-youtube';
import cx from 'classnames';

import { Icon } from '#atoms/index';

const FeaturedVideo = ({ videoId, mobile = false, alwaysVertical = false }) => {
  const [player, setPlayer] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const handleOnReady = event => setPlayer(event.target);

  const handleSeeVideo = () => {
    if (!mobile) {
      setShowModal(true);
    }
    player.playVideo();
  };

  return (
    <>
      {mobile && (
        <div className="featured-video-tile-mobile">
          <Youtube
            videoId={videoId}
            id={`video-${videoId}`}
            onReady={handleOnReady}
            opts={{ playerVars: { controls: 0, rel: 0 } }}
          />
        </div>
      )}
      {!mobile && (
        <>
          <picture
            className={cx('featured-video-tile', { 'always-vertical': alwaysVertical })}
            onClick={handleSeeVideo}
          >
            <img
              src={`https://i.ytimg.com/vi_webp/${videoId}/sddefault.webp`}
              alt="featured-video | Crack The Code"
            />
            <Icon icon="play_circle_filled" />
          </picture>

          <div className={cx('featured-video-tile-modal', { active: showModal })}>
            <button onClick={() => setShowModal(false) || player.stopVideo()}>
              <Icon icon="clear" />
            </button>
            <Youtube
              videoId={videoId}
              id={`video-${videoId}`}
              onReady={handleOnReady}
              opts={{ playerVars: { controls: 0, rel: 0 } }}
            />
          </div>
        </>
      )}
    </>
  );
};

FeaturedVideo.propTypes = {
  videoId: string,
  mobile: bool,
  alwaysVertical: bool
};

export default FeaturedVideo;
