import React from 'react';
import ImageNext from 'next/image';
import { number, string } from 'prop-types';

const Image = ({
 width, height, src, alt 
}) => {
  return (
    <div className="ui-image" style={{ width: width, height: height ? height : width }}>
      <ImageNext width={width} height={height ? height : width} src={src} alt={alt} />
    </div>
  );
};

Image.propTypes = {
  width: number.isRequired,
  height: number,
  src: string.isRequired,
  alt: string.isRequired
};

export default Image;
