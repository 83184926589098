import React from 'react';

import { Paragraph, Image } from '#styleguide/index';

const ShapeTab = ({
 name, isActive, onClick, icon 
}) => {
  return (
    <div className="ui-shape-tab" onClick={onClick}>
      <div
        className={`ui-shape-tab__right ${
          isActive ? 'ui-shape-tab__right--active' : 'ui-shape-tab__right--inactive'
        }`}
      ></div>
      <div
        className={`ui-shape-tab__center ${
          isActive ? 'ui-shape-tab__center--active' : 'ui-shape-tab__center--inactive'
        }`}
      >
        <div
          className={`ui-shape-tab__profile-image ${
            isActive
              ? 'ui-shape-tab__profile-image--active'
              : 'ui-shape-tab__profile-image--inactive'
          }`}
        >
          <Image width="46" src={`/avatars-profile/student${icon}.webp`} />
        </div>
        <Paragraph size="21" weight={700}>
          {name}
        </Paragraph>
      </div>
      <div
        className={`ui-shape-tab__left ${
          isActive ? 'ui-shape-tab__left--active' : 'ui-shape-tab__left--inactive'
        }`}
      ></div>
    </div>
  );
};

export default ShapeTab;
