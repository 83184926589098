import { Link } from '#atoms/index';
import TimerClockIcon from '#helpers/icons/timer-clock-icon';
const AnyQuestionCard = () => {
  return (
    <Link href="/contactanos">
      <div className="any_question_card-Wrapper">
        <TimerClockIcon />
        <h3>¿Tienes alguna pregunta?</h3>
        <Link variant="any-question-card" href="/contactanos">
          ¡Contáctanos!
        </Link>
      </div>
    </Link>
  );
};

export default AnyQuestionCard;
