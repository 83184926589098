export const LogoSVGWhite = () => (
  <svg
    width="96"
    height="72"
    viewBox="0 0 96 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.172 12.6109C12.2476 14.0087 10.7685 14.9322 9.03515 14.9322C6.14629 14.9322 3.97385 12.3613 3.97385 9.1415C3.97385 5.94662 6.14629 3.32582 9.03515 3.32582C10.7223 3.32582 12.1552 4.22438 13.0796 5.5223L15.4369 3.70022C14.0503 1.60358 11.7392 0.305664 9.03515 0.305664C4.5054 0.305664 1.01562 4.2743 1.01562 9.1415C1.01562 13.9837 4.5054 17.9524 9.03515 17.9524C11.8085 17.9524 14.1196 16.5796 15.5063 14.458L13.172 12.6109Z"
      fill="white"
    />
    <path
      d="M29.3337 3.35081C30.8129 3.35081 31.4831 4.82345 31.4831 6.07145C31.4831 7.34441 30.8129 8.74217 29.2413 8.74217H26.052V3.35081H29.3337ZM30.5124 11.4628C33.3551 10.9886 34.5569 8.51753 34.5569 6.07145C34.5569 3.27593 32.9853 0.505371 29.4724 0.505371H23.0938V17.7028H26.052V11.5876H27.2769L31.0209 17.7028H34.58L30.5124 11.4628Z"
      fill="white"
    />
    <path
      d="M52.7681 17.7028H55.8881L49.9254 0.505371H46.7361L40.7734 17.7028H43.9165L45.3725 13.5345H51.3121L52.7681 17.7028ZM48.3308 4.17449L50.3414 10.739H46.3663L48.3308 4.17449Z"
      fill="white"
    />
    <path
      d="M73.8596 12.6109C72.9351 14.0087 71.456 14.9322 69.7227 14.9322C66.8338 14.9322 64.6613 12.3613 64.6613 9.1415C64.6613 5.94662 66.8338 3.32582 69.7227 3.32582C71.4098 3.32582 72.8426 4.22438 73.7671 5.5223L76.1245 3.70022C74.7378 1.60358 72.4267 0.305664 69.7227 0.305664C65.1929 0.305664 61.7031 4.2743 61.7031 9.1415C61.7031 13.9837 65.1929 17.9524 69.7227 17.9524C72.496 17.9524 74.8071 16.5796 76.1938 14.458L73.8596 12.6109Z"
      fill="white"
    />
    <path
      d="M88.4497 9.04169L94.5051 0.505371H91.0384L86.7626 6.77033V0.505371H83.7812V17.7028H86.7626V11.338L92.0784 17.7028H95.938L88.4497 9.04169Z"
      fill="white"
    />
    <path
      d="M0.648438 30.3056H5.132V44.5827H8.11333V30.3056H12.5969V27.3853H0.648438V30.3056Z"
      fill="white"
    />
    <path
      d="M29.634 27.3853V34.5987H23.2554V27.3853H20.3203V44.5827H23.2554V37.4691H29.634V44.5827H32.6154V27.3853H29.634Z"
      fill="white"
    />
    <path
      d="M41.6797 44.5827H51.7561V41.7373H44.6148V37.519H50.6699V34.6985H44.6148V30.2307H51.525V27.3853H41.6797V44.5827Z"
      fill="white"
    />
    <path
      d="M13.172 66.371C12.2476 67.7687 10.7685 68.6922 9.03515 68.6922C6.14629 68.6922 3.97385 66.1214 3.97385 62.9015C3.97385 59.7066 6.14629 57.0858 9.03515 57.0858C10.7223 57.0858 12.1552 57.9844 13.0796 59.2823L15.4369 57.4602C14.0503 55.3636 11.7392 54.0657 9.03515 54.0657C4.5054 54.0657 1.01562 58.0343 1.01562 62.9015C1.01562 67.7438 4.5054 71.7124 9.03515 71.7124C11.8085 71.7124 14.1196 70.3396 15.5063 68.218L13.172 66.371Z"
      fill="white"
    />
    <path
      d="M25.0601 62.9015C25.0601 59.7815 27.3019 57.2106 30.1908 57.2106C33.0334 57.2106 35.3214 59.7815 35.3214 62.9015C35.3214 66.0465 33.0334 68.5674 30.1908 68.5674C27.3019 68.5674 25.0601 66.0465 25.0601 62.9015ZM22.125 62.9015C22.125 67.7438 25.661 71.7124 30.1908 71.7124C34.7205 71.7124 38.2797 67.7438 38.2797 62.9015C38.2797 58.0343 34.7205 54.0657 30.1908 54.0657C25.661 54.0657 22.125 58.0343 22.125 62.9015Z"
      fill="white"
    />
    <path
      d="M49.3293 57.1857H52.588C55.4075 57.1857 56.8173 59.7815 56.8173 62.9015C56.8173 65.9966 55.4075 68.5425 52.588 68.5425H49.3293V57.1857ZM52.588 71.4628C57.1409 71.4628 59.7755 67.7937 59.7755 62.9015C59.7755 58.0094 57.1409 54.2654 52.588 54.2654H46.3711V71.4628H52.588Z"
      fill="white"
    />
    <path
      d="M67.9531 71.4628H78.0296V68.6174H70.8882V64.3991H76.9433V61.5787H70.8882V57.1108H77.7985V54.2654H67.9531V71.4628Z"
      fill="white"
    />
    <path
      d="M85.5938 69.8154C85.5938 70.9137 86.4026 71.7124 87.3964 71.7124C88.4133 71.7124 89.2456 70.9137 89.2456 69.8154C89.2456 68.7172 88.4133 67.9185 87.3964 67.9185C86.4026 67.9185 85.5938 68.7172 85.5938 69.8154Z"
      fill="white"
    />
  </svg>
);

export const LogoSVGDark = () => (
  <svg
    width="128"
    height="64"
    viewBox="0 0 128 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 407">
      <path
        id="Vector"
        d="M6.29886 29.4491C3.9661 29.4491 2.64671 27.3883 2.64671 24.8093C2.64671 22.2303 3.91443 20.2485 6.27104 20.2485C8.11102 20.2485 9.17209 21.538 9.29926 23.3655H12.1208C11.8864 20.3 9.74038 18.1323 6.21541 18.1323C2.22547 18.1323 0 21.0713 0 24.8607C0 28.6501 2.30495 31.5614 6.26707 31.5614C9.71256 31.5614 11.5764 29.6786 12.3275 26.6882H9.63308C9.34695 28.2348 8.15871 29.4452 6.29091 29.4452L6.29886 29.4491Z"
        fill="#001F19"
      />
      <path
        id="Vector_2"
        d="M17.0965 24.3187C17.0965 21.0949 19.0914 20.5807 21.0864 20.5807H22.7952V18.3379H20.8003C18.0542 18.3379 17.3548 19.754 16.9415 20.8378V18.3379H14.5332V31.3556H17.0965V24.3187Z"
        fill="#001F19"
      />
      <path
        id="Vector_3"
        d="M33.0443 29.2158V31.3557H35.5837V23.3378C35.5837 19.9598 33.6682 18.1323 30.1711 18.1323C26.8567 18.1323 24.7863 19.7818 24.3173 22.7445H27.0356C27.3217 20.9922 28.2794 20.2169 30.1433 20.2169C31.6733 20.2169 32.8377 20.9645 32.8377 21.9692C32.8377 23.2824 31.8004 23.8758 28.9034 24.2357C25.6129 24.6471 24.0352 25.9366 24.0352 28.128C24.0352 30.1651 25.7718 31.5812 28.2039 31.5812C30.3022 31.5812 31.9356 30.7822 33.0483 29.2079L33.0443 29.2158ZM26.7772 27.9025C26.7772 26.6644 27.7628 26.2016 29.9128 25.814C31.3116 25.5569 32.1661 25.2998 33.0205 24.6273V25.8892C33.0205 28.0805 30.7433 29.6034 28.955 29.6034C27.6078 29.6034 26.7772 29.0101 26.7772 27.9025Z"
        fill="#001F19"
      />
      <path
        id="Vector_4"
        d="M50.1401 26.6877H47.4457C47.1596 28.2344 45.9713 29.4447 44.1035 29.4447C41.7708 29.4447 40.4514 27.3839 40.4514 24.8049C40.4514 22.2259 41.7191 20.2441 44.0757 20.2441C45.9157 20.2441 46.9768 21.5337 47.1039 23.3611H49.9255C49.6911 20.2956 47.5451 18.1279 44.0201 18.1279C40.0302 18.1279 37.8047 21.0669 37.8047 24.8563C37.8047 28.6457 40.1096 31.557 44.0718 31.557C47.5173 31.557 49.3811 29.6742 50.1322 26.6838L50.1401 26.6877Z"
        fill="#001F19"
      />
      <path
        id="Vector_5"
        d="M54.9051 26.8461L56.1728 25.6594L61.0688 31.3554H64.3593L57.9611 23.9031L63.6837 18.3377H60.5244L54.9051 23.8557V13.1836H52.3418V31.3554H54.9051V26.8461Z"
        fill="#001F19"
      />
      <path
        id="Vector_6"
        d="M5.67563 59.4476V51.9993H9.63775V49.7565H5.67563V46.0186H3.06071L3.08853 49.7565H0.417969V51.9993H3.08455V59.6019C3.08455 61.8961 3.83565 62.7743 6.58171 62.7743H10.2339V60.48H6.76452C5.96176 60.48 5.67563 60.1952 5.67563 59.4476Z"
        fill="#001F19"
      />
      <path
        id="Vector_7"
        d="M19.167 49.551C17.5098 49.551 15.8526 50.4014 14.9465 51.8175V44.5986H12.3555V62.7704H14.9465V55.5515C14.9465 53.8507 16.2421 51.7621 18.4675 51.7621C19.8664 51.7621 21.0308 52.7431 21.0308 54.5191V62.7665H23.5941V54.1315C23.5941 51.0145 21.7024 49.5431 19.167 49.5431V49.551Z"
        fill="#001F19"
      />
      <path
        id="Vector_8"
        d="M31.8832 60.8681C29.6061 60.8681 28.1556 59.3492 28.0006 56.9758H37.7648C38.1542 52.6445 35.746 49.5513 31.5494 49.5513C27.5873 49.5513 25.334 52.514 25.334 56.2797C25.334 60.5319 28.1556 62.9804 31.8872 62.9804C35.126 62.9804 37.2482 61.1252 38.1026 58.4948H35.4082C34.9949 59.7566 33.751 60.8641 31.8872 60.8641L31.8832 60.8681ZM31.5454 51.537C33.6159 51.537 34.8598 53.0559 35.0942 55.0416H28.0244C28.2827 53.0045 29.5544 51.537 31.5454 51.537Z"
        fill="#001F19"
      />
      <path
        id="Vector_9"
        d="M53.3789 60.8681C51.0462 60.8681 49.7268 58.8073 49.7268 56.2282C49.7268 53.6492 50.9945 51.6675 53.3511 51.6675C55.1911 51.6675 56.2522 52.957 56.3793 54.7845H59.2009C58.9664 51.7189 56.8205 49.5513 53.2955 49.5513C49.3055 49.5513 47.0801 52.4902 47.0801 56.2797C47.0801 60.0691 49.385 62.9804 53.3471 62.9804C56.7926 62.9804 58.6565 61.0975 59.4076 58.1071H56.7132C56.427 59.6537 55.2388 60.8641 53.371 60.8641L53.3789 60.8681Z"
        fill="#001F19"
      />
      <path
        id="Vector_10"
        d="M67.2886 49.5513C63.247 49.5513 61.0215 52.514 61.0215 56.2797C61.0215 60.0453 63.247 62.9804 67.2886 62.9804C71.3302 62.9804 73.5278 60.1205 73.5278 56.2797C73.5278 52.4388 71.3023 49.5513 67.2886 49.5513ZM67.2886 60.8681C64.8525 60.8681 63.6602 58.7281 63.6602 56.2797C63.6602 53.6769 64.8525 51.6675 67.2886 51.6675C69.7246 51.6675 70.889 53.8074 70.889 56.2797C70.889 58.7519 69.7485 60.8681 67.2886 60.8681Z"
        fill="#001F19"
      />
      <path
        id="Vector_11"
        d="M84.6161 51.7423C83.6862 50.2708 82.2833 49.5509 80.8089 49.5509C77.3118 49.5509 75.293 52.3871 75.293 56.2793C75.293 60.1716 77.3396 63.0077 80.7811 63.0077C82.335 63.0077 83.8093 62.181 84.7154 60.5592V62.7743H87.1754V44.6025H84.6121V51.7423H84.6161ZM81.3017 60.8915C79.0246 60.8915 77.9874 58.5458 77.9874 56.2793C77.9874 53.8031 78.9451 51.6909 81.3017 51.6909C83.6583 51.6909 84.6161 53.8031 84.6161 56.2793C84.6161 58.5735 83.5033 60.8915 81.3017 60.8915Z"
        fill="#001F19"
      />
      <path
        id="Vector_12"
        d="M96.1391 60.8681C93.862 60.8681 92.4075 59.3492 92.2525 56.9758H102.017C102.406 52.6445 99.9979 49.5513 95.8013 49.5513C91.8392 49.5513 89.5859 52.514 89.5859 56.2797C89.5859 60.5319 92.4075 62.9804 96.1391 62.9804C99.374 62.9804 101.5 61.1252 102.355 58.4948H99.6601C99.2468 59.7566 98.003 60.8641 96.1391 60.8641V60.8681ZM95.8013 51.537C97.8718 51.537 99.1157 53.0559 99.3502 55.0416H92.2803C92.5387 53.0045 93.8064 51.537 95.8013 51.537Z"
        fill="#001F19"
      />
      <path
        id="Vector_13"
        d="M115.177 18.4562C116.712 16.9284 116.712 14.4515 115.177 12.9237C113.642 11.396 111.154 11.396 109.619 12.9237C108.084 14.4515 108.084 16.9284 109.619 18.4562C111.154 19.9839 113.642 19.9839 115.177 18.4562Z"
        fill="#001F19"
      />
      <path
        id="Vector_14"
        d="M78.431 27.4555C78.431 26.2807 77.9104 25.2324 77.0877 24.5125C77.9104 23.7966 78.431 22.7444 78.431 21.5696C78.431 20.3948 77.9104 19.3466 77.0877 18.6267C77.9104 17.9107 78.431 16.8585 78.431 15.6837C78.431 14.5089 77.9104 13.4607 77.0877 12.7408C77.9104 12.0249 78.431 10.9727 78.431 9.79789C78.431 8.62309 77.9104 7.57487 77.0877 6.85496C77.9104 6.13901 78.431 5.08683 78.431 3.91204C78.431 1.75231 76.6705 0 74.5006 0C72.3308 0 70.5703 1.75231 70.5703 3.91204C70.5703 5.08683 71.0909 6.13505 71.9135 6.85496C71.0909 7.57092 70.5703 8.62309 70.5703 9.79789C70.5703 10.9727 71.0909 12.0209 71.9135 12.7408C71.0909 13.4568 70.5703 14.5089 70.5703 15.6837C70.5703 16.8585 71.0909 17.9068 71.9135 18.6267C71.0909 19.3426 70.5703 20.3948 70.5703 21.5696C70.5703 22.7444 71.0909 23.7926 71.9135 24.5125C71.0909 25.2285 70.5703 26.2807 70.5703 27.4555C70.5703 29.6152 72.3308 31.3675 74.5006 31.3675C76.6705 31.3675 78.431 29.6152 78.431 27.4555Z"
        fill="#001F19"
      />
      <path
        id="Vector_15"
        d="M108.609 28.6499V35.493C115.043 35.493 120.277 40.1289 120.277 45.8288C120.277 51.5288 115.043 56.1647 108.609 56.1647V63.0078C119.3 63.0078 127.999 55.3024 127.999 45.8288C127.999 36.3553 119.3 28.6499 108.609 28.6499Z"
        fill="#001F19"
      />
    </g>
  </svg>
);
