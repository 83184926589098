import { useState, useRef } from 'react';
import dynamic from 'next/dynamic';

import { eventGtag } from '#app/hooks/eventGtag';
import { useUserContext } from '#hooks/index';
import { FaqIconArrow } from '#helpers/icons/path-learning-icons';
import { event } from '#helpers/gtag';

const Typography = dynamic(() => import('#app/UI/atoms/typography-updated'));

const FaqCardUpd = ({
  title,
  description,
  fill,
  belongs_to_learning_path,
  eventNameAnalytics
}) => {
  const { data: userData } = useUserContext();
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const [setRotate, setRotateState] = useState('accordion__icon');

  const content = useRef(null);

  const toggleAccordion = () => {
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`);
    setRotateState(setActive === 'active' ? 'accordion__icon' : 'accordion__icon rotate');
    event('clicks-desplegable-faq', 'preguntas-frecuentes', 'clic en pregunta frecuente');
    if (userData) {
      event(
        `clicks-desplegable-faq': ${userData.email}`,
        'preguntas-frecuentes',
        'clic en pregunta frecuente'
      );
    }
    eventNameAnalytics && setHeight === '0px'
      ? eventGtag(eventNameAnalytics, userData)
      : '';
  };

  return (
    <div className={`faq_card_upd-Wrapper ${setActive}`} onClick={toggleAccordion}>
      <button className="accordion">
        <Typography tag="body-big sm_text-left">{title}</Typography>
        <FaqIconArrow
          className={`${setRotate}`}
          fill={belongs_to_learning_path ? fill : '#000000'}
        />
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content"
      >
        <div style={{ margin: '20px 30px 20px 0' }}>
          <Typography tag="body-small">{description}</Typography>
        </div>
      </div>
    </div>
  );
};

export default FaqCardUpd;
