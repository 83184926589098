import React from 'react';

const Paragraph = ({
  children,
  color = '#000000',
  size = '14',
  weight = '400',
  align = 'left',
  hasUnderline = false,
  className,
  italic
}) => {
  const ParagraphStyle67 = ({ children, className }) => (
    <p
      className={`ui-paragraph ${className}`}
      style={{
        fontFamily: 'Roca One',
        textAlign: align,
        fontWeight: weight,
        color: color,
        textDecoration: hasUnderline ? 'underline' : 'none',
        fontStyle: italic ? 'italic' : 'normal',
        fontSize: '67px',
        lineHeight: '100px'
      }}
    >
      {children}
    </p>
  );
  const ParagraphStyle51 = ({ children, className }) => (
    <p
      className={`ui-paragraph ${className}`}
      style={{
        fontFamily: 'Roca One',
        textAlign: align,
        fontWeight: weight,
        color: color,
        textDecoration: hasUnderline ? 'underline' : 'none',
        fontStyle: italic ? 'italic' : 'normal',
        fontSize: '51px',
        lineHeight: '72px'
      }}
    >
      {children}
    </p>
  );
  const ParagraphStyle38 = ({ children, className }) => (
    <p
      className={`ui-paragraph ${className}`}
      style={{
        fontFamily: 'Roca One',
        textAlign: align,
        fontWeight: weight,
        color: color,
        textDecoration: hasUnderline ? 'underline' : 'none',
        fontStyle: italic ? 'italic' : 'normal',
        fontSize: '38px',
        lineHeight: '56px'
      }}
    >
      {children}
    </p>
  );
  const ParagraphStyle28 = ({ children, className }) => (
    <p
      className={`ui-paragraph ${className}`}
      style={{
        fontFamily: 'Roca One',
        textAlign: align,
        fontWeight: weight,
        color: color,
        textDecoration: hasUnderline ? 'underline' : 'none',
        fontStyle: italic ? 'italic' : 'normal',
        fontSize: '28px',
        lineHeight: '32px'
      }}
    >
      {children}
    </p>
  );
  const ParagraphStyle24 = ({ children, className }) => (
    <p
      className={`ui-paragraph ${className}`}
      style={{
        fontFamily: 'Roca One',
        textAlign: align,
        fontWeight: weight,
        color: color,
        textDecoration: hasUnderline ? 'underline' : 'none',
        fontStyle: italic ? 'italic' : 'normal',
        fontSize: '24px',
        lineHeight: '32px'
      }}
    >
      {children}
    </p>
  );
  const ParagraphStyle21 = ({ children, className }) => (
    <p
      className={`ui-paragraph ${className}`}
      style={{
        fontFamily: 'Roca One',
        textAlign: align,
        fontWeight: weight,
        color: color,
        textDecoration: hasUnderline ? 'underline' : 'none',
        fontStyle: italic ? 'italic' : 'normal',
        fontSize: '21px',
        lineHeight: '32px'
      }}
    >
      {children}
    </p>
  );
  const ParagraphStyle16 = ({ children, className }) => (
    <p
      className={`ui-paragraph ${className}`}
      style={{
        fontFamily: 'Roca One',
        textAlign: align,
        fontWeight: weight,
        color: color,
        textDecoration: hasUnderline ? 'underline' : 'none',
        fontStyle: italic ? 'italic' : 'normal',
        fontSize: '16px',
        lineHeight: '24px'
      }}
    >
      {children}
    </p>
  );
  const ParagraphStyle14 = ({ children, className }) => (
    <p
      className={`ui-paragraph ${className}`}
      style={{
        fontFamily: 'Roca One',
        textAlign: align,
        fontWeight: weight,
        color: color,
        textDecoration: hasUnderline ? 'underline' : 'none',
        fontStyle: italic ? 'italic' : 'normal',
        fontSize: '14px',
        lineHeight: '21px'
      }}
    >
      {children}
    </p>
  );
  const ParagraphStyle12 = ({ children, className }) => (
    <p
      className={`ui-paragraph ${className}`}
      style={{
        fontFamily: 'Roca One',
        textAlign: align,
        fontWeight: weight,
        color: color,
        textDecoration: hasUnderline ? 'underline' : 'none',
        fontStyle: italic ? 'italic' : 'normal',
        fontSize: '12px',
        lineHeight: '21px'
      }}
    >
      {children}
    </p>
  );

  return (
    <>
      {size === 'primary' ? (
        <ParagraphStyle67 className={className}>{children}</ParagraphStyle67>
      ) : size === '51' ? (
        <ParagraphStyle51 className={className}>{children}</ParagraphStyle51>
      ) : size === '38' ? (
        <ParagraphStyle38 className={className}>{children}</ParagraphStyle38>
      ) : size === '28' ? (
        <ParagraphStyle28 className={className}>{children}</ParagraphStyle28>
      ) : size === '24' ? (
        <ParagraphStyle24 className={className}>{children}</ParagraphStyle24>
      ) : size === '21' ? (
        <ParagraphStyle21 className={className}>{children}</ParagraphStyle21>
      ) : size === '16' ? (
        <ParagraphStyle16 className={className}>{children}</ParagraphStyle16>
      ) : size === '14' ? (
        <ParagraphStyle14 className={className}>{children}</ParagraphStyle14>
      ) : size === '12' ? (
        <ParagraphStyle12 className={className}>{children}</ParagraphStyle12>
      ) : (
        ''
      )}
    </>
  );
};

export default Paragraph;
