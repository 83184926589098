import Typography from 'atoms/typography';

import { DownloadPdfIco, PdfIco } from '#helpers/icons/dashboard-icons';
const CertificateCard = ({ certificade }) => {
  const onRedirectFile = path => () => {
    window.open(path, '_blank');
  };

  return (
    <div className="certificate_card-Tile" onClick={onRedirectFile(certificade.file)}>
      <PdfIco />
      <Typography variant="body">{certificade.title}</Typography>
      <DownloadPdfIco />
    </div>
  );
};

export default CertificateCard;
