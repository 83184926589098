const SectionAboutUs = ({
 title, description, image, order 
}) => {
  return (
    <div className="section-about-us">
      <section className="section-about-us__first-section">
        <h2 className="section-about-us__title section-about-us__title--bold">{title}</h2>
        <p className="section-about-us__description">{description}</p>
      </section>

      <section className="section-about-us__second-section" style={{ order: order }}>
        <img src={image} alt={title} />
      </section>
    </div>
  );
};

export default SectionAboutUs;
