export const EmailOutlineIcon = () => (
  <svg
    width="25"
    height="18"
    viewBox="0 0 25 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* eslint-disable */}
    <path
      d="M22.8027 0H2.19727C0.983252 0 0 0.984958 0 2.1875V15.3125C0 16.5222 0.990479 17.5 2.19727 17.5H22.8027C24.0066 17.5 25 16.5263 25 15.3125V2.1875C25 0.987097 24.0207 0 22.8027 0ZM22.495 1.45833C22.0461 1.90288 14.3205 9.55369 14.0537 9.81784C13.6387 10.231 13.0869 10.4585 12.5 10.4585C11.9131 10.4585 11.3613 10.231 10.9449 9.81648C10.7655 9.63881 3.12515 2.07249 2.50498 1.45833H22.495ZM1.46484 15.0157V2.48524L7.79482 8.75389L1.46484 15.0157ZM2.50591 16.0417L8.8334 9.78235L9.9105 10.849C10.6022 11.5377 11.5218 11.9169 12.5 11.9169C13.4782 11.9169 14.3978 11.5377 15.0881 10.8504L16.1666 9.78235L22.4941 16.0417H2.50591ZM23.5352 15.0157L17.2052 8.75389L23.5352 2.48524V15.0157Z"
      fill="#616161"
    />
    {/* eslint-disable */}
  </svg>
);

export const InstagramIcon = () => (
  <svg
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* eslint-disable */}

    <path
      d="M1.63074 1.73602C-0.328197 3.77078 0.0727316 5.93226 0.0727316 12.4988C0.0727316 17.9518 -0.878694 23.4183 4.10071 24.7053C5.6556 25.1051 19.4326 25.1051 20.9854 24.7032C23.0586 24.1683 24.7454 22.4866 24.976 19.5545C25.0082 19.1452 25.0082 5.85955 24.9749 5.442C24.7298 2.31871 22.8072 0.518689 20.2739 0.154115C19.6933 0.0699822 19.577 0.045054 16.598 0.0398606C6.0316 0.045054 3.71536 -0.425465 1.63074 1.73602Z"
      fill="#616161"
    />
    <path
      d="M12.535 3.30051C8.76357 3.30051 5.18222 2.96502 3.81429 6.47574C3.24925 7.92572 3.33131 9.80884 3.33131 12.5052C3.33131 14.8713 3.25548 17.0951 3.81429 18.5337C5.17911 22.0465 8.78954 21.71 12.5329 21.71C16.1444 21.71 19.868 22.086 21.2526 18.5337C21.8187 17.0692 21.7356 15.2141 21.7356 12.5052C21.7356 8.90935 21.934 6.58791 20.19 4.84502C18.4243 3.07927 16.0364 3.30051 12.5308 3.30051H12.535ZM11.7103 4.95927C19.5772 4.94681 20.5785 4.07225 20.0259 16.2216C19.8296 20.5186 16.5578 20.047 12.536 20.047C5.20299 20.047 4.99214 19.8372 4.99214 12.5011C4.99214 5.07976 5.5738 4.96343 11.7103 4.95719V4.95927ZM17.4479 6.48716C16.8382 6.48716 16.3438 6.98157 16.3438 7.59127C16.3438 8.20097 16.8382 8.69538 17.4479 8.69538C18.0576 8.69538 18.552 8.20097 18.552 7.59127C18.552 6.98157 18.0576 6.48716 17.4479 6.48716ZM12.535 7.77823C9.92481 7.77823 7.80903 9.89505 7.80903 12.5052C7.80903 15.1154 9.92481 17.2312 12.535 17.2312C15.1452 17.2312 17.2599 15.1154 17.2599 12.5052C17.2599 9.89505 15.1452 7.77823 12.535 7.77823ZM12.535 9.43699C16.591 9.43699 16.5962 15.5735 12.535 15.5735C8.48001 15.5735 8.47378 9.43699 12.535 9.43699Z"
      fill="white"
    />
    {/* eslint-disable */}
  </svg>
);

export const FacebookIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* eslint-disable */}
    <path
      d="M21.875 0H3.125C1.40104 0 0 1.40104 0 3.125V21.875C0 23.5979 1.40104 25 3.125 25H21.875C23.5979 25 25 23.5979 25 21.875V3.125C25 1.40104 23.5979 0 21.875 0Z"
      fill="#616161"
    />
    <path
      d="M17.1875 12.5V9.375C17.1875 8.5125 17.8875 8.59375 18.75 8.59375H20.3125V4.6875H17.1875C14.5979 4.6875 12.5 6.78542 12.5 9.375V12.5H9.375V16.4062H12.5V25H17.1875V16.4062H19.5312L21.0938 12.5H17.1875Z"
      fill="white"
    />
    {/* eslint-disable */}
  </svg>
);

export const LinkedinIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* eslint-disable */}
    <path
      d="M24.9939 25.0005V24.9995H25.0002V15.8307C25.0002 11.3452 24.0345 7.89001 18.7908 7.89001C16.2699 7.89001 14.5782 9.27336 13.8876 10.5848H13.8147V8.30877H8.84277V24.9995H14.0199V16.7349C14.0199 14.5588 14.4324 12.4546 17.1272 12.4546C19.7824 12.4546 19.822 14.938 19.822 16.8744V25.0005H24.9939Z"
      fill="#616161"
    />
    <path d="M0.412109 8.30933H5.59548V25.0001H0.412109V8.30933Z" fill="#616161" />
    <path
      d="M3.0021 0C1.3448 0 0 1.3448 0 3.0021C0 4.6594 1.3448 6.03233 3.0021 6.03233C4.6594 6.03233 6.0042 4.6594 6.0042 3.0021C6.00316 1.3448 4.65836 0 3.0021 0V0Z"
      fill="#616161"
    />
    {/* eslint-disable */}
  </svg>
);

export const YoutubeIcon = () => (
  <svg
    width="24"
    height="17"
    viewBox="0 0 24 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* eslint-disable */}
    <path
      d="M0.537084 14.208C1.02675 15.9263 2.52471 16.2407 2.62464 16.301C5.08994 16.9654 18.9084 16.9673 21.4107 16.301C23.1245 15.8106 23.4393 14.3069 23.4982 14.208C24.1508 10.7735 24.1937 6.29587 23.4682 2.39862L23.4982 2.5924C23.0085 0.874131 21.5106 0.559741 21.4107 0.499433C18.9783 -0.154062 5.12391 -0.178779 2.62464 0.499433C0.910825 0.990791 0.596043 2.49353 0.537084 2.5924C-0.161433 6.20195 -0.196409 10.3434 0.537084 14.208ZM9.61681 12.0103V4.80005L15.8785 8.41059L9.61681 12.0103Z"
      fill="#616161"
    />
    {/* eslint-disable */}
  </svg>
);

export const WhatsIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* eslint-disable */}
    <circle cx="12.5" cy="12.5" r="12.5" fill="#616161" />
    <path
      d="M4.5918 20.4082L5.70886 16.3467C5.01955 15.1578 4.65735 13.8101 4.65801 12.4282C4.66 8.1077 8.19262 4.59186 12.5331 4.59186C14.6394 4.59252 16.6166 5.40903 18.1038 6.8905C19.5904 8.37196 20.4088 10.3411 20.4081 12.4354C20.4061 16.7566 16.8735 20.2724 12.5331 20.2724C11.2154 20.2718 9.91688 19.9429 8.76671 19.3182L4.5918 20.4082ZM8.96006 17.8993C10.0698 18.555 11.1293 18.9478 12.5304 18.9485C16.1379 18.9485 19.0765 16.0264 19.0785 12.4341C19.0798 8.83459 16.1551 5.91648 12.5357 5.91516C8.92563 5.91516 5.98895 8.83722 5.98763 12.4288C5.98697 13.8952 6.41869 14.9931 7.14376 16.1417L6.48226 18.5458L8.96006 17.8993ZM16.5001 14.2985C16.4511 14.2168 16.32 14.168 16.1226 14.0698C15.926 13.9716 14.9586 13.4978 14.7778 13.4325C14.5977 13.3673 14.4666 13.3343 14.3348 13.5307C14.2037 13.7264 13.8263 14.168 13.7117 14.2985C13.5972 14.429 13.4819 14.4454 13.2853 14.3472C13.0886 14.249 12.4543 14.0428 11.7027 13.3752C11.118 12.8559 10.7227 12.2147 10.6082 12.0183C10.4936 11.8226 10.5963 11.7165 10.6943 11.6189C10.783 11.5313 10.8909 11.3902 10.9896 11.2756C11.0896 11.1622 11.122 11.0805 11.1882 10.9494C11.2538 10.8189 11.2213 10.7042 11.1717 10.606C11.122 10.5085 10.7287 9.54434 10.5651 9.15223C10.4049 8.77066 10.2427 8.82207 10.1221 8.81613L9.74472 8.80955C9.61361 8.80955 9.40039 8.85831 9.22029 9.0547C9.04018 9.25108 8.53164 9.72426 8.53164 10.6884C8.53164 11.6525 9.23684 12.5837 9.33484 12.7142C9.4335 12.8447 10.7221 14.823 12.696 15.6712C13.1654 15.8729 13.5323 15.9935 13.8177 16.0837C14.2891 16.2327 14.7182 16.2116 15.0572 16.1615C15.4353 16.1055 16.2213 15.6877 16.3855 15.2303C16.5497 14.7723 16.5497 14.3802 16.5001 14.2985Z"
      fill="white"
    />
    {/* eslint-disable */}
  </svg>
);

export const TiktokIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* eslint-disable */}
    <g clipPath="url(#clip0)">
      <path
        d="M23.197 0H1.80303C0.807178 0 0 0.807178 0 1.80303V23.197C0 24.1928 0.807178 25 1.80303 25H23.197C24.1928 25 25 24.1928 25 23.197V1.80303C25 0.807178 24.1928 0 23.197 0V0ZM18.7621 9.56831V11.2584C17.9651 11.2587 17.1907 11.1025 16.4604 10.7944C15.9909 10.5961 15.5534 10.3407 15.1532 10.032L15.1652 15.2343C15.1602 16.4057 14.6968 17.5063 13.858 18.3354C13.1754 19.0102 12.3104 19.4393 11.3729 19.5782C11.1526 19.6108 10.9284 19.6275 10.7017 19.6275C9.69814 19.6275 8.74531 19.3024 7.96592 18.7026C7.81924 18.5896 7.679 18.4672 7.54541 18.3354C6.63638 17.4369 6.16763 16.2191 6.24653 14.9381C6.30679 13.963 6.69717 13.033 7.348 12.3041C8.20908 11.3396 9.41372 10.8042 10.7017 10.8042C10.9284 10.8042 11.1526 10.8211 11.3729 10.8538V11.4787V13.217C11.1641 13.1481 10.941 13.1103 10.7087 13.1103C9.53193 13.1103 8.57993 14.0703 8.59751 15.2479C8.60864 16.0014 9.02026 16.66 9.6273 17.023C9.91255 17.1937 10.2408 17.2994 10.591 17.3186C10.8654 17.3336 11.1289 17.2955 11.3729 17.2149C12.2136 16.9372 12.8201 16.1475 12.8201 15.2161L12.8229 11.7324V5.37246H15.1504C15.1527 5.60308 15.1761 5.82812 15.2196 6.04614C15.3953 6.92871 15.8927 7.69443 16.5851 8.21645C17.1888 8.67183 17.9406 8.94175 18.7554 8.94175C18.756 8.94175 18.7626 8.94175 18.7621 8.94121V9.56831H18.7621Z"
        fill="#616161"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="25" height="25" fill="white" />
      </clipPath>
    </defs>
    {/* eslint-disable */}
  </svg>
);
