import { useRouter } from 'next/router';
import { string } from 'prop-types';
import Button from 'atoms/button';

import { ContactIcon } from '#helpers/icons/dashboard-icons';

const NeedSupportCard = ({ url }) => {
  const router = useRouter();
  const redirect = url => () => router.push(url);
  return (
    <div className="need_support_card">
      <ContactIcon />
      <p className="need_support-Title">¿Necesitas ayuda?</p>
      <Button variant="radius-white" onClick={redirect(url)}>
        Contáctanos
      </Button>
    </div>
  );
};

NeedSupportCard.propTypes = { url: string.isRequired };

export default NeedSupportCard;
