import {
 array, bool, func, string 
} from 'prop-types';
import cx from 'classnames';

const SelectField = ({
  name,
  options,
  placeholder,
  register,
  error,
  isRequired,
  variant,
  onChange,
  selected,
  value,
  testid
}) => {
  return (
    <select
      data-testid={testid}
      value={value}
      onChange={onChange}
      className={
        variant ? cx(`select-field-${variant}`, { error }) : cx('select-field', { error })
      }
      name={name}
      ref={register}
      required={isRequired ? true : false}
      role="combobox"
    >
      <option role="option" className="placeholder" value={''} disabled selected hidden>
        {placeholder}
      </option>
      {options.map(option => (
        <option key={option.value} role="option" value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

SelectField.propTypes = {
  name: string.isRequired,
  placeholder: string.isRequired,
  options: array.isRequired,
  register: func,
  error: bool,
  variant: string
};

export default SelectField;
