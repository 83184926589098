import { array } from 'prop-types';
import { useState, useRef } from 'react';

import { Typography, CertificateCard } from '#atoms/index';

const CertificateTile = ({ certificates }) => {
  const content = useRef(null);
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');

  const toggleAccordion = () => {
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`);
  };

  return (
    <div className="certificate-Tile">
      <Typography variant="h4">Certificados</Typography>
      <div className="certificate-list">
        {certificates.length <= 0 ? (
          <Typography variant="body">
            Aún no tienes un certificado 😕, termina un curso para recibir uno 🙌
          </Typography>
        ) : (
          certificates
            .slice(0, 2)
            .map((certificade, idx) => (
              <CertificateCard key={idx} certificade={certificade} />
            ))
        )}
      </div>
      {certificates.length > 2 && (
        <>
          <div
            ref={content}
            style={{ maxHeight: `${setHeight}` }}
            className="accordion__content"
          >
            <div className="certificate-list">
              {certificates.slice(2).map((certificade, idx) => (
                <CertificateCard key={idx} certificade={certificade} />
              ))}
            </div>
          </div>

          <button className="accordion btn-accordion" onClick={toggleAccordion}>
            {setActive ? 'ver menos certificados' : 'ver más certificados'}
          </button>
        </>
      )}
    </div>
  );
};

CertificateTile.propTypes = { certificates: array.isRequired };

export default CertificateTile;
