import { shape } from 'prop-types';

import { Image, Typography } from '#atoms/index';
import { Breadcrumbs } from '#components/index';

const CourseHero = ({ data }) => (
  <section className="course-hero">
    <div className="course-hero__image">
      <Image
        src={
          data.fields.mainImage
            ? data.fields.mainImage.fields.file.url
            : data.fields.backgroundimage.fields.file.url
        }
        alt={data.fields.h1Tag}
      />
    </div>
    <div className="course-hero__title">
      <Breadcrumbs
        routes={[
          { name: 'Cursos', pathname: '/cursos' },
          { name: data.fields.name, pathname: 'end' }
        ]}
      />
      <Typography variant="h1" tag="h2">
        {data.fields.name}
      </Typography>
      {data.fields.ageRange.fields && (
        <Typography variant="body" fontWeight="medium">
          — De {data.fields.ageRange.fields.name} años —
        </Typography>
      )}
    </div>
    <div className="course-hero__subtitle">
      <Typography variant="h4 no-margin-bottom" tag="h1">
        {data.fields.h1Tag}
      </Typography>
      <Typography variant="h4" tag="h3">
        {data.fields.shortDescription}
      </Typography>
    </div>
  </section>
);

CourseHero.propTypes = { data: shape() };

export default CourseHero;
