import React from 'react';

import {
  Image,
  TagDiscount,
  Paragraph,
  IconCalendar,
  IconReminder
} from '#styleguide/index';
import { ButtonComponent, Link } from '#atoms/index';
import { parseDuration, renameCoursePerYear } from '#helpers/path-learning';
import { parsePathDateMonth } from '#helpers/parsers';

const CardRecommendedCourse = ({
  ageRange,
  image,
  nameCourse,
  duration,
  startDate,
  discount,
  type,
  pathname,
  nivel
}) => {
  const verCurso = e => {
    window.localStorage.setItem('thumbnail', image);
  };

  return (
    <div className="ui-card-recommended-course">
      <div className="ui-card-recommended-course__container-image">
        {discount > 0 && <TagDiscount discount={discount} />}
        {image && <Image src={image} alt="curso" width={237} height={120} />}
      </div>

      <div className="ui-card-recommended-course__container-information">
        <div className="ui-card-recommended-course__details-information">
          <section className="ui-card-recommended-course__content-title">
            <Paragraph color="#737373" weight={700}>
              {ageRange && `${ageRange} años`}
            </Paragraph>
            <Paragraph size="21" weight={700}>
              {renameCoursePerYear(nameCourse)}
            </Paragraph>
          </section>
          <section className="ui-card-recommended-course__content-date">
            <div className="ui-card-recommended-course__duration">
              <IconCalendar />
              <div className="ui-card-recommended-course__duration-text">
                <Paragraph size="12">Duración</Paragraph>
                {duration >= 10 ? (
                  <Paragraph size="16" weight={700}>
                    {`${parseDuration(duration)} m.`}
                  </Paragraph>
                ) : duration > 1 ? (
                  <Paragraph size="16" weight={700}>
                    {`${parseDuration(duration)} meses`}
                  </Paragraph>
                ) : (
                  <Paragraph size="16" weight={700}>
                    {`${parseDuration(duration)} mes`}
                  </Paragraph>
                )}
              </div>
            </div>
            <div className="ui-card-recommended-course__start-date">
              <IconReminder />
              <div className="ui-card-recommended-course__start-date-text">
                <Paragraph size="12">Inicio</Paragraph>
                {startDate ? (
                  <Paragraph size="16" weight={700}>
                    {parsePathDateMonth(startDate)}
                  </Paragraph>
                ) : (
                  <Paragraph size="16" weight={700}>
                    Próximamente
                  </Paragraph>
                )}
              </div>
            </div>
          </section>
        </div>

        {type === 'course' ? (
          <Link href="https://api.whatsapp.com/send?phone=17867892469&text=Deseo%20recibir%20informaci%C3%B3n%20sobre%20Crack%20The%20Code">
            <ButtonComponent
              description="Ver curso"
              variantPage="curso"
              variantButton="black"
              boxShadow={false}
              align="left"
              width={'100%'}
              onClick={e => verCurso(e)}
            />
          </Link>
        ) : (
          <Link href="https://api.whatsapp.com/send?phone=17867892469&text=Deseo%20recibir%20informaci%C3%B3n%20sobre%20Crack%20The%20Code">
            <ButtonComponent
              description="Ver ruta"
              variantPage="curso"
              variantButton="black"
              boxShadow={false}
              align="left"
              width={'100%'}
              onClick={e => verCurso(e)}
            />
          </Link>
        )}
      </div>
    </div>
  );
};

export default CardRecommendedCourse;
