const IconArrowRightSimple = ({ fill = '#000000', className }) => (
  <svg
    className={className}
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.44814 15.3354C6.03243 15.7561 5.35303 15.7561 4.93732 15.3354V15.3354C4.52867 14.9217 4.52867 14.2564 4.93732 13.8427L10.3145 8.39998L4.93732 2.95722C4.52867 2.54359 4.52867 1.87823 4.93732 1.4646V1.4646C5.35304 1.04382 6.03243 1.04382 6.44814 1.4646L11.9113 6.99436C12.681 7.7734 12.681 9.02655 11.9113 9.8056L6.44814 15.3354Z"
      fill={fill}
    />
  </svg>
);

export default IconArrowRightSimple;
