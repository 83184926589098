import React from 'react';

const ProgressBar = ({
  progress = 0,
  color = '#dcdcdc',
  isFreeCourse = false,
  status,
  isEmpty
}) => {
  return (
    <div
      className={`ui-progress-bar ${
        isFreeCourse && !isEmpty
          ? 'ui-progress-bar--free-course'
          : !isFreeCourse && !isEmpty
          ? 'ui-progress-bar--path-course'
          : isEmpty
          ? 'ui-progress-bar--empty-progress'
          : 'ui-progress-bar--alternative'
      }`}
    >
      <div
        className="ui-progress-bar__fill"
        style={{
          width: status === 'has_ended' ? '100%' : `${progress}%`,
          backgroundColor: color
        }}
      ></div>
    </div>
  );
};

export default ProgressBar;
