const IconClockBold = ({ fill = '#000000', className, dataTestid = 'clock-bold' }) => (
  <svg
    className={className}
    data-testid={dataTestid}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={`${className}-path`}
      data-testid={`${dataTestid}-path`}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 0.875C8.99747 0.875 7.0399 1.46882 5.37486 2.58137C3.70981 3.69392 2.41206 5.27523 1.64572 7.12533C0.879387 8.97543 0.678878 11.0112 1.06955 12.9753C1.46023 14.9393 2.42454 16.7435 3.84055 18.1595C5.25656 19.5755 7.06066 20.5398 9.02471 20.9305C10.9888 21.3211 13.0246 21.1206 14.8747 20.3543C16.7248 19.5879 18.3061 18.2902 19.4186 16.6251C20.5312 14.9601 21.125 13.0025 21.125 11C21.1225 8.31544 20.055 5.74155 18.1567 3.84329C16.2585 1.94502 13.6846 0.877482 11 0.875ZM11 18.875C9.44248 18.875 7.91993 18.4131 6.62489 17.5478C5.32985 16.6825 4.32049 15.4526 3.72445 14.0136C3.12841 12.5747 2.97246 10.9913 3.27632 9.46366C3.58018 7.93606 4.3302 6.53287 5.43154 5.43153C6.53288 4.3302 7.93607 3.58017 9.46367 3.27632C10.9913 2.97246 12.5747 3.12841 14.0136 3.72445C15.4526 4.32049 16.6825 5.32985 17.5478 6.62488C18.4131 7.91992 18.875 9.44247 18.875 11C18.8725 13.0878 18.042 15.0894 16.5657 16.5657C15.0894 18.042 13.0878 18.8725 11 18.875ZM17.375 11C17.375 11.2984 17.2565 11.5845 17.0455 11.7955C16.8345 12.0065 16.5484 12.125 16.25 12.125H11C10.7016 12.125 10.4155 12.0065 10.2045 11.7955C9.99353 11.5845 9.875 11.2984 9.875 11V5.75C9.875 5.45163 9.99353 5.16548 10.2045 4.9545C10.4155 4.74353 10.7016 4.625 11 4.625C11.2984 4.625 11.5845 4.74353 11.7955 4.9545C12.0065 5.16548 12.125 5.45163 12.125 5.75V9.875H16.25C16.5484 9.875 16.8345 9.99353 17.0455 10.2045C17.2565 10.4155 17.375 10.7016 17.375 11Z"
      fill={fill}
    />
  </svg>
);

export default IconClockBold;
