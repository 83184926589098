const IconProgressCheck = ({
  fill = '#000000',
  className,
  dataTestid = 'progress-check'
}) => (
  <svg
    className={className}
    data-testid={dataTestid}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={`${className}-path`}
      data-testid={`${dataTestid}-path`}
      d="M11 0.0300293V2.05003C15.39 2.59003 18.5 6.58003 17.96 10.97C17.5 14.61 14.64 17.5 11 17.93V19.93C16.5 19.38 20.5 14.5 19.9501 9.00003C19.5 4.25003 15.73 0.500029 11 0.0300293ZM9.00005 0.0600293C7.05005 0.250029 5.19005 1.00003 3.67005 2.26003L5.10005 3.74003C6.22005 2.84003 7.57005 2.26003 9.00005 2.06003V0.0600293ZM2.26005 3.67003C1.0097 5.18737 0.240251 7.04311 0.0500488 9.00003H2.05005C2.24005 7.58003 2.80005 6.23003 3.69005 5.10003L2.26005 3.67003ZM13.5 6.50003L8.62005 11.38L6.50005 9.26003L5.44005 10.32L8.62005 13.5L14.56 7.56003L13.5 6.50003ZM0.0600488 11C0.260049 12.96 1.03005 14.81 2.27005 16.33L3.69005 14.9C2.80698 13.7696 2.244 12.4226 2.06005 11H0.0600488ZM5.10005 16.37L3.67005 17.74C5.18483 19.0027 7.03932 19.7891 9.00005 20V18C7.57745 17.8161 6.23045 17.2531 5.10005 16.37Z"
      fill={fill}
    />
  </svg>
);

export default IconProgressCheck;
