const IconCertificateCourse = ({ fill = '#000000', className }) => (
  <svg
    width="60"
    height="40"
    viewBox="0 0 60 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_dd_601_16621)">
      <rect x="4" width="52" height="32" rx="3" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.6607 7.72015C33.6607 9.51447 32.1734 10.9691 30.3388 10.9691C28.5041 10.9691 27.0168 9.51447 27.0168 7.72015C27.0168 5.92582 28.5041 4.47123 30.3388 4.47123C32.1734 4.47123 33.6607 5.92582 33.6607 7.72015ZM34.6098 7.72015C34.6098 10.0271 32.6976 11.8973 30.3388 11.8973C27.9799 11.8973 26.0677 10.0271 26.0677 7.72015C26.0677 5.41315 27.9799 3.54297 30.3388 3.54297C32.6976 3.54297 34.6098 5.41315 34.6098 7.72015ZM30.3416 10.0404C31.652 10.0404 32.7144 9.00141 32.7144 7.71974C32.7144 6.43808 31.652 5.39909 30.3416 5.39909C29.0311 5.39909 27.9688 6.43808 27.9688 7.71974C27.9688 9.00141 29.0311 10.0404 30.3416 10.0404ZM26.9449 10.9648L25.0658 14.148L27.2785 13.5682L27.8686 15.7221L29.8658 12.3388C28.7252 12.2285 27.7032 11.7233 26.9449 10.9648ZM30.7607 12.3433L32.7563 15.7239L33.3468 13.5684L35.5578 14.1478L33.6983 10.9977C32.9323 11.7488 31.9048 12.2446 30.7607 12.3433ZM27.6646 16.0675L24.8657 14.487L24.8646 14.4889L27.6635 16.0694L27.6646 16.0675ZM35.7585 14.4878L32.9596 16.0683L32.9602 16.0694L35.7592 14.4889L35.7585 14.4878ZM19.4375 17.5999H41.375V19.1999H19.4375V17.5999ZM23.5 20.8001H36.9382V22.5144H23.5V20.8001ZM44.2266 27.1999H36.0469V28.3427H44.2266V27.1999ZM16.1875 27.1999H24.3673V28.3427H16.1875V27.1999Z"
        fill="url(#paint0_linear_601_16621)"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_601_16621"
        x="0"
        y="0"
        width="60"
        height="40"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_601_16621"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_601_16621"
          result="effect2_dropShadow_601_16621"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_601_16621"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_601_16621"
        x1="30.2071"
        y1="3.33333"
        x2="30.2071"
        y2="28.3427"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B15EF2" />
        <stop offset="1" stopColor="#5CAEFA" />
      </linearGradient>
    </defs>
  </svg>
);

export default IconCertificateCourse;
