import Typography from 'atoms/typography';

import { asksFrecuencyContactUsDashboard } from '#helpers/data-helper';
const FrecuencyAskCard = () => {
  return (
    <div className="frecuency_ask-Container">
      <Typography variant="h2">Preguntas frecuentes</Typography>
      {asksFrecuencyContactUsDashboard.map((ask, idx) => (
        <div key={idx} className="ask_item">
          {ask.moreInfo ? (
            <li>
              <a
                className="link"
                href={ask.moreInfo}
                target="_blank"
                style={{ textDecoration: 'underline' }}
                rel="noreferrer"
              >
                {ask.ask}
              </a>
            </li>
          ) : (
            <>
              <li>{ask.ask}</li>
              <Typography variant="body">{ask.answer}</Typography>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default FrecuencyAskCard;
