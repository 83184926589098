/* eslint-disable no-unused-vars */
import { useState, useContext } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';

import {
  CardPaymentVacation,
  ScheduleVacation,
  TechnicalRequirementsVacation
} from '#components/index';
import { useUser, useUserGeolocalization } from '#hooks/index';
import { ButtonComponentVacation } from '#atoms/index';
import PeopleClassIcon from '#helpers/icons/people-class-icon';
import ScheduleIcon from '#helpers/icons/schedule-icon';
import WatchIcon from '#helpers/icons/watch-icon';
import { getTime, parseDayString } from '#helpers/parsers';
import { event } from '#helpers/gtag';

import GlobalContext from '../../context/Global/GlobalContext';

const RealidadAumentada = dynamic(() =>
  import('#helpers/icons/vacation-icons').then(module => module.RealidadAumentada)
);

const PixelArtGif = dynamic(() =>
  import('#helpers/icons/vacation-icons').then(module => module.PixelArtGif)
);

const JuegoScratch = dynamic(() =>
  import('#helpers/icons/vacation-icons').then(module => module.JuegoScratch)
);

const RobloxJunior = dynamic(() =>
  import('#helpers/icons/vacation-icons').then(module => module.RobloxJunior)
);

const VideojuegosRetroNintendoIcon = dynamic(() =>
  import('#helpers/icons/vacation-icons').then(
    module => module.VideojuegosRetroNintendoIcon
  )
);

const PokemonGoIcon = dynamic(() =>
  import('#helpers/icons/vacation-icons').then(module => module.PokemonGoIcon)
);

const ScratchAiIcon = dynamic(() =>
  import('#helpers/icons/vacation-icons').then(module => module.ScratchAiIcon)
);

const RobloxAnimationsIcon = dynamic(() =>
  import('#helpers/icons/vacation-icons').then(module => module.RobloxAnimationsIcon)
);

const IllustratorIcon = dynamic(() =>
  import('#helpers/icons/vacation-icons').then(module => module.IllustratorIcon)
);

const PhotoshopIcon = dynamic(() =>
  import('#helpers/icons/vacation-icons').then(module => module.PhotoshopIcon)
);

const PythonIcon = dynamic(() =>
  import('#helpers/icons/vacation-icons').then(module => module.PythonIcon)
);

const JavascriptIcon = dynamic(() =>
  import('#helpers/icons/vacation-icons').then(module => module.JavascriptIcon)
);

const DiscordBotIcon = dynamic(() =>
  import('#helpers/icons/vacation-icons').then(module => module.DiscordBotIcon)
);

const MinecraftPythonIcon = dynamic(() =>
  import('#helpers/icons/vacation-icons').then(module => module.MinecraftPythonIcon)
);

const BubbleIcon = dynamic(() =>
  import('#helpers/icons/vacation-icons').then(module => module.BubbleIcon)
);

const CheckScheduleIcon = dynamic(() =>
  import('#helpers/icons/vacation-icons').then(module => module.CheckScheduleIcon)
);

const KidlocodingIcon = dynamic(() =>
  import('#helpers/icons/vacation-icons').then(module => module.KidlocodingIcon)
);

const P5jsIcon = dynamic(() =>
  import('#helpers/icons/vacation-icons').then(module => module.P5jsIcon)
);

// Utiliza los componentes importados dinámicamente en tu código

const SectionPaymentVacation = props => {
  const {
    mainColor,
    secondaryColor,
    supportColor,
    icon,
    subscriptionProgram,
    requirements,
    ageRange,
    program,
    setProgram,
    myRef
  } = props;
  const router = useRouter();
  const { existToken } = useUser();
  const { data: geolocalizationData } = useUserGeolocalization();
  const [alert, setAlert] = useState('');
  const [schedule, setSchedule] = useState(getTime('2021-12-08T14:00:00.000Z'));
  let subsProgramNumber =
    /* ageRange === '5_to_7' ? subscriptionProgram[1] : */ subscriptionProgram[program];
  const comercialName = subsProgramNumber?.name?.split(',');
  const {
    updatePurchaseDescription,
    updateDataForm,
    updatePurchaseFlowType,
    updateTotalStep,
    goForward,
    updateCurrentPosition,
    isLoginUser
  } = useContext(GlobalContext);

  const onClickPurchaseIndividualProgram = (e, data) => {
    e.preventDefault();
    const currency = geolocalizationData.countryCode === 'PE' ? 'PEN' : 'USD';
    let scheduleSelected = data?.groups?.find(group =>
      group.frequency.find(time => getTime(time?.time_start) === schedule)
    );

    if (schedule) {
      setAlert('');
      updateTotalStep(4);
      updatePurchaseFlowType('purchase-flow');
      updatePurchaseDescription({
        age_range: data?.age_range,
        before_price: data?.has_discount
          ? currency === 'PEN'
            ? data?.amount_pen
            : data?.amount_usd
          : '',
        courses_in_path: data?.courses?.map(course => course.id),
        currencyCode: currency,
        duration: data.duration,
        exist_discount: data?.has_discount,
        ico: null,
        id_group: scheduleSelected?.id,
        id_pathlearning: '',
        id_variations: null,
        isFullPath: false,
        isSubscription: false,
        is_kit: null,
        is_variations: null,
        name_pathlearning: '',
        name_product: comercialName[0],
        number_course: data?.courses[0].id,
        price: data?.has_discount
          ? currency === 'PEN'
            ? data?.amount_after_discount_pen
            : data?.amount_after_discount_usd
          : currency === 'PEN'
          ? data?.amount_pen
          : data?.amount_usd,
        price_per_month: '',
        schedule: scheduleSelected?.frequency,
        days_start: scheduleSelected.frequency
          .map(elem => parseDayString(elem.day_name))
          .join(' y '),
        start_date: scheduleSelected?.start_date,
        typeSubscription: null,
        type_purchase: comercialName[0]
      });
      updateDataForm({
        guardian_country: geolocalizationData.country,
        guardian_city: geolocalizationData.city
      });
      if (data.has_discount) {
        updateDataForm({ discount_off: Math.floor(data?.discount_percentage) });
      }
      if (existToken()) {
        goForward();
        updateCurrentPosition('payment_form');
        isLoginUser(true);
      }
      //google event
      event('start-purchase', 'purchase-flow', 'Émpieza el flujo de compra');
      router.push('/enrollment');
    } else {
      setAlert('***Debe seleccionar un horario');
    }
  };

  const onClickPurchasePlusProgram = (e, data) => {
    e.preventDefault();
    const currency = geolocalizationData.countryCode === 'PE' ? 'PEN' : 'USD';
    let scheduleSelected = data?.groups?.find(group =>
      group.frequency.find(time => getTime(time?.time_start) === schedule)
    );
    const courses_in_path = scheduleSelected.groups_to_buy.map(elem => elem.course);
    const array_group = scheduleSelected.groups_to_buy.map(elem => elem.group);
    const first_course = [courses_in_path[0]];
    const second_course = [courses_in_path[1]];

    if (schedule) {
      setAlert('');
      updateTotalStep(4);
      updatePurchaseFlowType('purchase-flow');
      updatePurchaseDescription({
        age_range: data?.age_range,
        before_price: data?.has_discount
          ? currency === 'PEN'
            ? data?.amount_pen
            : data?.amount_usd
          : '',
        courses_in_path: courses_in_path[0],
        firstCourse: first_course,
        secondCourse: second_course,
        currencyCode: currency,
        duration: 1,
        exist_discount: data?.has_discount,
        ico: null,
        id_group: scheduleSelected?.id,
        isArrayGroup: true,
        array_group: array_group,
        id_pathlearning: '',
        id_variations: null,
        isFullPath: false,
        isSubscription: false,
        is_kit: null,
        is_variations: null,
        name_pathlearning: '',
        name_product: comercialName[0],
        number_course: data?.courses[0].id,
        price: data?.has_discount
          ? currency === 'PEN'
            ? data?.amount_after_discount_pen
            : data?.amount_after_discount_usd
          : currency === 'PEN'
          ? data?.amount_pen
          : data?.amount_usd,
        price_per_month: '',
        schedule: scheduleSelected?.frequency,
        days_start: scheduleSelected.frequency
          .map(elem => parseDayString(elem.day_name))
          .join(' , '),
        start_date: scheduleSelected?.start_date,
        typeSubscription: null,
        type_purchase: comercialName[0]
      });
      updateDataForm({
        guardian_country: geolocalizationData.country,
        guardian_city: geolocalizationData.city
      });
      if (data.has_discount) {
        updateDataForm({ discount_off: Math.floor(data?.discount_percentage) });
      }
      if (existToken()) {
        goForward();
        updateCurrentPosition('payment_form');
        isLoginUser(true);
      }
      //google event
      event('start-purchase', 'purchase-flow', 'Émpieza el flujo de compra');
      router.push('/enrollment');
    } else {
      setAlert('***Debe seleccionar un horario');
    }
  };

  const renderIcon = iconName => {
    switch (iconName) {
      case 'realidad-aumentada':
        return <RealidadAumentada fill={secondaryColor && secondaryColor} />;
      case 'pixel-art-gif':
        return <PixelArtGif fill={secondaryColor && secondaryColor} />;
      case 'juego-scratch':
        return <JuegoScratch fill={secondaryColor && secondaryColor} />;
      case 'roblox-junior':
        return <RobloxJunior fill={secondaryColor && secondaryColor} />;

      case 'videojuegos-retro':
        return <VideojuegosRetroNintendoIcon fill={secondaryColor && secondaryColor} />;
      case 'pokemon-go':
        return <PokemonGoIcon fill={secondaryColor && secondaryColor} />;
      case 'scratch-ai':
        return <ScratchAiIcon fill={secondaryColor && secondaryColor} />;
      case 'roblox-animations':
        return <RobloxAnimationsIcon fill={secondaryColor && secondaryColor} />;
      case 'illustrator':
        return <IllustratorIcon fill={secondaryColor && secondaryColor} />;
      case 'photoshop':
        return <PhotoshopIcon fill={secondaryColor && secondaryColor} />;

      case 'python':
        return <PythonIcon fill={secondaryColor && secondaryColor} />;
      case 'javascript':
        return <JavascriptIcon fill={secondaryColor && secondaryColor} />;
      case 'discord-bot':
        return <DiscordBotIcon fill={secondaryColor && secondaryColor} />;
      case 'minecraft-python':
        return <MinecraftPythonIcon fill={secondaryColor && secondaryColor} />;

      case 'bubble':
        return <BubbleIcon fill={secondaryColor && secondaryColor} />;
      case 'p5js':
        return <P5jsIcon fill={secondaryColor && secondaryColor} />;
      case 'kidlocoding':
        return <KidlocodingIcon fill={secondaryColor && secondaryColor} />;
      default:
        return null;
    }
  };
  /* TODO: PROGRAMAS */
  return (
    <div className="section-payment-vacation" ref={myRef}>
      <h1 className="section-payment-vacation__title section-payment-vacation__title--bold">
        Tenemos varios Programas, elige el mejor para ti y tu pequeñ@
      </h1>
      <div className="section-payment-vacation__container-cards">
        {subscriptionProgram.slice(0, 2).map((suscription, idx) => (
          <CardPaymentVacation
            key={idx}
            index={idx}
            mainColor={mainColor}
            secondaryColor={secondaryColor}
            icon={icon}
            name={suscription?.name}
            courses={suscription?.courses}
            frequency={suscription?.frequency}
            program={program}
            setProgram={setProgram}
            ageRange={ageRange}
          />
        ))}
      </div>
      {/* //TODO: DETALLE PROGRAMA - detalle segun index */}
      <div className="section-payment-vacation__container-details">
        <h1 className="section-payment-vacation__details-title section-payment-vacation__details-title--bold">
          Descripción del {comercialName ? comercialName[0] : ''}
        </h1>
        <p className="section-payment-vacation__details-description">
          {subsProgramNumber?.description}
        </p>
        <ul className="section-payment-vacation__class-details">
          <li className="section-payment-vacation__class-item">
            <div className="section-payment-vacation__class-icon-online"></div>
            <span className="section-payment-vacation__class-item-text">
              Clases online{' '}
              <span className="section-payment-vacation__class-item-text--bold">
                en vivo
              </span>
            </span>
          </li>
          <li className="section-payment-vacation__class-item">
            <PeopleClassIcon fill={secondaryColor} />
            <span className="section-payment-vacation__class-item-text">
              Inicia en{' '}
              <span className="section-payment-vacation__class-item-text--bold">
                {' '}
                Febrero 2022
              </span>
            </span>
          </li>
          <li className="section-payment-vacation__class-item">
            <ScheduleIcon fill={secondaryColor} />
            <span className="section-payment-vacation__class-item-text">
              <span className="section-payment-vacation__class-item-text--bold">
                6 alumnos
              </span>{' '}
              por clase
            </span>
          </li>
          <li className="section-payment-vacation__class-item">
            <WatchIcon fill={secondaryColor} />
            <span className="section-payment-vacation__class-item-text">
              {program === 2 ? '16 sesiones de' : '8 sesiones de'}{' '}
              <span className="section-payment-vacation__class-item-text--bold">
                90 minutos
              </span>
            </span>
          </li>
        </ul>
        {/* //TODO: DETALLE PROGRAMA - detalle segun index */}
        <h1 className="section-payment-vacation__details-title section-payment-vacation__details-title--bold">
          Contenido
        </h1>
        <p className="section-payment-vacation__details-description">
          El programa se compone de los siguientes cursos:
        </p>
        <div className="section-payment-vacation__container-courses">
          {program === 0 || program === 1 ? (
            subsProgramNumber?.courses ? (
              subsProgramNumber?.courses[0]?.modules.map((module, idx) => (
                <section key={idx} className="section-payment-vacation__section-courses">
                  <div
                    style={{ background: mainColor }}
                    className="section-payment-vacation__courses-icon"
                  >
                    {renderIcon(module?.icon)}
                  </div>
                  <div className="section-payment-vacation__courses-title">
                    <h2 style={{ color: secondaryColor }}>{module?.name}</h2>
                  </div>
                  <div className="section-payment-vacation__courses-description">
                    <p>{module?.description}</p>
                  </div>
                </section>
              ))
            ) : (
              <p>Sin cursos</p>
            )
          ) : program === 2 ? (
            <>
              {subsProgramNumber?.courses ? (
                subsProgramNumber?.courses[0]?.modules.map((module, idx) => (
                  <section
                    key={idx}
                    className="section-payment-vacation__section-courses"
                  >
                    <div
                      style={{ background: mainColor }}
                      className="section-payment-vacation__courses-icon"
                    >
                      {renderIcon(module?.icon)}
                    </div>
                    <div className="section-payment-vacation__courses-title">
                      <h2 style={{ color: secondaryColor }}>{module?.name}</h2>
                    </div>
                    <div className="section-payment-vacation__courses-description">
                      <p>{module?.description}</p>
                    </div>
                  </section>
                ))
              ) : (
                <p>Sin cursos</p>
              )}
              {subsProgramNumber?.courses[1].modules.map((module, idx) => (
                <section key={idx} className="section-payment-vacation__section-courses">
                  <div
                    style={{ background: mainColor }}
                    className="section-payment-vacation__courses-icon"
                  >
                    {renderIcon(module?.icon)}
                  </div>
                  <div className="section-payment-vacation__courses-title">
                    <h2 style={{ color: secondaryColor }}>{module?.name}</h2>
                  </div>
                  <div className="section-payment-vacation__courses-description">
                    <p>{module?.description}</p>
                  </div>
                </section>
              ))}
            </>
          ) : (
            ''
          )}
        </div>
        <div className="section-payment-vacation__container-button">
          <ButtonComponentVacation
            description="Descarga el plan de estudios"
            variantPage="course"
            variantButton="outlined"
            iconDownload={true}
            align="center"
            width="335px"
            download={true}
            href={`https://ctc-web-statics-prod.s3.amazonaws.com/multimedia/${subsProgramNumber?.curriculum}`}
          />
        </div>
        <TechnicalRequirementsVacation
          requirements={requirements}
          mainColor={mainColor}
          secondaryColor={secondaryColor}
          supportColor={supportColor}
          ageRange={ageRange}
          program={program}
        />
        <ScheduleVacation
          schedule={schedule}
          setSchedule={setSchedule}
          groups={subsProgramNumber?.groups}
          mainColor={mainColor}
          secondaryColor={secondaryColor}
          supportColor={supportColor}
          ageRange={ageRange}
          myRef={myRef}
          daysSchedule={
            program === 0
              ? 'Lunes y miércoles'
              : program === 1
              ? 'Martes y jueves'
              : program === 2
              ? 'Lunes, martes, miércoles y jueves'
              : ''
          }
        />
        <h1 className="section-payment-vacation__details-title section-payment-vacation__details-title--bold">
          Pago
        </h1>
        <p className="section-payment-vacation__details-description">
          El programa se compone de los siguientes cursos:
        </p>
        <div
          className="section-payment-vacation__final-price"
          style={{ border: `2px solid ${secondaryColor}` }}
        >
          <div className="section-payment-vacation__section-price">
            {geolocalizationData && (
              <p
                className="section-payment-vacation__final-price-amount section-payment-vacation__final-price-amount--bold"
                style={{ color: secondaryColor }}
              >
                {geolocalizationData.countryCode === 'PE'
                  ? subsProgramNumber?.has_discount === true
                    ? `S/${subsProgramNumber?.amount_after_discount_pen} `
                    : `S/${subsProgramNumber?.amount_pen}`
                  : subsProgramNumber?.has_discount === true
                  ? `$${subsProgramNumber?.amount_after_discount_usd} USD`
                  : `$${subsProgramNumber?.amount_usd} USD`}
              </p>
            )}
            <p>Pago único</p>
          </div>
          <div className="section-payment-vacation__section-tools">
            <div
              className="section-payment-vacation__container-icon"
              style={{ backgroundColor: mainColor }}
            >
              <CheckScheduleIcon
                fill={secondaryColor ? secondaryColor : '#F0F0F0'}
                size="12"
              />
            </div>
            <p className="section-payment-vacation__subtitles-section">
              <span className="section-payment-vacation__subtitles-section--bold">
                Herramientas
              </span>
              <br />
              Se entregarán acceso a las herramientas necesarias
            </p>
          </div>
          <div className="section-payment-vacation__section-access">
            <div
              className="section-payment-vacation__container-icon"
              style={{ backgroundColor: mainColor }}
            >
              <CheckScheduleIcon
                fill={secondaryColor ? secondaryColor : '#F0F0F0'}
                size="12"
              />
            </div>
            <p className="section-payment-vacation__subtitles-section">
              <span className="section-payment-vacation__subtitles-section--bold">
                Accesos
              </span>
              <br />
              Se otorgarán credenciales para nuestras plataformas
            </p>
          </div>
        </div>
        <div className="section-payment-vacation__container-button-pay">
          {program === 0 ? (
            <ButtonComponentVacation
              description="Ir a Pagar"
              mainColor={secondaryColor}
              hoverClassColor={ageRange}
              iconArrowColor={true}
              width="210px"
              align="right"
              variantButton="containedColor"
              onClick={e => onClickPurchaseIndividualProgram(e, subsProgramNumber)}
            />
          ) : program === 1 ? (
            <ButtonComponentVacation
              description="Ir a Pagar"
              mainColor={secondaryColor}
              hoverClassColor={ageRange}
              iconArrowColor={true}
              width="210px"
              align="right"
              variantButton="containedColor"
              onClick={e => onClickPurchaseIndividualProgram(e, subsProgramNumber)}
            />
          ) : program === 2 ? (
            <ButtonComponentVacation
              variantButton="containedColor"
              description="Ir a Pagar"
              mainColor={secondaryColor}
              hoverClassColor={ageRange}
              iconArrowColor={true}
              width="210px"
              align="right"
              onClick={e => onClickPurchasePlusProgram(e, subsProgramNumber)}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SectionPaymentVacation;
