import { Link } from '#atoms/index';
const AnnouncementBar = ({ content, url }) => {
  return (
    <section className="announcement_bar">
      {url ? (
        <Link href={url}>
          <p>{content}</p>
        </Link>
      ) : (
        <p>{content}</p>
      )}
    </section>
  );
};

export default AnnouncementBar;
