import React from 'react';

import { Paragraph } from '#styleguide/index';

const TagDiscount = ({ discount }) => {
  return (
    <div className="ui-tag-discount">
      <Paragraph color="#FFFFFF" weight={700}>
        {discount}% Off
      </Paragraph>
    </div>
  );
};

export default TagDiscount;
