import React from 'react';

const NumberCourse = ({ number }) => {
  return (
    <div className="ui-number-course">
      <p className="ui-number-course__description">curso</p>
      {number ? (
        <>
          {number <= 9 ? (
            <p className="ui-number-course__number">0{number}</p>
          ) : (
            <p className="ui-number-course__number">{number}</p>
          )}
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default NumberCourse;
