import React, { useEffect, useState } from 'react';

import IconArrow from '#app/UI/atoms/icons/IconArrow';
import Paragraph from '#styleguide/atoms/typography/paragraph';
import Hr from '#styleguide/atoms/hr';
import { Link } from '#atoms/index';
import { parseDuration, renameCoursePerYear } from '#helpers/path-learning';

import Stepper from './stepper';
import CardPathCourseProgress from './card-path-course-progress';

const SectionPathCarousel = ({
  tab,
  currentCourseProp = 0,
  currentTab,
  childrenData,
  setObjectCalendar,
  coursesTab,
  userData,
  hasEnded,
  setCalendarRecurrence,
  setCalendarSplitRecurrence,
  setCalendarOptions
}) => {
  const [currentCourse, setCurrentCourse] = useState(currentCourseProp);
  const [isClose, setIsClose] = useState(hasEnded);

  const [courseInProgress, setCourseInProgress] = useState();

  useEffect(() => {
    const course = tab?.courses.filter(course => course.status === 'in_progress');
    setCourseInProgress(course[0]);
  }, [currentCourse]);

  return (
    <div className="ui-container-tab-line__section-path-carousel">
      <div
        className={`ui-container-tab-line__${
          isClose ? 'path-name-header-close' : 'path-name-header-open'
        }`}
      >
        {!hasEnded && (
          <Paragraph size="12" align="center">
            Ruta de aprendizaje
          </Paragraph>
        )}

        <Paragraph
          size="28"
          weight="700"
          color={tab?.learning_path_details[0]?.main_color}
          align="center"
        >
          {renameCoursePerYear(tab?.learning_path_name)}
        </Paragraph>
        <Paragraph
          size="16"
          weight="700"
          color={tab?.learning_path_details[0]?.main_color}
          align="center"
        >
          Nivel {tab?.learning_path_details[0]?.level}
        </Paragraph>
      </div>
      <section
        className={`ui-container-tab-line__${isClose ? 'section-close' : 'section-open'}`}
      >
        <Stepper
          setCalendarSplitRecurrence={setCalendarSplitRecurrence}
          setCalendarRecurrence={setCalendarRecurrence}
          setCalendarOptions={setCalendarOptions}
          currentTab={currentTab}
          dataStepper={tab?.courses}
          currentCourse={currentCourse}
          setCurrentCourse={setCurrentCourse}
          childName={childrenData?.first_name}
          quantitySteps={tab?.courses.length}
          fill={tab?.learning_path_details[0]?.main_color}
          colorsCourse={{
            main_color: tab?.learning_path_details[0]?.main_color,
            secondary_color: tab?.learning_path_details[0]?.secondary_color
          }}
          setObjectCalendar={setObjectCalendar}
          fullInfoCurrentCourse={courseInProgress}
          hasEnded={hasEnded}
        />
        <div className="ui-container-tab-line__item-content">
          <div
            className="ui-container-tab-line__item-content-card"
            style={{
              width: 'calc(100%)' /*   - 32px  */,
              display: 'flex',
              overflow: 'hidden'
            }}
          >
            {coursesTab && coursesTab.length > 0
              ? coursesTab.map((courseTab, idx) => (
                  <CardPathCourseProgress
                    hasCertificatePath={
                      tab?.courses[tab?.courses.length - 1]?.status === 'has_ended'
                    }
                    childName={childrenData?.first_name}
                    child={childrenData}
                    numberLastElement={tab?.courses.length}
                    key={idx}
                    number={idx}
                    data={courseTab}
                    pathDetails={tab}
                    currentCourse={currentCourse}
                    setCurrentCourse={setCurrentCourse}
                    hasEnded={hasEnded}
                    colorsCourse={{
                      main_color: tab?.learning_path_details[0]?.main_color,
                      secondary_color: tab?.learning_path_details[0]?.secondary_color
                    }}
                  />
                ))
              : null}
          </div>
        </div>
      </section>
      {hasEnded ? (
        <section>
          <Hr margin={27} />
        </section>
      ) : (
        <div className="ui-container-tab-line__item-content-details">
          <div className="ui-container-tab-line__path-detail">
            <Paragraph size="16">Ruta completa: &nbsp;</Paragraph>
            <Paragraph size="16">
              {parseDuration(tab?.learning_path_details[0]?.duration) === 1
                ? `${parseDuration(tab?.learning_path_details[0]?.duration)} mes`
                : `${parseDuration(tab?.learning_path_details[0]?.duration)} meses`}{' '}
              -{' '}
              {tab?.learning_path_details[0]?.frequency === 1
                ? `${tab?.learning_path_details[0]?.frequency} vez a la semana`
                : `${tab?.learning_path_details[0]?.frequency} veces a la semana`}
            </Paragraph>
          </div>

          <Link
            href={tab?.learning_path_details[0]?.curriculum}
            isFullWidth={false}
            external={true}
            gtagActionClick1="descarga-plan-de-estudios"
            gtagCategoryClick1="zona-padres"
            gtagActionClick2={
              userData
                ? `descarga-plan-de-estudios: ${userData.email}`
                : 'descarga-plan-de-estudios'
            }
            gtagCategoryClick2="zona-padres"
          >
            <Paragraph size="16" hasUnderline={true}>
              Descarga plan de estudios de ruta
            </Paragraph>
          </Link>
        </div>
      )}
      {hasEnded && (
        <div
          className="ui-container-tab-line__container-icon-close"
          onClick={() => setIsClose(!isClose)}
        >
          <IconArrow
            className={`ui-container-tab-line__${isClose ? 'icon-close' : 'icon-open'}`}
          />
        </div>
      )}
    </div>
  );
};

export default SectionPathCarousel;
