import { bool, node, string } from 'prop-types';
import Link from 'next/link';
import cx from 'classnames';

import { eventGtag } from '#app/hooks/eventGtag';
import { useUserContext } from '#hooks/index';
import gtagEventsLink from '#helpers/links-case-gtag-events';
import { event } from '#helpers/gtag';

const LocalLink = ({
  href,
  children,
  external = false,
  obfuscate = false,
  variant,
  isBlocked,
  target,
  rel,
  bold,
  gtagAction,
  gtagCategory,
  gtagActionClick1,
  gtagCategoryClick1,
  gtagActionClick2,
  gtagCategoryClick2,
  isFullWidth = true,
  eventName
}) => {
  const { data: userData } = useUserContext();

  const classNames = cx(
    `link${bold ? ' bold' : ''}`,
    { [`link--${variant}`]: variant },
    `${isBlocked ? 'link--is-blocked' : ''}`
  );

  // const isBrowser = () => typeof window !== 'undefined';
  const openInNewTab = url => {
    if (typeof window !== 'undefined') {
      window.open(url, target, rel);
    }
  };

  const handleOnClick = () => {
    eventGtag(eventName, userData);
    gtagEventsLink(gtagAction, gtagCategory);
    if (gtagActionClick1) {
      event(gtagActionClick1, gtagCategoryClick1);
    }
    if (gtagActionClick2) {
      event(gtagActionClick2, gtagCategoryClick2);
    }
  };

  if (obfuscate) {
    return (
      <a
        className={classNames}
        onClick={() => {
          handleOnClick();
          openInNewTab(href);
        }}
        style={{ width: isFullWidth ? '100%' : 'auto' }}
      >
        {children}
      </a>
    );
  }

  if (external) {
    return (
      <a
        className={classNames}
        href={href}
        onClick={handleOnClick}
        target="_blank"
        rel="noreferrer"
        style={{ width: isFullWidth ? '100%' : 'auto' }}
      >
        {children}
      </a>
    );
  }
  return (
    <Link href={href}>
      <a
        className={classNames}
        target={target}
        rel={rel}
        onClick={() => handleOnClick(href)}
        style={{ width: isFullWidth ? '100%' : 'auto' }}
      >
        {children}
      </a>
    </Link>
  );
};

LocalLink.propTypes = {
  href: string.isRequired,
  children: node.isRequired,
  external: bool,
  variant: string,
  target: string,
  rel: string,
  isBlocked: bool
};

export default LocalLink;
