import React from 'react';

import { renameCoursePerYear } from '#helpers/path-learning';

import Paragraph from './typography/paragraph';

const TabWithLine = ({
 name, image, isActive, onClick 
}) => {
  return (
    <div className="ui-tab-with-line" onClick={onClick}>
      <div
        className={`ui-tab-with-line__center ${
          isActive
            ? 'ui-tab-with-line__center--active'
            : 'ui-tab-with-line__center--inactive'
        }`}
      >
        <Paragraph size="16" weight={700}>
          {renameCoursePerYear(name)}
        </Paragraph>
      </div>
    </div>
  );
};

export default TabWithLine;
