const IconFrequentQuestions = ({ fill = '#000000', className }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="10" fill={fill} />
    <path
      d="M9.87241 12.807C9.61723 12.807 9.40202 12.7159 9.2268 12.5336C9.05157 12.3514 8.97438 12.137 8.99522 11.8904C9.02711 11.4401 9.13344 11.0487 9.3142 10.7164C9.49495 10.384 9.78203 10.0409 10.1754 9.68715C10.6114 9.30118 10.9438 8.96604 11.1726 8.68171C11.4014 8.39738 11.5156 8.07317 11.5152 7.70908C11.5152 7.26951 11.3691 6.90498 11.0769 6.61551C10.7847 6.32604 10.3779 6.1813 9.85646 6.1813C9.51622 6.1813 9.22637 6.25378 8.98692 6.39873C8.74747 6.54368 8.55353 6.72315 8.4051 6.93715C8.25624 7.15157 8.07294 7.29631 7.85518 7.37136C7.63742 7.44641 7.42732 7.44105 7.22487 7.35528C6.98032 7.24806 6.81296 7.07116 6.7228 6.82457C6.63263 6.57799 6.65113 6.34748 6.7783 6.13305C7.08665 5.6506 7.50132 5.24855 8.02232 4.92691C8.54333 4.60528 9.15471 4.44446 9.85646 4.44446C10.9729 4.44446 11.8316 4.75795 12.4326 5.38493C13.0335 6.0119 13.3338 6.76518 13.3334 7.64475C13.3334 8.18081 13.2192 8.63925 12.9908 9.02007C12.7624 9.40089 12.4034 9.83231 11.9139 10.3143C11.5524 10.6574 11.2972 10.9362 11.1483 11.1506C10.9995 11.365 10.8985 11.6116 10.8453 11.8904C10.8028 12.1584 10.6911 12.3782 10.5104 12.5497C10.3296 12.7213 10.117 12.807 9.87241 12.807ZM9.85646 16.6667C9.50558 16.6667 9.2051 16.5406 8.95502 16.2884C8.70494 16.0363 8.58012 15.7335 8.58054 15.3801C8.58054 15.0263 8.70558 14.7233 8.95566 14.4712C9.20574 14.219 9.50601 14.0932 9.85646 14.0936C10.2073 14.0936 10.5078 14.2197 10.7579 14.4718C11.008 14.724 11.1328 15.0268 11.1324 15.3801C11.1324 15.7339 11.0073 16.0369 10.7573 16.2891C10.5072 16.5412 10.2069 16.6671 9.85646 16.6667Z"
      fill="white"
    />
  </svg>
);

export default IconFrequentQuestions;
