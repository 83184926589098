import dayjs from 'dayjs';
import 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export const parseDateRange = (initialDate, endDate) =>
  `${dayjs(initialDate).locale('es').format('MMM DD')} - ${dayjs(endDate)
    .locale('es')
    .format('MMM DD')}`;

export const parseDate = date => `${dayjs(date).locale('es').format('MMM DD, YYYY')}`;

//* entry_data: 2021-11-19  / output_data: 19 de Nov.
export const parsePathDateMonth = date => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  return `${dayjs(date).tz(dayjs.tz.guess()).locale('es').format('DD')} de ${dayjs(date)
    .tz(dayjs.tz.guess())
    .locale('es')
    .format('MMMM')
    .substr(0, 3)}.`;
};

//* entry_data: 2021-11-19  / output_data: 19 de Noviembre
export const parsePathDate = date => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  return `${dayjs(date).tz(dayjs.tz.guess()).locale('es').format('DD')} de ${dayjs(date)
    .tz(dayjs.tz.guess())
    .locale('es')
    .format('MMMM')}`;
};

//* entry_data: 2021-11-19  / output_data: 19
export const parsePathDateToDay = date => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  return dayjs(date).tz(dayjs.tz.guess()).locale('es').format('DD');
};

//* entry_data: 2021-11-19  / output_data: Noviembre
export const parsePathDateToMonth = date => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  return dayjs(date).tz(dayjs.tz.guess()).locale('es').format('MMMM');
};

export const getDayString = date => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  return `${dayjs(date).tz(dayjs.tz.guess()).locale('es').format('dddd')}`;
};

//* entry_data: 2022-08-26T14:00:00.000Z / output_data: 10:30 AM
export const getTime = time => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  return `${dayjs(time).tz(dayjs.tz.guess()).format('hh:mm A')}`;
};

//* entry_data: 2022-08-26T14:00:00.000Z / output_data: 15:00
export const getTimeNumber = time => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  return `${dayjs(time).tz(dayjs.tz.guess()).format('HH:mm')}`;
};

//* entry_data: 2022-01-01 / output_data: sábado 01 de Enero
export const parseCourseDate = date =>
  `${dayjs(date).locale('es').format('dddd DD')} de ${dayjs(date)
    .locale('es')
    .format('MMMM')}`;

//* entry_data: 2022-01-01 / output_data: 01 de Enero 2022
export const parseCourseDateAlternative = date =>
  `${dayjs(date).locale('es').format('DD')} de ${dayjs(date)
    .locale('es')
    .format('MMMM')} ${dayjs(date).locale('es').format('YYYY')}`;

export const parseDatePurchase = date =>
  `${dayjs(date).locale('es').format('DD/MM/YYYY')}`;
export const parseTimePurchase = date => `${dayjs(date).locale('es').format('h:mm A')}`;

export const parsePurchaseDescriptionDate = date => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  return `${dayjs(date).tz(dayjs.tz.guess()).locale('es').format('dddd DD MMMM')}`;
};

export const ddmmaaaaParse = date => {
  const splitDate = date.split('/');
  const newDate = new Date(+splitDate[2], splitDate[1] - 1, +splitDate[0]);
  return newDate;
};

export const parseGeneralDate = date => {
  const dateConvert = ddmmaaaaParse(date);
  return dayjs(dateConvert).locale('es').format('YYYY-MM-DD');
};

export const parseGeneralDateDashboard = date =>
  `${dayjs(date).locale('es').format('YYYY-MM-DD')}`;

export const parseHoraryRange = (initialHour, endHour) =>
  initialHour
    ? `${dayjs(initialHour).locale('es').format('ddd h:mm a')} a ${dayjs(endHour).format(
        'h:mm a'
      )}`
    : 'No hay horarios disponibles';

export const parseHorary = horaryItem =>
  horaryItem
    ? `${dayjs(horaryItem.initialHour).locale('es').format('ddd h:mm a')} a ${dayjs(
        horaryItem.endHour
      ).format('h:mm a')}`
    : 'No hay horarios disponibles';

export const parseDay = date => `${dayjs(date).locale('es').format('dddd')}`;

export const parseDayString = dayString => {
  switch (dayString.toLowerCase()) {
    case 'sunday':
      return 'Domingo';
    case 'monday':
      return 'Lunes';
    case 'tuesday':
      return 'Martes';
    case 'wednesday':
      return 'Miércoles';
    case 'thursday':
      return 'Jueves';
    case 'friday':
      return 'Viernes';
    case 'saturday':
      return 'Sábados';
    default:
      return null;
  }
};

export const renderDay = day => {
  switch (day?.toLowerCase()) {
    case 'monday':
      return 'Lunes';
    case 'tuesday':
      return 'Martes';
    case 'wednesday':
      return 'Miércoles';
    case 'thursday':
      return 'Jueves';
    case 'friday':
      return 'Viernes';
    case 'saturday':
      return 'Sábados';
    case 'sunday':
      return 'Domingo';
    default:
      return;
  }
};

//* entry_data: array of objects / output_data: Lunes, Martes y Miercoles
export const renderArrayDaySchedule = arrayDays => {
  const daysArray = arrayDays ? arrayDays.map(day => parseDayString(day.day_name)) : [];
  const uniqueDays = [...new Set(daysArray)];
  // const multipleDays = uniqueDays.length > 1 ? uniqueDays.join(', ') : uniqueDays[0];

  if (uniqueDays.length === 1) {
    return uniqueDays[0];
  } else if (uniqueDays.length === 2) {
    return `${uniqueDays[0]} y ${uniqueDays[1]}`;
  } else if (uniqueDays.length === 3) {
    return `${uniqueDays[0]}, ${uniqueDays[1]} y ${uniqueDays[2]}`;
  } else if (uniqueDays.length === 4) {
    return `${uniqueDays[0]}, ${uniqueDays[1]}, ${uniqueDays[2]} y ${uniqueDays[3]}`;
  } else if (uniqueDays.length === 5) {
    return `${uniqueDays[0]}, ${uniqueDays[1]}, ${uniqueDays[2]}, ${uniqueDays[3]} y ${uniqueDays[4]}`;
  } else if (uniqueDays.length === 6) {
    return `${uniqueDays[0]}, ${uniqueDays[1]}, ${uniqueDays[2]}, ${uniqueDays[3]}, ${uniqueDays[4]} y ${uniqueDays[5]}`;
  } else if (uniqueDays.length === 7) {
    return `${uniqueDays[0]}, ${uniqueDays[1]}, ${uniqueDays[2]}, ${uniqueDays[3]}, ${uniqueDays[4]}, ${uniqueDays[5]} y ${uniqueDays[6]}`;
  } else {
    return '';
  }
};

//* entry_data: array of days / output_data: SU,MO,TU,WE,TH,FR,SA
export const renderRecurringDaysCalendar = arrayDays => {
  let daysWeek = [];
  const fullArray = arrayDays ? arrayDays.map(day => day.day_name) : [];
  if (fullArray.length > 0) {
    fullArray.forEach(day => {
      if (day.toLowerCase() === 'sunday') {
        daysWeek.push(day);
      } else if (day.toLowerCase() === 'monday') {
        daysWeek.push(day);
      } else if (day.toLowerCase() === 'tuesday') {
        daysWeek.push(day);
      } else if (day.toLowerCase() === 'wednesday') {
        daysWeek.push(day);
      } else if (day.toLowerCase() === 'thursday') {
        daysWeek.push(day);
      } else if (day.toLowerCase() === 'friday') {
        daysWeek.push(day);
      } else if (day.toLowerCase() === 'saturday') {
        daysWeek.push(day);
      } else null;
    });
  }

  const daysArray = daysWeek ? daysWeek.map(day => day.substr(0, 2)) : [];
  const bydays = daysArray.join().toUpperCase();

  return bydays;
};

export const getTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const parseTimeHubspot = date =>
  `${dayjs(date).locale('es').format('HH')}.${dayjs(date).locale('es').format('mm')}`;

export const getCityOfTimezone = timezone => {
  const city = timezone ? timezone.split('/') : null;
  return city ? city[city.length - 1].replace('_', ' ') : '';
};
