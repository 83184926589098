/* eslint-disable react/prop-types */
import { array } from 'prop-types';

import { CardProgramVacation } from '#components/index';
import { H3 } from '#styleguide/index';

const SectionProgramsVacation = ({
  data,
  myRef,
  myRef1,
  myRef2,
  idButton,
  onClickRefContact
}) => {
  return (
    <div className="section-programs-vacation">
      <H3 className="section-programs-vacation__title section-programs-vacation__title--bold">
        ¡Elige el programa ideal según la edad tu hij@!
      </H3>
      <div className="section-programs-vacation__container-programs">
        {data.map((data, i) => (
          <CardProgramVacation
            key={i}
            title={data?.card_title}
            thumbnailCard={data?.thumbnailCard}
            headerImage={data?.thumbnail}
            subtitle={data?.card_subtitle}
            gradient={data?.gradient_color}
            mainColor={data?.main_color}
            secondaryColor={data?.secondary_color}
            ageRange={data?.age_range}
            supportColor={data?.support_color}
            typePrograms={data?.programs}
            tag={data?.tag}
            description={data?.description}
            idButton={idButton}
            myRef={myRef}
            myRef1={myRef1}
            myRef2={myRef2}
            id={i}
            isEqual={idButton === i ? true : false}
            onClickRefContact={onClickRefContact}
          />
        ))}
      </div>
    </div>
  );
};

SectionProgramsVacation.propTypes = { data: array };

export default SectionProgramsVacation;
