import { event } from './gtag';
const gtagEventsLink = (gtagAction, gtagCategory) => {
  const userData = JSON.parse(localStorage.getItem('userData'));
  switch (gtagAction) {
    case 'access-class':
      event(
        'access class',
        'dashboard user',
        'Click en botón de acceder a la clase con el link de zoom',
        userData.id
      );
      return;

    case 'get-report':
      event(
        'get report',
        'dashboard user',
        'Click en botón de descargar el reporte del curso',
        userData.id
      );
      return;

    case 'get-more-inf':
      event(
        'get more inf',
        'dashboard user',
        'Click en botón de ir al detalle del curso',
        userData.id
      );
      return;

    default:
      return;
  }
};

export default gtagEventsLink;
