const WatchIcon = ({ fill }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.8794 10.9716C17.8794 10.477 17.4712 10.0804 16.9732 10.0804H12.318V5.58316V5.58302C12.318 5.08546 11.9023 4.68874 11.3988 4.68874C10.895 4.68874 10.4792 5.08543 10.4792 5.58302V10.9686C10.4792 11.4664 10.895 11.8631 11.3988 11.8631H16.9737C17.4713 11.8631 17.8794 11.4662 17.8794 10.9716ZM20.0149 10.9668C20.0149 15.569 16.1617 19.3065 11.4004 19.3065C6.63879 19.3065 2.7859 15.569 2.7859 10.9668C2.7859 6.36462 6.63879 2.62707 11.4004 2.62707C16.1617 2.62707 20.0149 6.36462 20.0149 10.9668ZM21.8518 10.9668C21.8518 5.37111 17.1687 0.841797 11.4004 0.841797C5.6318 0.841797 0.94873 5.37111 0.94873 10.9668C0.94873 16.5625 5.6318 21.0918 11.4004 21.0918C17.1687 21.0918 21.8518 16.5625 21.8518 10.9668Z"
      fill={fill ? fill : '#000000'}
      stroke="black"
      strokeWidth="0.25"
    />
  </svg>
);

export default WatchIcon;
