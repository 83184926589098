import { string } from 'prop-types';

import { Typography } from '#atoms/index';
const InformationBox = ({ type, message }) => {
  return (
    <div className={`information_box information_box--type_${type}`}>
      <Typography variant="body">{message}</Typography>
    </div>
  );
};

InformationBox.propTypes = {
  type: string,
  message: string
};

export default InformationBox;
