import { format, compareAsc } from 'date-fns';
import esLocale from 'date-fns/locale/es/index';

import countriesList from './countries-list';

export const parseAgeRangeOptions = agesRange =>
  agesRange.items.map(age => ({
    value: age.sys.id,
    label: age.fields.name
  }));

export const parseStartDateOptions = coursesList => {
  const coursesThatHaveInitialDate = coursesList.filter(
    course => course.fields.initialDate
  );
  const datesListSorted = coursesThatHaveInitialDate
    .reduce((newArray, course) => {
      const dateFormat = new Date(`${course.fields.initialDate}T00:00-05:00`);
      !newArray.find(
        date => format(date, 'yyyy-MM-dd') === format(dateFormat, 'yyyy-MM-dd')
      ) && newArray.push(dateFormat);
      return newArray;
    }, [])
    .sort(compareAsc);

  return datesListSorted.map(date => ({
    value: format(date, 'yyyy-MM-dd'),
    label: format(date, "d 'de' MMMM", { locale: esLocale })
  }));
};

export const daysOptions = () => {
  return Array.from(Array(31).keys()).map(index => ({
    value: `${index < 9 ? 0 : ''}${index + 1}`,
    label: `${index < 9 ? 0 : ''}${index + 1}`
  }));
};

export const monthsOptions = [
  { value: '01', label: 'Enero' },
  { value: '02', label: 'Febrero' },
  { value: '03', label: 'Marzo' },
  { value: '04', label: 'Abril' },
  { value: '05', label: 'Mayo' },
  { value: '06', label: 'Junio' },
  { value: '07', label: 'Julio' },
  { value: '08', label: 'Agosto' },
  { value: '09', label: 'Septiembre' },
  { value: '10', label: 'Octubre' },
  { value: '11', label: 'Noviembre' },
  { value: '12', label: 'Diciembre' }
];

export const yearsOptions = () => {
  let years = [];
  for (let index = 2000; index < 2020; index++) {
    years.push({ value: index, label: index });
  }
  return years;
};

export const userRolesOptions = [
  { value: 'mother', label: 'Madre' },
  { value: 'father', label: 'Padre' },
  { value: 'tutor', label: 'Tutor' }
];

export const countriesOptions = countriesList;

export const docTypeOptions = [
  { value: 'DNI', label: 'DNI' },
  { value: 'alien-certificate', label: 'Carnet de Extranjería' },
  { value: 'passport', label: 'Pasaporte' },
  { value: 'cedula', label: 'Cedula' },
  { value: 'ID', label: 'ID' }
];

export const awarenessOptions = [
  { value: 'facebook', label: 'Facebook' },
  { value: 'instagram', label: 'Instagram' },
  { value: 'mailing', label: 'Mailing' },
  { value: 'referral', label: 'Referido' },
  { value: 'school', label: 'Colegio' },
  { value: 'company', label: 'Empresa' },
  { value: 'web', label: 'Web' },
  { value: 'already_in_CTC', label: 'Ya he tomado clases en Crack The Code' }
];

export const pathsConverts = (pathName, gtagCategory) => {
  if (gtagCategory === 'google-landing-a') {
    switch (pathName) {
      case 'Little Coders':
        return 'click_path1';
      case 'Desarrollador de Videojuegos':
        return 'click_path2';
      case 'Diseño y Creatividad':
        return 'click_path3';
      case 'Super Programador':
        return 'click_path4';
      default:
        return;
    }
  }

  if (gtagCategory === 'google-landing-b') {
    switch (pathName) {
      case 'Little Coders':
        return 'click_path1_with_price';
      case 'Desarrollador de Videojuegos':
        return 'click_path2_with_price';
      case 'Diseño y Creatividad':
        return 'click_path3_with_price';
      case 'Super Programador':
        return 'click_path4_with_price';
      default:
        return;
    }
  }
};

export const reason_list = [
  {
    ico: 'reason1',
    content: ' Más de 4.000 estudiantes con la metodología “Think Based Learning”'
  },
  {
    ico: 'reason2',
    content: 'Más de 50 profesores expertos en tecnología y enseñanza pedagógica'
  },
  {
    ico: 'reason3',
    content: 'Clases interactivas, efectivas y divertidas'
  },
  {
    ico: 'reason4',
    content: 'Presencia regional en más de 15 países de Latinoamérica'
  }
];

export const jumbutronCarousel = [
  ['/path-learning/LT-1.png', '/path-learning/LT-2.png', '/path-learning/LT-3.png'],
  ['/path-learning/DDV-1.png', '/path-learning/DDV-2.png', '/path-learning/DDV-3.png'],
  ['/path-learning/CDD-1.png', '/path-learning/CDD-2.png', '/path-learning/CDD-3.png'],
  ['/path-learning/SP-1.png', '/path-learning/SP-2.png', '/path-learning/SP-3.png']
];

export const proficiency_list = [
  'Ser un creador de tecnología a través de proyectos usando programación en bloques.',
  'Manejar desafíos graduales desarrollando su autoconfianza, atención y concentración.',
  'Desarrollar su imaginación, creatividad y curiosidad mientras se divierte. '
];

export const course_path_fake = [
  {
    order: 1,
    title: 'Mini creador',
    duration: '2',
    totalClass: '8',
    frecuency: '1',
    description:
      'Alientalo a crear su primer videojuego mientras aprende programación básica en bloques. Conocerá conceptos básicos de codificación, diseñará y programará su videojuego en CodeSpark. ',
    skills: [
      'Trabajo colaborativo.',
      'Uso y control de la tecnología.',
      'Pensamiento lógico computacional.'
    ]
  },
  {
    order: 2,
    title: 'Animación Digital',
    duration: '2',
    totalClass: '8',
    frecuency: '1',
    description:
      'Alientalo a crear su primer videojuego mientras aprende programación básica en bloques. Conocerá conceptos básicos de codificación, diseñará y programará su videojuego en CodeSpark. ',
    skills: [
      'Trabajo colaborativo.',
      'Uso y control de la tecnología.',
      'Pensamiento lógico computacional.'
    ]
  },
  {
    order: 3,
    title: 'Story Maker',
    duration: '2',
    totalClass: '8',
    frecuency: '1',
    description:
      'Alientalo a crear su primer videojuego mientras aprende programación básica en bloques. Conocerá conceptos básicos de codificación, diseñará y programará su videojuego en CodeSpark. ',
    skills: [
      'Trabajo colaborativo.',
      'Uso y control de la tecnología.',
      'Pensamiento lógico computacional.'
    ]
  },
  {
    order: 4,
    title: 'Arte Digital',
    duration: '2',
    totalClass: '8',
    frecuency: '1',
    description:
      'Alientalo a crear su primer videojuego mientras aprende programación básica en bloques. Conocerá conceptos básicos de codificación, diseñará y programará su videojuego en CodeSpark. ',
    skills: [
      'Trabajo colaborativo.',
      'Uso y control de la tecnología.',
      'Pensamiento lógico computacional.'
    ]
  },
  {
    order: 5,
    title: 'Creador Tech',
    duration: '2',
    totalClass: '8',
    frecuency: '1',
    description:
      'Alientalo a crear su primer videojuego mientras aprende programación básica en bloques. Conocerá conceptos básicos de codificación, diseñará y programará su videojuego en CodeSpark. ',
    skills: [
      'Trabajo colaborativo.',
      'Uso y control de la tecnología.',
      'Pensamiento lógico computacional.'
    ]
  }
];

export const purchase_description_fake = {
  title: 'Desarrollador  de Videojuegos',
  age_range: '5 a 7 años',
  date: 'Martes 2 de abril.',
  schedule: 'Cada martes de 8:30pm a 9:45pm.',
  duration: '8 meses.',
  information: '5 cursos dentro de la ruta.'
};

export const aboutCoursePurchase = {
  'KIT BÁSICO': 'Kit básico',
  'KIT AVANZADO': 'Kit avanzado',
  'MÓDULO 01': 'Módulo 01',
  'TALLER COMPLETO': 'Curso completo'
};

export const faqs_courses = {
  cursos_libres: [
    {
      question: '¿Qué es un Curso Libre?',
      answer: (
        <>
          En Crack The Code, tenemos dos tipos de cursos, los cursos de las rutas y los
          cursos libres. Los cursos libres son talleres con una duración determinada que
          permiten aprender una temática específica y que usualmente son de corta
          duración. Además, los cursos libres no tienen un requisito de conocimiento
          previo.
        </>
      )
    },
    {
      question: '¿Cómo son las clases de Crack The Code?',
      answer: (
        <>
          Aprendemos del mundo de la programación y tecnología con nuestras clases en
          línea que son impartidas de forma síncrona, es decir, los estudiantes y
          profesores se conectan por medio de la plataforma Zoom.
          <br />
          <br />
          Nuestros grupos son de máximo 6 estudiantes con edades e intereses similares. Y
          contamos con profesores expertos en tecnología con formación pedagógica.
          <br />
          <br />
          Los estudiantes aplican todo lo aprendido es sus proyectos finales. Entre estos
          pueden hacer videojuegos, páginas web, proyectos de inteligencia artificial y
          muchos más.
        </>
      )
    },
    {
      question: '¿De qué trata nuestra metodología Thinking Based Learning?',
      answer: (
        <>
          Con esta metodología buscamos que los estudiantes sean protagonistas de su
          propio aprendizaje y puedan encontrar posibles soluciones haciendo uso de su
          capacidad crítica y creativa.
          <br />
          <br />
          Promovemos una evaluación formativa, en donde módulo a módulo ayudamos a las
          niñas y niños a potenciar sus habilidades tecnológicas.
        </>
      )
    },
    {
      question:
        'Si llevo un curso de las rutas de aprendizaje, ¿Puedo llevar un curso libre?',
      answer: (
        <>
          ¡Por supuesto! Los cursos libres están diseñados para que tus hij@s aprendan una
          temática en específico de su interés, pero además son una muy buena manera de
          conocer la metodología de Crack The Code y luego continuar con otros cursos de
          ruta que puedan llamar la atención de tu niñ@.
          <br />
          <br />
          Además, muchos de nuestros cursos libres tienen temáticas similares a las de
          nuestras rutas por lo que si a tu hij@ le gustó el curso podrás encontrar una
          ruta que se adecue a sus preferencias.
        </>
      )
    },
    {
      question: '¿Qué plataformas se usan en los cursos?',
      answer: (
        <>
          Cada uno de nuestros cursos libres utiliza una plataforma diferente con el
          objetivo de que nuestros talleres se dicten utilizando la mejor herramienta
          posible disponible en el mercado.
          <br />
          <br />
          Si quieres saber cuál es la herramienta que tu hij@ empleará, selecciona el
          curso y dirígete a la sección de preguntas frecuentes donde encontrarás una
          descripción de la plataforma a ser usada.
        </>
      )
    }
  ],
  cursos_ruta: [
    {
      question: '¿Qué es una ruta de aprendizaje?',
      answer: (
        <>
          Es un programa curricular estructurado que tiene como eje una temática
          principal. Al rededor de esta temática, dictamos cursos que permiten el
          aprendizaje progresivo de conceptos y habilidades en nuestros estudiantes.
          <br />
          <br />
          En Crack The Code tenemos 6 rutas de aprendizaje: Pequeños Programadores,
          Desarrollo de Videojuegos, Super Programadores, Programación Pro, Diseño Gráfico
          y Edición de Videos. Cada una de nuestras rutas está orientada a los intereses y
          a rangos de edades para cada estudiante.
        </>
      )
    },
    {
      question: '¿Por qué el primer curso se vende por separado?',
      answer: (
        <>
          Vendemos el primer curso de forma separada porque creemos que puede ser difícil
          inscribir a los estudiantes en una ruta con una duración de varios meses sin
          saber si la temática y metodología del taller les va a gustar a los niños. Por
          eso, consideramos que es una buena idea que tu hij@ pruebe nuestras clases y
          luego pueda decidir seguir toda la ruta con nosotros.
        </>
      )
    },
    {
      question: '¿Cómo son las clases de Crack The Code?',
      answer: (
        <>
          Aprendemos del mundo de la programación y tecnología con nuestras clases en
          línea que son impartidas de manera síncrona, es decir, los estudiantes y
          profesores se conectan por medio de la plataforma Zoom.
          <br />
          <br />
          Nuestros grupos son de máximo 6 estudiantes con edades e intereses similares. Y
          contamos con profesores expertos en tecnología con formación pedagógica.
          <br />
          <br />
          Los estudiantes aplican todo lo aprendido es sus proyectos finales. Entre estos
          pueden hacer videojuegos, páginas web, proyectos de inteligencia artificial y
          muchos más.
        </>
      )
    },
    {
      question: '¿De qué trata nuestra metodología Thinking Based Learning?',
      answer: (
        <>
          Con esta metodología buscamos que los estudiantes sean protagonistas de su
          propio aprendizaje y puedan encontrar posibles soluciones haciendo uso de su
          capacidad crítica y creativa.
          <br />
          <br />
          Promovemos una evaluación formativa, en donde módulo a módulo ayudamos a las
          niñas y niños a potenciar sus habilidades tecnológicas.
        </>
      )
    },
    {
      question: '¿Qué plataformas se utilizan en los cursos?',
      answer: (
        <>
          Cada uno de nuestros cursos libres emplea una plataforma diferente con el
          objetivo de que nuestros talleres se dicten utilizando la mejor herramienta
          posible disponible en el mercado.
          <br />
          <br />
          Si quieres saber cuál es la herramienta que tu hij@ empleará, selecciona el
          curso y dirígete a la sección de preguntas frecuentes donde encontrarás una
          descripción de la plataforma a ser empleada.
        </>
      )
    }
  ]
};

export const faqs_paths = {
  rutas: [
    {
      question: '¿Por qué elegirnos como academia de programación para tus hij@s?',
      answer: (
        <>
          En Crack The Code contamos con la experiencia de haber formado en tecnología a
          más de 8.500 niños y adolescentes de distintas partes de Latinoamérica. Nuestra
          academia de programación difunde, de manera divertida y didáctica, los
          aprendizajes con los que los pequeños pueden empezar a crear cosas increíbles
          mediante la tecnología.
        </>
      )
    },
    {
      question: '¿Por qué es importante para los niños aprender a programar?',
      answer: (
        <>
          La programación es el equivalente a lo que representaba el inglés décadas atrás:
          un lenguaje que abre muchísimas oportunidades para los profesionales de hoy y
          que cuenta con un potencial aún mayor para el futuro. Al saber programar, tu
          hij@ obtendrá una gran ventaja competitiva para cuando le toque ser grande e
          incursionar en el mundo laboral.
        </>
      )
    },
    {
      question: '¿Qué aprenderán tus niñ@s en nuestra academia de programación online?',
      answer: (
        <>
          En Crack The Code podrán aprender habilidades tech como programación, desarrollo
          de videojuegos, diseño gráfico, edición de videos, entre otras con las que
          podrán dar sus primeros pasos en la industria tecnológica.
        </>
      )
    },
    {
      question: '¿Qué metodología de aprendizaje aplicamos en nuestra academia online?',
      answer: (
        <>
          En Crack The Code aplicamos la metodología Thinking Based Learning, con la que
          buscamos que nuestros estudiantes sean protagonistas de su propio aprendizaje y
          encuentren soluciones a retos usando su capacidad crítica y creativa. Módulo a
          módulo, ayudamos a niñas y niños a potenciar sus habilidades tecnológicas.
        </>
      )
    }
  ],
  gameJam: [
    {
      question: '¿Qué es un Game Jam?',
      answer: (
        <>
          Es un concurso virtual en el que los participantes crean un videojuego. Los
          ganadores serán los que creen los mejores videojuegos a nivel técnico, creativo
          y funcional. Estos aspectos deben quedar aterrizados en la temática central del
          evento que, en el caso del Game Jam de Crack The Code de este año, es la lucha y
          prevención contra el bullying.
        </>
      )
    },
    {
      question: '¿Qué necesita mi hij@ para participar en el Game Jam de Crack The Code?',
      answer: (
        <>
          El participante debe tener entre 9 y 16 años de edad, saber usar la plataforma
          Game Maker Studio, y contar con una computadora en la que utilice el programa.
        </>
      )
    },
    {
      question: '¿Participar en el Game Jam tiene algún costo?',
      answer: (
        <>
          NO. La inscripción al concurso es gratuita. Sin embargo, los participantes que
          quieran aprender a crear su videojuego desde cero, o a reforzar sus
          conocimientos previos sobre el tema, pueden inscribirse al taller práctico de
          Desarrollo de Videojuegos que Crack The Code ofrecerá especialmente para el Game
          Jam. Con este taller, los participantes podrán llegar 100 % preparados al
          concurso.
        </>
      )
    },
    {
      question: '¿Cuáles son los premios del Game Jam?',
      answer: (
        <>
          El Game Jam 2022 de Crack The Code premia a los tres primeros lugares de la
          categoría Juniors (9 a 12 años) y a los de la categoría Teens (13 a 16 años). A
          estos se les entregará, respectivamente, una laptop Lenovo, una tablet Lenovo, y
          una beca completa para un curso de Crack The Code.
        </>
      )
    },
    ,
    {
      question: '¿Cómo son las clases del taller práctico de Desarrollo de Videojuegos?',
      answer: (
        <>
          Nuestras clases parten de principios básicos para que tu hij@ pueda entender
          desde cero la lógica de los videojuegos. Nos apoyamos en distintas plataformas
          para que nuestros estudiantes comiencen a programar en bloques y, luego, a
          escribir con código. Bajo esas habilidades construirán sus personajes, enemigos,
          mapas, obstáculos, metas y otros elementos de su juego.
        </>
      )
    }
  ]
};
