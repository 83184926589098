import React from 'react';

import { useUserContext } from '#hooks/index';
import { WhatsAppLogo } from '#layout/helpers';
import { TabWithLine, CardFreeCourseProgress, Paragraph } from '#styleguide/index';

import SectionPathCarousel from './sectionPathCarousel';

const ContainerTabLine = ({
  tabs,
  pathCourses,
  currentCourse,
  setCurrentCourse,
  currentTab,
  setCurrentTab,
  freeCourses,
  childrenData,
  setIsCourse,
  isCourse,
  setIdRecomendation,
  setObjectCalendar,
  setCalendarRecurrence,
  setCalendarSplitRecurrence,
  setCalendarOptions
}) => {
  const { data: userData } = useUserContext();

  const selectTab = (idx, iscourse, tab) => () => {
    setCurrentTab(idx);
    setCurrentCourse(0);
    setIsCourse(iscourse);
    if (iscourse) {
      setIdRecomendation(tab[0]?.course.id);
    } else {
      setIdRecomendation(tab?.learning_path_details[0]?.id);
    }
  };

  const freeCoursesNoEnded = freeCourses
    .filter(course => course.status !== 'has_ended')
    .map((courseTab, idx) => (
      <CardFreeCourseProgress
        key={idx}
        data={courseTab}
        child={childrenData}
        type="free"
        colorsCourse={{
          main_color: '#8C30F2',
          secondary_color: '#F3F1F3'
        }}
        isCourse={isCourse}
        setObjectCalendar={setObjectCalendar}
        hasEndedCI={false}
      />
    ));

  const freeCoursesEnded = freeCourses
    .filter(course => course.status === 'has_ended')
    .map((courseTab, idx) => (
      <CardFreeCourseProgress
        key={idx}
        data={courseTab}
        child={childrenData}
        type="free"
        colorsCourse={{
          main_color: '#8C30F2',
          secondary_color: '#F6F6F6'
        }}
        hasEndedCI={true}
      />
    ));

  return (
    <div className="ui-container-tab-line">
      <div className="ui-container-tab-line__container-items-tab">
        <div className="ui-container-tab-line__items-tab">
          {pathCourses.length > 0 && (
            <TabWithLine
              name="Rutas de Aprendizaje"
              isActive={currentTab === 0}
              onClick={selectTab(0, false, pathCourses[0])}
              setObjectCalendar={setObjectCalendar}
            />
          )}
          {freeCourses.length > 0 && pathCourses.length > 0 && (
            <TabWithLine
              name="Cursos Individuales"
              isActive={currentTab === 1}
              onClick={selectTab(1, true, freeCourses)}
              setObjectCalendar={setObjectCalendar}
            />
          )}
          {freeCourses.length > 0 && pathCourses.length < 1 && (
            <TabWithLine
              name="Cursos Individuales"
              isActive={currentTab === 0}
              onClick={selectTab(0, true, freeCourses)}
              setObjectCalendar={setObjectCalendar}
            />
          )}
        </div>
      </div>
      {currentTab === 0 && pathCourses.length > 0 ? (
        <section className="ui-container-tab-line__section-paths-details">
          {pathCourses
            .filter(path => path.courses[path.courses.length - 1].status !== 'has_ended')
            .map((path, i) => (
              <SectionPathCarousel
                setCalendarSplitRecurrence={setCalendarSplitRecurrence}
                setCalendarRecurrence={setCalendarRecurrence}
                setCalendarOptions={setCalendarOptions}
                key={i}
                tab={path}
                currentTab={currentTab}
                currentCourseProp={currentCourse}
                setCurrentCourseProp={setCurrentCourse}
                childrenData={childrenData}
                setObjectCalendar={setObjectCalendar}
                coursesTab={path.courses.concat(path.courses[path.courses.length - 1])}
                userData={userData}
                hasEnded={false}
              />
            ))}
          {pathCourses
            .filter(path => path.courses[path.courses.length - 1].status === 'has_ended')
            .map(path => (
              <>
                <Paragraph size="21" weight="700">
                  Rutas anteriores:
                </Paragraph>
                <SectionPathCarousel
                  setCalendarSplitRecurrence={setCalendarSplitRecurrence}
                  setCalendarRecurrence={setCalendarRecurrence}
                  setCalendarOptions={setCalendarOptions}
                  tab={path}
                  currentTab={currentTab}
                  currentCourseProp={currentCourse}
                  setCurrentCourseProp={setCurrentCourse}
                  childrenData={childrenData}
                  setObjectCalendar={setObjectCalendar}
                  coursesTab={path.courses.concat(path.courses[path.courses.length - 1])}
                  userData={userData}
                  hasEnded={true}
                />
              </>
            ))}
        </section>
      ) : (
        <div
          className="ui-container-tab-line__item-content"
          style={{
            width: '100%',
            minHeight: '100px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            padding: '27px 24px 45px 24px'
          }}
        >
          {freeCoursesNoEnded}
          {freeCoursesEnded.length > 0 && (
            <Paragraph size="21" weight="700">
              Cursos anteriores:
            </Paragraph>
          )}
          {freeCoursesEnded}
        </div>
      )}
      <WhatsAppLogo fixLanding />
    </div>
  );
};

export default ContainerTabLine;
