/* eslint-disable react/prop-types */
import dynamic from 'next/dynamic';

import { Link } from '#atoms/index';
import { useUserGeolocalization } from '#hooks/index';

import { opcBuyBtn } from '../../helpers/formsUtils';

// Dynamic Components
const DynamicFeaturedVideo = dynamic(() => import('../../components/featured-video'));

const ThankPage = ({
 title, description, typePath, variant, whatsappMsg 
}) => {
  const { data: geolocalizationData } = useUserGeolocalization();

  const renderGeolocalization = () => {
    return geolocalizationData.countryCode;
  };

  const renderTestimonials = () => {
    return (
      <div className="thank-page-testimonials">
        <h1 className="thank-page-title">
          Escucha los testimonios de nuestros estudiantes
        </h1>
        <div className="testimonials-grid">
          <DynamicFeaturedVideo videoId="dhUX3tp1-H0" />
          <DynamicFeaturedVideo videoId="IsW9XqlY7UQ" />
          <DynamicFeaturedVideo videoId="dl-hTys5OiY" />
        </div>
      </div>
    );
  };

  return (
    <div className="thank-page-wrapper">
      <img
        className="thank-page-welcome-img"
        src="/Icono2.svg"
        alt="welcome | Crack The Code"
      />
      <h1 className="thank-page-title">{title}</h1>
      <p className="thank-page-content">{description}</p>
      {variant === 'purchase' && typePath && geolocalizationData && (
        <>
          <h2 className="thank-page-subtitle">Seleccione la opción de pago</h2>
          <div className="thank-page-payments-section">
            <Link
              variant="black-w-icon"
              href={opcBuyBtn(typePath, renderGeolocalization()).link[0]}
              gtagAction="first_course_pagolink"
            >
              {opcBuyBtn(typePath, renderGeolocalization()).label[0]}
            </Link>
            <Link
              variant="black-w-icon"
              href={opcBuyBtn(typePath, renderGeolocalization()).link[1]}
              gtagAction="full_pathway_pagolink"
            >
              {opcBuyBtn(typePath, renderGeolocalization()).label[1]}
            </Link>
          </div>
        </>
      )}

      <p className="thank-page-content">
        Si tienes alguna consulta adicional, no dudes en contactarnos a nuestro Whatsapp{' '}
        <a
          href={`https://wa.me/17867892469?text=${whatsappMsg}`}
          target="_blank"
          rel="noreferrer"
        >
          +1(786)789-2469
        </a>
      </p>

      {variant === 'testimonials' && renderTestimonials()}

      {variant !== 'purchase' && (
        <div className="button-wrapper">
          <Link variant="outline-w-icon" href="/">
            Volver a la pagina principal
          </Link>
        </div>
      )}
    </div>
  );
};

export default ThankPage;
