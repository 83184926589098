import { shape } from 'prop-types';
import { useRouter } from 'next/router';

import { Button, Hr, Typography } from '#atoms/index';
import { event } from '#helpers/gtag';

const SuccessMessage = ({
 transactionData, userData, courseData, productData 
}) => {
  const router = useRouter();
  const weirdDateFormat = String(transactionData.order.transactionDate);
  const dateParsed = `
    ${weirdDateFormat[4]}${weirdDateFormat[5]}/${weirdDateFormat[2]}${weirdDateFormat[3]}/${weirdDateFormat[0]}${weirdDateFormat[1]} 
    ${weirdDateFormat[6]}${weirdDateFormat[7]}:${weirdDateFormat[8]}${weirdDateFormat[9]}:${weirdDateFormat[10]}${weirdDateFormat[11]}
  `;

  const showMyPurchase = () => {
    event(
      'checkout_success',
      'purchase-flow',
      'Visualización de la Confirmación de Pago/Compra'
    ); //*gta event
    router.push('/dashboard');
  };

  return (
    <section className="niubiz-message">
      <Typography variant="h2">¡Pago aprobado y completado con éxito!</Typography>

      <div className="__inline-info">
        <Typography variant="body">Número de pedido:</Typography>
        <Typography variant="h3">{transactionData.order.purchaseNumber}</Typography>
      </div>
      <div className="__inline-info">
        <Typography variant="body">Nombres y Apellidos:</Typography>
        <Typography variant="h3">
          {userData.firstName} {userData.lastName}
        </Typography>
      </div>
      <div className="__inline-info">
        <Typography variant="body">Fecha y hora:</Typography>
        <Typography variant="h3">{dateParsed}</Typography>
      </div>
      <div className="__inline-info">
        <Typography variant="body">Importe:</Typography>
        <Typography variant="h3">{transactionData.order.amount}</Typography>
      </div>
      <div className="__inline-info">
        <Typography variant="body">Tipo de moneda:</Typography>
        <Typography variant="h3">{transactionData.order.currency}</Typography>
      </div>
      <div className="__inline-info">
        <Typography variant="body">Tarjeta:</Typography>
        <Typography variant="h3">
          {transactionData.dataMap.CARD} ({transactionData.dataMap.BRAND})
        </Typography>
      </div>
      <div>
        <Typography variant="body">Descripción de la compra:</Typography>
        <Typography variant="h3">{courseData.fields.name}</Typography>
        <Typography variant="h4">{productData.product.fields.concept}</Typography>
      </div>
      <Hr />
      <Button onClick={showMyPurchase} color="sky">
        Ver Mi Compra
      </Button>
    </section>
  );
};

SuccessMessage.propTypes = {
  transactionData: shape(),
  userData: shape(),
  courseData: shape(),
  productData: shape()
};

export default SuccessMessage;
