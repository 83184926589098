const SectionContactAdvisor = ({
 regularText, boldText, br = false, button, image 
}) => {
  return (
    <div className="contact-advisor">
      <div className="contact-advisor__first-content">
        <img
          width="424px"
          src={image}
          alt="Imagen contacta a un asesor | Crack The Code"
        />
      </div>
      <div className="contact-advisor__second-content">
        <h1 className="contact-advisor__title">
          {regularText} {br && <br className="contact-advisor__br" />}
          <span className="contact-advisor__title about-vacation__title--bold">
            {boldText}
          </span>
        </h1>
        {button}
      </div>
    </div>
  );
};

export default SectionContactAdvisor;
