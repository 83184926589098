import { object, bool } from 'prop-types';

import { Link } from '#atoms/index';
import { useUserContext, useWindowSize } from '#hooks/index';
import { event } from '#helpers/gtag';

const BannerDashboard = ({ banner, isExternal }) => {
  const { data: userData } = useUserContext();
  const { width } = useWindowSize();

  const eventsAnalytics = () => {
    event(
      'click-banner-informativo',
      'zona-padres',
      'clic en banner informativo en zona de padres'
    );
    event(
      `click-banner-informativo: ${userData.email}`,
      'zona-padres',
      'clic en banner informativo en zona de padres'
    );
  };

  return (
    <div className="banner_dasboard" onClick={eventsAnalytics}>
      <Link href={banner.url} external={isExternal}>
        <img src={width >= 768 ? banner.cover : banner.cover_mobile} alt="banner" />
      </Link>
    </div>
  );
};

BannerDashboard.propTypes = {
  banner: object,
  isExternal: bool
};

export default BannerDashboard;
