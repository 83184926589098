const PeopleClassIcon = ({ fill }) => (
  <svg
    width="29"
    height="23"
    viewBox="0 0 29 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4542 21.2002V19.0669C19.4542 17.9353 18.9945 16.8501 18.1762 16.0499C17.3579 15.2498 16.248 14.8003 15.0907 14.8003H6.36356C5.20627 14.8003 4.09638 15.2498 3.27806 16.0499C2.45973 16.8501 2 17.9353 2 19.0669V21.2002"
      stroke={fill ? fill : '#000000'}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeWidth="round"
    />
    <path
      d="M10.7278 10.5332C13.1377 10.5332 15.0914 8.62297 15.0914 6.26659C15.0914 3.91022 13.1377 2 10.7278 2C8.31789 2 6.36426 3.91022 6.36426 6.26659C6.36426 8.62297 8.31789 10.5332 10.7278 10.5332Z"
      stroke={fill ? fill : '#000000'}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeWidth="round"
    />
    <path
      d="M26.0002 21.197V19.0637C25.9995 18.1184 25.6777 17.2 25.0854 16.4529C24.493 15.7058 23.6637 15.1721 22.7275 14.9358"
      stroke={fill ? fill : '#000000'}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeWidth="round"
    />
    <path
      d="M18.3633 2.14062C19.3019 2.37561 20.1338 2.90936 20.7279 3.65773C21.322 4.4061 21.6445 5.32652 21.6445 6.27389C21.6445 7.22125 21.322 8.14167 20.7279 8.89004C20.1338 9.63841 19.3019 10.1722 18.3633 10.4071"
      stroke={fill ? fill : '#000000'}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeWidth="round"
    />
  </svg>
);

export default PeopleClassIcon;
