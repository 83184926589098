import axios from 'axios';

import { client } from './client';
import { baseUrl } from '../baseUrl';

//*Contentfull paths

//? get summary data of the path
export async function fetchPaths() {
  const response = await client.getEntries({ content_type: 'rutaDeAprendizaje' });

  return response;
}

//? get data of the path
export async function fetchPathBySlug(slug) {
  const response = await client.getEntries({
    content_type: 'rutaDeAprendizaje',
    'fields.slug': slug,
    include: 3
  });

  return response.items.pop();
}

//*Backend paths

//? get summary data of the path
export async function getLearningPath() {
  const response = await fetch(`${baseUrl}/api/learning-paths/`);
  return await response.json();
}

//? get data of the path
/* export async function getLearningPathBySlug(slug) {
  const response = await fetch(`${baseUrl}/api/learning-paths/${slug}`);
  return await response.json();
} */

export const getLearningPathBySlug = async slug => {
  const data = await axios.get(`${baseUrl}/api/learning-paths-all/${slug}`);
  return data;
};

//*Backend courses

//? get summary data of the courses
export async function getCourses() {
  const response = await fetch(`${baseUrl}/api/courses-filtered/`);
  return response.json();
}

//? get data of the course
export async function getCourseBySlug(slug) {
  const response = await fetch(`${baseUrl}/api/courses/${slug}`);
  return response.json();
}

//? get data of the program
export async function getProgramBySlug(slug) {
  const response = await fetch(`${baseUrl}/api/campaigns/${slug}`);
  return response.json();
}

//? get data of the course (REDIRECT MOODLE)
export async function getInfoStudent(idStudent, token) {
  const response = await fetch(`${baseUrl}/api/students/moodle_redirect/${idStudent}`, {headers: { Authorization: `Bearer ${token}` }});
  return response.json();
}

//? get data by group (schedule)
export async function getProgramByGroup(data) {
  const response = await fetch(`${baseUrl}/api/group/prices/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  });
  return response.json();
}

export const getGroupHash = async data => {
  const response = await fetch(`${baseUrl}/api/resale/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}`
    },
    body: JSON.stringify(data)
  });
  return response.json();
};

//* Slider Home

//? get summary data of the courses
export async function getSiders() {
  const response = await fetch(`${baseUrl}/api/sliders/page/home/`);
  return response.json();
}

//? get recomendation courses
export async function getRecomendationCourses(idCourse) {
  const response = await fetch(
    `${baseUrl}/api/recommended/dashboard/course/${idCourse}/`
  );
  return response.json();
}

//? get recomendation path
export async function getRecomendationPath(idLearningPath) {
  const response = await fetch(
    `${baseUrl}/api/recommended/dashboard/learning_path/${idLearningPath}/`
  );
  return response.json();
}
