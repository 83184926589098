import React from 'react';

import {
 Paragraph, Chip, NumberCourse, ProgressBar 
} from '#styleguide/index';
import { parseCourseDateAlternative } from '#helpers/parsers';
import { parseDuration } from '#helpers/path-learning';

const HeaderCardCourseProgress = ({
  status,
  state,
  courseName,
  orderInListPath,
  progress,
  colorsCourse,
  startDate,
  duration,
  numberOfSessions,
  frequencyNumber,
  hasEnded,
  type = 'path'
}) => {
  return (
    <div className="ui-header-card-course-progress">
      {type === 'path' && <NumberCourse number={orderInListPath} />}
      <section className="ui-header-card-course-progress__header-principal">
        <div className="ui-header-card-course-progress__header-title-status">
          <Paragraph size="28" weight={700}>
            {courseName}
          </Paragraph>
          <Chip
            fill={
              state === 'abandoned'
                ? '#E5E5E5'
                : status === 'in_progress'
                ? '#0BC79B'
                : status === 'blocked'
                ? '#C3BFC5'
                : status === 'has_not_started'
                ? '#BCBCBC'
                : status === 'remaining'
                ? '#BCBCBC'
                : status === 'has_ended'
                ? '#E5E5E5'
                : '#FFFFFF'
            }
            color={
              state === 'abandoned'
                ? '#555555'
                : status === 'in_progress'
                ? '#FFFFFF'
                : status === 'blocked'
                ? '#7A7777'
                : status === 'has_not_started'
                ? '#FFFFFF'
                : status === 'remaining'
                ? '#FFFFFF'
                : status === 'has_ended'
                ? '#9C56E0'
                : '#FFFFFF'
            }
          >
            {state === 'abandoned'
              ? 'No completado'
              : status === 'has_ended'
              ? 'Completado'
              : status === 'in_progress'
              ? 'En progreso'
              : status === 'blocked'
              ? 'Proximamente'
              : status === 'has_not_started'
              ? 'Programado'
              : status === 'remaining'
              ? 'Por definir'
              : '--'}
          </Chip>
        </div>
        <ProgressBar
          progress={progress}
          color={hasEnded ? '#BCBCBC' : colorsCourse?.main_color}
          status={status}
          isEmpty={status === 'has_not_started'}
          isFreeCourse={type === 'free'}
        />
        <div className="ui-header-card-course-progress__header-start-date">
          <Paragraph size="14" weight={400}>
            {parseDuration(duration) === 1
              ? `${parseDuration(duration)} mes`
              : `${parseDuration(duration)} meses`}{' '}
            -{' '}
            {numberOfSessions === 1
              ? `${numberOfSessions} clase`
              : `${numberOfSessions} clases`}{' '}
            {frequencyNumber && (
              <>
                -{' '}
                {frequencyNumber === 1
                  ? `${frequencyNumber} vez a la semana`
                  : `${frequencyNumber} veces a la semana`}
              </>
            )}
          </Paragraph>
          <Paragraph size="14" weight={400}>
            {status === 'has_not_started' ||
            status === 'blocked' ||
            status === 'remaining' ? (
              <>
                {' '}
                {startDate
                  ? `Inicia ${parseCourseDateAlternative(startDate)}`
                  : 'Fecha de inicio por definir'}
              </>
            ) : (
              <>
                {progress === 100 || status === 'has_ended'
                  ? '¡Completaste todas las clases del curso!'
                  : `¡Vas ${progress} de ${numberOfSessions} en el curso!`}
              </>
            )}
          </Paragraph>
        </div>
      </section>
    </div>
  );
};

export default HeaderCardCourseProgress;
