const IconProfileUser = ({
  fill = '#000000',
  className,
  dataTestid = 'profile-user'
}) => (
  <svg
    className={className}
    data-testid={dataTestid}
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1016_5350)">
      <path
        className={`${className}-path`}
        data-testid={`${dataTestid}-path`}
        d="M7.69998 12.8C9.6882 12.8 11.3 11.1882 11.3 9.19998C11.3 7.21175 9.6882 5.59998 7.69998 5.59998C5.71175 5.59998 4.09998 7.21175 4.09998 9.19998C4.09998 11.1882 5.71175 12.8 7.69998 12.8Z"
        fill="#313131"
      />
      <path
        className={`${className}-path-2`}
        data-testid={`${dataTestid}-path-2`}
        d="M17.3 12.8C19.2882 12.8 20.9 11.1882 20.9 9.19998C20.9 7.21175 19.2882 5.59998 17.3 5.59998C15.3117 5.59998 13.7 7.21175 13.7 9.19998C13.7 11.1882 15.3117 12.8 17.3 12.8Z"
        fill="#313131"
      />
      <path
        className={`${className}-path-3`}
        data-testid={`${dataTestid}-path-3`}
        d="M7.7 14C3.728 14 0.5 16.148 0.5 18.8V21.2H7.7V18.8C7.7 17.024 9.152 15.476 11.3 14.648C10.244 14.228 9.008 14 7.7 14ZM17.3 14C13.328 14 10.1 16.148 10.1 18.8V21.2H24.5V18.8C24.5 16.148 21.272 14 17.3 14Z"
        fill="#313131"
      />
    </g>
    <defs>
      <clipPath id="clip0_1016_5350">
        <rect
          className={`${className}-rect`}
          data-testid={`${dataTestid}-rect`}
          width="24"
          height="24"
          fill={fill}
          transform="translate(0.5 2)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default IconProfileUser;
