const IconStarStep = ({ status = 'has_not_started', fill, className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8907 4.54544L12 2.80008L11.1093 4.54544L8.94236 8.79153L4.23448 9.54028L2.2993 9.84805L3.68399 11.2345L7.05264 14.6075L6.30993 19.3163L6.00464 21.2519L7.75114 20.3634L12 18.202L16.2489 20.3634L17.9954 21.2519L17.6901 19.3163L16.9474 14.6075L20.316 11.2345L21.7007 9.84805L19.7655 9.54028L15.0576 8.79153L12.8907 4.54544Z"
      fill={status === 'has_ended' ? fill : '#FFFFFF'}
      stroke={status === 'has_ended' ? fill : '#DDDDDD'}
      strokeWidth="2"
    />
  </svg>
);

export default IconStarStep;
