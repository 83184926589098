/* eslint-disable react/prop-types */
import Image from 'next/image';

import { H3, Paragraph } from '#styleguide/index';

const AboutVacation = props => {
  const {
    title,
    subtitle,
    description,
    image,
    button,
    imgDeskDeg,
    colorImgDeskDeg,
    noteButton
  } = props;
  return (
    <div className="about-vacation">
      <div className="about-vacation__first-content">
        {image ? (
          <img
            className="about-vacation__image"
            src={image}
            alt={`${title} | Crack The Code`}
            width={277}
          />
        ) : imgDeskDeg ? (
          <div
            className="about-vacation__container-img"
            style={{ background: colorImgDeskDeg }}
          >
            <Image
              src={imgDeskDeg}
              alt={`${title} | Crack The Code`}
              width={488}
              height={488}
            />
          </div>
        ) : null}
      </div>
      <div className="about-vacation__second-content">
        <H3>{title}</H3>
        <Paragraph size="16">{description}</Paragraph>
        {(button || noteButton) && (
          <div className="about-vacation__container-button">
            {noteButton}
            {button}
          </div>
        )}
      </div>
    </div>
  );
};

export default AboutVacation;
