const IconDownload = ({ fill = '#000000', className }) => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.815557 11.25H12.2334C12.6838 11.25 13.049 11.5858 13.049 12C13.049 12.4142 12.6838 12.75 12.2334 12.75H0.815557C0.365136 12.75 -1.90735e-06 12.4142 -1.90735e-06 12C-1.90735e-06 11.5858 0.365136 11.25 0.815557 11.25Z"
      fill={fill}
    />
    <path
      d="M13.049 10.5V12C13.049 12.4142 12.6838 12.75 12.2334 12.75C11.783 12.75 11.4178 12.4142 11.4178 12V10.5C11.4178 10.0858 11.783 9.75 12.2334 9.75C12.6838 9.75 13.049 10.0858 13.049 10.5Z"
      fill={fill}
    />
    <path
      d="M1.63112 10.5L1.63112 12C1.63112 12.4142 1.26598 12.75 0.815559 12.75C0.365138 12.75 0 12.4142 0 12L0 10.5C0 10.0858 0.365138 9.75 0.815559 9.75C1.26598 9.75 1.63112 10.0858 1.63112 10.5Z"
      fill={fill}
    />
    <path
      d="M6.52332 9C6.6924 9.00118 6.8577 8.95401 6.99634 8.865L10.2586 6.75C10.4344 6.6353 10.5537 6.46125 10.5904 6.2659C10.6271 6.07054 10.5781 5.86978 10.4543 5.7075C10.3925 5.62639 10.3138 5.55734 10.2229 5.50433C10.1319 5.45133 10.0304 5.41542 9.92422 5.39869C9.81806 5.38195 9.70935 5.38472 9.60436 5.40683C9.49938 5.42894 9.40019 5.46995 9.31253 5.5275L6.52332 7.32L3.75042 5.4C3.57738 5.28066 3.35987 5.22941 3.14574 5.25754C2.93162 5.28567 2.73841 5.39087 2.60863 5.55C2.47885 5.70913 2.42313 5.90916 2.45372 6.10607C2.48431 6.30298 2.59871 6.48066 2.77175 6.6L6.03398 8.85C6.17515 8.94737 6.34686 9 6.52332 9V9Z"
      fill={fill}
    />
    <path
      d="M6.52448 7.5C6.74077 7.5 6.94822 7.42098 7.10116 7.28033C7.25411 7.13968 7.34003 6.94891 7.34003 6.75V0.75C7.34003 0.551088 7.25411 0.360322 7.10116 0.21967C6.94822 0.0790176 6.74077 0 6.52448 0C6.30818 0 6.10073 0.0790176 5.94779 0.21967C5.79484 0.360322 5.70892 0.551088 5.70892 0.75V6.75C5.70892 6.94891 5.79484 7.13968 5.94779 7.28033C6.10073 7.42098 6.30818 7.5 6.52448 7.5Z"
      fill={fill}
    />
  </svg>
);

export default IconDownload;
