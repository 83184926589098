import React from 'react';

const H4 = ({
 className, children, align, color = '#000000', weight = '700' 
}) => {
  return (
    <h4
      className={`typography-h4 ${className}`}
      style={{ textAlign: align, color: color, fontWeight: weight }}
    >
      {children}
    </h4>
  );
};

export default H4;
