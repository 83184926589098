import React from 'react';

const Hr = ({ margin }) => {
  return (
    <div>
      <hr className="ui-hr" style={{ margin: `${margin}px 0` }} />
    </div>
  );
};

export default Hr;
