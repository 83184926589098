import { useRouter } from 'next/router';

import {
  Button,
  H3,
  H4,
  IconArrowRight,
  IconCalendarCurve,
  IconDownload,
  IconFlagColombia,
  IconFlagPeru,
  IconFlagMexico,
  Paragraph
} from '#styleguide/index';

const CardTypeProgram = ({
  name,
  description,
  thumbnail,
  gradient,
  secondaryColor,
  supportColor,
  ageRange,
  tag,
  index,
  thumbnailMob,
  link,
  silabo
}) => {
  const router = useRouter();

  return (
    <>
      <div
        className={
          index === 1
            ? `card-type-program card-type-program--one-program card-type-program--hover-${ageRange}`
            : `card-type-program card-type-program--more-program card-type-program--hover-${ageRange}`
        }
      >
        <div className="card-type-program__flag-countries">
          <Paragraph size="16">Disponible para:</Paragraph>
          <div className="card-type-program__individual-flag">
            {index === 1 ? (
              <>
                <IconFlagPeru />
                <IconFlagColombia />
              </>
            ) : (
              <>
                <IconFlagMexico />
              </>
            )}
          </div>
        </div>
        <div className="card-type-program__container-card">
          <div
            className={`card-type-program__container-thumbnail 
						${
              index === 1
                ? ' card-type-program__container-thumbnail--one-program'
                : 'card-type-program__container-thumbnail--more-program'
            }
					`}
          >
            <img
              className={`card-type-program__thumbnail
							${
                index === 1
                  ? 'card-type-program__thumbnail--one-program'
                  : 'card-type-program__thumbnail--more-program'
              }
						`}
              src={thumbnail}
              alt={name}
            />
            <img
              className={`card-type-program__thumbnail-mob
							${
                index === 1
                  ? 'card-type-program__thumbnail-mob--one-program'
                  : 'card-type-program__thumbnail-mob--more-program'
              }
						`}
              src={thumbnailMob}
              alt={name}
            />
          </div>
          <div
            className={
              index === 1
                ? 'card-type-program__container-detail card-type-program__container-detail--one-program'
                : 'card-type-program__container-detail card-type-program__container-detail--more-program'
            }
            style={{ background: gradient }}
          >
            <div>
              {index === 1 ? (
                <H4
                  className="card-type-program__detail-title card-type-program__detail-title--one-program"
                  color={supportColor}
                >
                  <span>{name}</span>
                </H4>
              ) : (
                <H3
                  className="card-type-program__detail-title card-type-program__detail-title--more-program"
                  color={secondaryColor}
                >
                  <span>{name}</span>
                  {/* <span style={{ color: supportColor }}>{namePack}</span> */}
                </H3>
              )}
              <span
                className={
                  index === 1
                    ? 'card-type-program__duration card-type-program__duration--one-program'
                    : 'card-type-program__duration card-type-program__duration--more-program'
                }
              >
                <IconCalendarCurve />
                {index === 1 ? (
                  <Paragraph size="16">
                    Duración: <b>4 sesiones</b> | 2 semanas
                  </Paragraph>
                ) : (
                  <Paragraph size="16">
                    Duración: <b>8 sesiones</b> | 4 semanas
                  </Paragraph>
                )}
              </span>
            </div>
            <div className="card-type-program__container-button">
              {index === 1 ? (
                <Button
                  isFullWidth={true}
                  onClick={() => router.push(`/cursos/${link}`)}
                  iconRight={
                    <IconArrowRight
                      fill="#ffffff"
                      className="card-type-program__icon-right"
                    />
                  }
                >
                  Ver curso
                </Button>
              ) : (
                <Button
                  isFullWidth={true}
                  onClick={() => router.push(`/cursos/${link}`)}
                  iconRight={
                    <IconArrowRight
                      fill="#ffffff"
                      className="card-type-program__icon-right"
                    />
                  }
                >
                  Ver pack de cursos
                </Button>
              )}
              <Button
                isFullWidth={true}
                level="secondary"
                iconRight={<IconDownload />}
                onClick={() => window.open(silabo, '_blank')}
              >
                Descarga el sílabo
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardTypeProgram;
