import React from 'react';

const H3 = ({
 children, align, color = '#000000', weight = '700', className 
}) => {
  return (
    <h3
      className={`typography-h3 ${className}`}
      style={{ textAlign: align, color: color, fontWeight: weight }}
    >
      {children}
    </h3>
  );
};

export default H3;
