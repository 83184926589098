const IconCalendar = ({ fill = '#000000', className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.999 22H4.99899C3.89442 22 2.99899 21.1046 2.99899 20V6C2.99899 4.89543 3.89442 4 4.99899 4H6.99899V2H8.99899V4H14.999V2H16.999V4H18.999C20.1036 4 20.999 4.89543 20.999 6V20C20.999 21.1046 20.1036 22 18.999 22ZM4.99899 10V20H18.999V10H4.99899ZM4.99899 6V8H18.999V6H4.99899Z"
      fill={fill}
    />
  </svg>
);

export default IconCalendar;
