const IconBlocked = ({ fill, className }) => (
  <svg
    width="41"
    height="49"
    viewBox="0 0 41 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 49C4.125 49 2.9475 48.5427 1.9675 47.628C0.987504 46.7133 0.498338 45.6151 0.500004 44.3333V21C0.500004 19.7167 0.990004 18.6177 1.97 17.703C2.95 16.7883 4.12667 16.3318 5.5 16.3333H8V11.6667C8 8.43889 9.21917 5.68711 11.6575 3.41133C14.0958 1.13556 17.0433 -0.00155396 20.5 1.59381e-06C23.9583 1.59381e-06 26.9067 1.13789 29.345 3.41367C31.7833 5.68945 33.0017 8.44044 33 11.6667V16.3333H35.5C36.875 16.3333 38.0525 16.7907 39.0325 17.7053C40.0125 18.62 40.5017 19.7182 40.5 21V44.3333C40.5 45.6167 40.01 46.7157 39.03 47.6303C38.05 48.545 36.8733 49.0015 35.5 49H5.5ZM20.5 37.3333C21.875 37.3333 23.0525 36.876 24.0325 35.9613C25.0125 35.0467 25.5017 33.9484 25.5 32.6667C25.5 31.3833 25.01 30.2843 24.03 29.3697C23.05 28.455 21.8733 27.9984 20.5 28C19.125 28 17.9475 28.4573 16.9675 29.372C15.9875 30.2867 15.4983 31.3849 15.5 32.6667C15.5 33.95 15.99 35.049 16.97 35.9637C17.95 36.8783 19.1267 37.3349 20.5 37.3333ZM13 16.3333H28V11.6667C28 9.72222 27.2708 8.06944 25.8125 6.70833C24.3542 5.34722 22.5833 4.66667 20.5 4.66667C18.4167 4.66667 16.6458 5.34722 15.1875 6.70833C13.7292 8.06944 13 9.72222 13 11.6667V16.3333Z"
      fill={fill}
    />
  </svg>
);

export default IconBlocked;
