import React, { useEffect, useState } from 'react';

import IconBlocked from '#styleguide/atoms/icons/icon-blocked';
import IconShoppingCart from '#styleguide/atoms/icons/icon-shopping-cart';
import { Paragraph, IconClose } from '#styleguide/index';
import { Link } from '#atoms/index';
import { event } from '#helpers/gtag';

import { urlToGetLearningPath, urlToGetOneCourse } from './constants';

const CardPathCourseBlocked = ({ data, status, childName }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {}, [isVisible, setIsVisible]);

  const eventsAnalytics = type => {
    if (type === 'compra-curso') {
      event('compra-curso', 'zona-padres', 'clic en adquirir curso en zona de padres');
    } else if (type === 'compra-ruta') {
      event('compra-ruta', 'zona-padres', 'clic en botón compra ruta en zona de padres');
    }
  };

  const handleClick = () => {
    setIsVisible(true);
  };
  if (data?.invoice === null) {
    return (
      <>
        <div
          className={`
              ui-card-path-course-blocked 
              ${
                (status === 'remaining' || status === 'blocked') && !isVisible
                  ? 'ui-card-path-course-blocked__show'
                  : 'ui-card-path-course-blocked__hidden'
              }
            `}
        >
          <div className="ui-card-path-course-blocked__button-close">
            <div onClick={handleClick}>
              <IconClose fill="#F6F6F6" />
            </div>
          </div>
          <div className="ui-card-path-course-blocked__container">
            <IconBlocked fill="#F6F6F6" />
            <Paragraph
              size="24"
              weight={700}
              color="#FFFFFF"
              align="center"
              className={'ui-card-path-course-blocked__title-section'}
            >
              ¡No dejes que {childName} se pierda este increíble curso!
            </Paragraph>
            <Paragraph
              size="14"
              weight={400}
              color="#FFFFFF"
              align="center"
              className={'ui-card-path-course-blocked__sub-title-section'}
            >
              También puedes adquirir la ruta completa de cursos para asegurar su
              educación completa y enriquecedora
            </Paragraph>
          </div>
          <div className="ui-card-path-course-blocked__buttons-section">
            <>
              <div
                className="ui-card-path-course-blocked__button-filled"
                onClick={() => eventsAnalytics('compra-curso')}
              >
                <Link href={urlToGetOneCourse} external={true}>
                  <Paragraph size="16" weight={700}>
                    Adquirir curso
                  </Paragraph>
                  <Paragraph size="16" weight={700}>
                    <IconShoppingCart fill={'#000000'} />
                  </Paragraph>
                </Link>
              </div>
              <div
                className="ui-card-path-course-blocked__button-outline"
                onClick={() => eventsAnalytics('compra-ruta')}
              >
                <Link href={urlToGetLearningPath} external={true}>
                  <Paragraph size="16" weight={700} color="#FFFFFF">
                    Adquirir ruta completa
                  </Paragraph>
                </Link>
              </div>
            </>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

export default CardPathCourseBlocked;
