import React from 'react';

const H1 = ({
 className, children, align, color = '#000000', weight = '700' 
}) => {
  return (
    <h1
      className={`typography-h1 ${className}`}
      style={{ textAlign: align, color: color, fontWeight: weight }}
    >
      {children}
    </h1>
  );
};

export default H1;
