import {
 bool, func, node, string 
} from 'prop-types';
import cx from 'classnames';

const Button = ({
  children,
  color,
  variant,
  onClick,
  type,
  disabled,
  testid,
  classNameProp
}) => {
  const classNames = cx('button', {
    [`button--color-${color}`]: color,
    [`button--variant-${variant}`]: variant
  });

  return (
    <>
      {variant === 'link' ? (
        <div className={classNames} onClick={onClick} data-testid={testid}>
          {children}
        </div>
      ) : (
        <button
          className={`${classNames} ${classNameProp}`}
          onClick={onClick}
          type={type}
          disabled={disabled}
          data-testid={testid}
        >
          {children}
        </button>
      )}
    </>
  );
};

Button.propTypes = {
  children: node.isRequired,
  color: string,
  variant: string,
  onClick: func,
  type: string,
  disabled: bool,
  classNameProp: string
};

export default Button;
