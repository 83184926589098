/* eslint-disable no-fallthrough */
import { string } from 'prop-types';

import { Typography } from '#atoms/index';
import IconCheckCircle from '#app/UI/atoms/icons/checkCircle';

const Toast = ({
 title, message, variant, mtop, mbottom, mRight, mLeft 
}) => {
  switch (variant) {
    case 'info':
      return (
        <div
          style={{
            marginTop: mtop ? mtop : '-15px',
            marginRight: mRight ? mRight : '0px',
            marginLeft: mLeft ? mLeft : '0px',
            marginBottom: mbottom ? mbottom : '5px',
            display: 'flex',
            color: '#001F19'
          }}
          className="toast"
        >
          <Typography variant="body2">{message}</Typography>
        </div>
      );

    case 'fulltoast':
      return (
        <div
          style={{
            marginTop: mtop ? mtop : '-15px',
            marginRight: mRight ? mRight : '0px',
            marginLeft: mLeft ? mLeft : '0px',
            marginBottom: mbottom ? mbottom : '5px'
          }}
          className="toast"
        >
          <Typography variant="h3" color="red">
            {title}
          </Typography>
          <Typography variant="body2" color="red">
            {message}
          </Typography>
        </div>
      );

    case 'success':
      return (
        <div
          style={{
            marginTop: mtop ? mtop : '-15px',
            marginRight: mRight ? mRight : '0px',
            marginLeft: mLeft ? mLeft : '0px',
            marginBottom: mbottom ? mbottom : '5px',
            display: 'flex'
          }}
          className="toast"
        >
          <IconCheckCircle fill="#56B23A" />
          <Typography variant="body2" color="green">
            {message}
          </Typography>
        </div>
      );
    case 'success-white':
      return (
        <div
          style={{
            marginTop: mtop ? mtop : '-15px',
            marginRight: mRight ? mRight : '0px',
            marginLeft: mLeft ? mLeft : '0px',
            marginBottom: mbottom ? mbottom : '5px',
            display: 'flex'
          }}
          className="toast"
        >
          <IconCheckCircle fill="#ffffff" />
          <Typography variant="body2" color="white">
            {message}
          </Typography>
        </div>
      );

    default:
      return (
        <div
          style={{
            marginTop: mtop ? mtop : '-15px',
            marginRight: mRight ? mRight : '0px',
            marginLeft: mLeft ? mLeft : '0px',
            marginBottom: mbottom ? mbottom : '5px',
            display: 'flex'
          }}
          className="toast"
        >
          <Typography variant="body2" color="red">
            {message}
          </Typography>
        </div>
      );
  }
};

Toast.propTypes = {
  title: string,
  message: string,
  variant: string
};

export default Toast;
