import React from 'react';
import { string } from 'prop-types';
import dynamic from 'next/dynamic';

import IconShoppingCart from '#styleguide/atoms/icons/icon-shopping-cart';
import {
  Paragraph,
  Hr,
  Image,
  IconDownload,
  IconArrowRight,
  IconCertificateCourse,
  Button
} from '#styleguide/index';
import { Link } from '#atoms/index';
import { useUserContext } from '#hooks/index';
import { getTime, getTimeZone, getCityOfTimezone } from '#helpers/parsers';
import { event } from '#helpers/gtag';

import { urlToGetOneCourse, urlToGetLearningPath } from './constants';

// Dynamic Components
const DynamicModal = dynamic(() => import('../../components/modal'));
const DynamicCertificate = dynamic(() =>
  import('../../components/Certificate/certificate')
);

const BodyCardCourse = ({
  thumbnail,
  courseName,
  status,
  teacher,
  projectDesc,
  groupDayName,
  groupTimeStart,
  groupTimeEnd,
  child,
  course,
  hrefInstructive,
  hrefCurriculum,
  zoomUrl
}) => {
  const { data: userData } = useUserContext();
  const eventsAnalytics = type => {
    if (type === 'instructive') {
      event(
        'descarga-instructivos',
        'zona-padres',
        'clic en instructivo en zona de padres'
      );
      event(
        `descarga-instructivos: ${courseName} - ${userData?.email}`,
        'zona-padres',
        'clic en instructivo en zona de padres'
      );
    } else if (type === 'curriculum') {
      event(
        'descarga-curricula',
        'zona-padres',
        'clic en botón curricula en zona de padres'
      );
      event(
        `descarga-curricula: ${courseName} - ${userData?.email}`,
        'zona-padres',
        'clic en botón curricula en zona de padres'
      );
    } else if (type === 'compra-curso') {
      event('compra-curso', 'zona-padres', 'clic en adquirir curso en zona de padres');
    } else if (type === 'compra-ruta') {
      event('compra-ruta', 'zona-padres', 'clic en botón compra ruta en zona de padres');
    }
  };

  return (
    <div className="ui-body-card-course">
      <div className="ui-body-card-course__column">
        <div className="ui-body-card-course__container-image">
          {thumbnail && <Image src={thumbnail} alt={courseName} width="100" />}
        </div>
      </div>
      <div
        className="ui-body-card-course__column"
        style={{ width: status === 'has_ended' ? '80%' : '90%' }}
      >
        <div className="ui-body-card-course__principal-info">
          <div className="ui-body-card-course__project-info">
            <Paragraph size="14" weight={400} color="#00000050">
              Proyecto:
            </Paragraph>
            <Paragraph size="16" weight={400}>
              {projectDesc || 'Sin descripción'}
            </Paragraph>
          </div>
          <div className="ui-body-card-course__container-image-mobile">
            {thumbnail && <Image src={thumbnail} alt={courseName} width="88" />}
          </div>
        </div>
        <Hr margin="16" />
        <section className="ui-body-card-course__container-items-section">
          <div className="ui-body-card-course__container-items-section--column">
            <div className="ui-body-card-course__container-section">
              <Paragraph size="14" weight={400} color="#00000050">
                Profesor:
              </Paragraph>
              <Paragraph size="16" weight={700}>
                {teacher ? teacher : 'No asignado aún'}
              </Paragraph>
            </div>
            <div className="ui-body-card-course__container-section">
              <Paragraph size="14" weight={400} color="#00000050">
                Horarios:
              </Paragraph>
              {groupDayName && groupTimeStart && groupTimeEnd ? (
                <Paragraph size="16" weight={700}>
                  {groupDayName} {getTime(groupTimeStart)} a {getTime(groupTimeEnd)} (
                  {getCityOfTimezone(getTimeZone())})
                </Paragraph>
              ) : (
                <Paragraph size="16" weight={700}>
                  No asignado aún
                </Paragraph>
              )}
            </div>
            <div className="ui-body-card-course__container-section">
              <Paragraph size="14" weight={400} color="#00000050">
                Material:
              </Paragraph>
              <div className="ui-body-card-course__content-section">
                {hrefInstructive || hrefCurriculum ? (
                  <>
                    {hrefInstructive && (
                      <div
                        className="ui-body-card-course__container-file"
                        onClick={() => eventsAnalytics('instructive')}
                      >
                        <Link href={hrefInstructive} external={true}>
                          <Paragraph size="16" weight={700}>
                            Instructivos
                          </Paragraph>
                          <Paragraph size="16" weight={700}>
                            <IconDownload />
                          </Paragraph>
                        </Link>
                      </div>
                    )}
                    {hrefCurriculum && (
                      <div
                        className="ui-body-card-course__container-file"
                        onClick={() => eventsAnalytics('curriculum')}
                      >
                        <Link href={hrefCurriculum} external={true}>
                          <Paragraph size="16" weight={700}>
                            Currícula
                          </Paragraph>
                          <Paragraph size="16" weight={700}>
                            <IconDownload />
                          </Paragraph>
                        </Link>
                      </div>
                    )}
                  </>
                ) : (
                  <Paragraph size="16" weight={700}>
                    No es necesario que descargues ningún material.
                  </Paragraph>
                )}
              </div>
            </div>
          </div>
          {status === 'in_progress' && (
            <div
              className="ui-body-card-course__container-items-section--column-last"
              style={{ minWidth: '218px', justifyContent: 'flex-end' }}
            >
              {(!teacher && status === 'in_progress') ||
              ((!groupDayName || !groupTimeStart || !groupTimeEnd) &&
                status === 'in_progress') ||
              !zoomUrl ? (
                <Button size="small" isFullWidth={true}>
                  <Link
                    href="https://wa.me/51975071866?text=¡Hola! No puedo acceder a mi clase de zoom"
                    external={true}
                    isFullWidth={false}
                  >
                    <Paragraph size="14" color="#FFFFFF">
                      Contactar a soporte
                    </Paragraph>
                    <IconArrowRight fill="#FFFFFF" />
                  </Link>
                </Button>
              ) : (
                <Button
                  size="small"
                  isFullWidth={true}
                  href={zoomUrl}
                  /* isDisabled={zoomUrl ? false : true} */
                >
                  <Link href={zoomUrl} external={true} isFullWidth={false}>
                    <Paragraph size="14" color="#FFFFFF">
                      Ir a la clase
                    </Paragraph>
                    <IconArrowRight fill="#FFFFFF" />
                  </Link>
                </Button>
              )}
            </div>
          )}
          {(status === 'remaining' || status === 'blocked') && (
            <div
              className="ui-body-card-course__container-items-section--column-last"
              style={{ minWidth: '218px', justifyContent: 'flex-end' }}
            >
              {course.invoice === null && (
                <>
                  <div
                    className="ui-body-card-course__button-filled"
                    onClick={() => eventsAnalytics('compra-curso')}
                    style={{
                      background: '#000000',
                      marginBottom: '16px',
                      width: '230px'
                    }}
                  >
                    <Link href={urlToGetOneCourse} external={true}>
                      <Paragraph size="16" weight={700} color={'#FFFFFF'}>
                        Adquirir curso
                      </Paragraph>
                      <Paragraph size="16" weight={700}>
                        <IconShoppingCart fill={'#FFFFFF'} />
                      </Paragraph>
                    </Link>
                  </div>
                  <div
                    className="ui-card-path-course-blocked__button-outline"
                    onClick={() => eventsAnalytics('compra-ruta')}
                    style={{
                      borderColor: '#000000',
                      width: '230px'
                    }}
                  >
                    <Link href={urlToGetLearningPath} external={true}>
                      <Paragraph size="16" weight={700} color="#000000">
                        Adquirir ruta completa
                      </Paragraph>
                    </Link>
                  </div>
                </>
              )}
            </div>
          )}
        </section>
      </div>
      {status === 'has_ended' && (
        <div
          className="ui-body-card-course__column"
          style={{ minWidth: '218px', gap: '8px' }}
        >
          <div className="ui-body-card-course__card-end-course">
            <section className="ui-body-card-course__card-end-course-desc">
              <IconCertificateCourse />
              <Paragraph size="14" align="center">
                Certificado de curso
              </Paragraph>
            </section>
            <DynamicModal btnText="Descargar Certificado">
              <DynamicCertificate course={course} children={child} />
            </DynamicModal>
          </div>
        </div>
      )}
    </div>
  );
};

BodyCardCourse.propTypes = {
  thumbnail: string.isRequired,
  courseName: string.isRequired,
  status: string.isRequired,
  teacher: string.isRequired,
  projectDesc: string.isRequired,
  groupDayName: string,
  groupTimeStart: string,
  groupTimeEnd: string,
  hrefInstructive: string,
  hrefCurriculum: string
};

export default BodyCardCourse;
