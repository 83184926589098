import { useState } from 'react';
import { bool, func, string } from 'prop-types';
import cx from 'classnames';

const TextField = ({
  name,
  placeholder,
  type = 'text',
  register,
  maxLength,
  size,
  error,
  isRequired,
  variant,
  disabled,
  onChange,
  onFocus,
  onBlur,
  clase,
  value,
  id,
  testid,
  hidePasswordIcon
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const showPass = showPassword ? 'text' : 'password';

  return (
    <div className="content-text-field">
      {type === 'password' ? (
        <input
          id={id}
          data-testid={testid}
          className={
            (variant
              ? cx(`text-field-${variant}`, { error })
              : cx('text-field', { error })) +
            ' ' +
            clase
          }
          style={{ borderRadius: '8px' }}
          name={name}
          placeholder={placeholder}
          type={showPass}
          maxLength={maxLength}
          size={size}
          ref={register}
          required={isRequired ? true : false}
          disabled={disabled ? true : false}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
        />
      ) : (
        <input
          id={id}
          data-testid={testid}
          className={
            (variant
              ? cx(`text-field-${variant}`, { error })
              : cx('text-field', { error })) +
            ' ' +
            clase
          }
          style={{ borderRadius: '8px' }}
          name={name}
          placeholder={placeholder}
          type={type}
          maxLength={maxLength}
          size={size}
          ref={register}
          required={isRequired ? true : false}
          disabled={disabled ? true : false}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
        />
      )}

      {type === 'password' && !hidePasswordIcon && (
        <button
          onClick={() => {
            setShowPassword(e => !e);
          }}
        >
          <img src="/eye-password.svg" />
        </button>
      )}
    </div>
  );
};

TextField.propTypes = {
  name: string.isRequired,
  placeholder: string,
  type: string,
  register: func,
  maxLength: string,
  size: string,
  error: bool,
  disabled: bool
};

export default TextField;
