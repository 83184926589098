const IconEmail = ({ fill = '#000000', className, dataTestid = 'email' }) => (
  <svg
    className={className}
    data-testid={dataTestid}
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={`${className}-path`}
      data-testid={`${dataTestid}-path`}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.0374517 2.15282L9 6.86995L17.9625 2.15282C17.9063 1.41748 17.7658 0.937339 17.4142 0.585786C16.8284 0 15.8856 0 14 0H4C2.11438 0 1.17157 0 0.585786 0.585786C0.234234 0.937339 0.0936614 1.41748 0.0374517 2.15282ZM18 4.39321L9.93149 8.63979C9.34841 8.94667 8.65159 8.94667 8.06851 8.63979L0 4.39321V10C0 11.8856 0 12.8284 0.585786 13.4142C1.17157 14 2.11438 14 4 14H14C15.8856 14 16.8284 14 17.4142 13.4142C18 12.8284 18 11.8856 18 10V4.39321Z"
      fill={fill}
    />
  </svg>
);

export default IconEmail;
