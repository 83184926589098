import Head from 'next/head';
import cx from 'classnames';
import { Fragment } from 'react';
import { useRouter } from 'next/router';

import Typography from '#app/UI/atoms/typography-updated';
import { Link } from '#atoms/index';

const Breadcrumbs = ({
 className, routes = [], isSeo = true, color = 'black' 
}) => {
  const YOUR_AWESOME_DOMAIN = 'https://www.crackthecode.la';
  const { asPath } = useRouter();

  const createEntity = (routes, asPath) => {
    return routes.map((route, idx) => {
      if (route.pathname === 'end') {
        return {
          '@type': 'ListItem',
          position: idx + 1,
          name: route.name,
          item: {
            '@type': 'Thing',
            '@id': `${YOUR_AWESOME_DOMAIN}${asPath}/`
          }
        };
      } else {
        return {
          '@type': 'ListItem',
          position: idx + 1,
          name: route.name,
          item: {
            '@type': 'Thing',
            '@id': `${YOUR_AWESOME_DOMAIN}${route.pathname}`
          }
        };
      }
    });
  };

  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: createEntity(routes, asPath)
  };

  return (
    <>
      {isSeo && (
        <Head>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
          />
        </Head>
      )}

      <div className={cx(className, 'breadcrumbs')}>
        {routes.map(route =>
          route.pathname !== 'end' ? (
            <Fragment key={route.pathname}>
              <Link href={route.pathname} isFullWidth={false}>
                <Typography tag="body-small" style={{ color: color }}>
                  {route.name}
                </Typography>
              </Link>
              <span style={{ color: color }}>&gt;</span>
            </Fragment>
          ) : (
            <Typography key={route.pathname} tag="body-small" style={{ color: color }}>
              {route.name}
            </Typography>
          )
        )}
      </div>
    </>
  );
};

export default Breadcrumbs;
