import { Typography } from '#atoms/index';
const HeaderContentSection = ({
 title, description, imgPath, variant 
}) => {
  return variant === 'course' ? (
    <section className="header_content_course-Section">
      <img className="desktop_img" src={imgPath} alt={`${title} header cover`} />
      <div className="header_content-Content">
        <Typography variant="h1">{title}</Typography>
        <Typography variant="body">{description}</Typography>
      </div>
      <img
        className="img_course_banner_mobile"
        src={imgPath}
        alt={`${title} header cover`}
      />
    </section>
  ) : (
    <section className="header_content-Section">
      <div className="header_content-Content">
        <Typography variant="h1">{title}</Typography>
        <Typography variant="body">{description}</Typography>
      </div>
      <img src={imgPath} alt={`${title} header cover`} />
    </section>
  );
};

export default HeaderContentSection;
