import { string } from 'prop-types';

import { Typography } from '#atoms/index';
const BuildingProcess = ({ title }) => {
  return (
    <div className="building_process_alert">
      <Typography variant="h2">{title}</Typography>
      <img src="/tech-life-media.webp" />
    </div>
  );
};

BuildingProcess.propTypes = { title: string.isRequired };

export default BuildingProcess;
