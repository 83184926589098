const IconHomeUser = ({ fill = '#000000', className, dataTestid = 'home-user' }) => (
  <svg
    className={className}
    data-testid={dataTestid}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={`${className}-path`}
      data-testid={`${dataTestid}-path`}
      d="M11.228 0.687989C10.8769 0.41488 10.4448 0.266602 9.99998 0.266602C9.55518 0.266602 9.12307 0.41488 8.77198 0.687989L0.387982 7.20799C-0.364018 7.79499 0.0499817 8.99999 1.00298 8.99999H1.99998V17C1.99998 17.5304 2.2107 18.0391 2.58577 18.4142C2.96084 18.7893 3.46955 19 3.99998 19H7.99998V13C7.99998 12.4696 8.2107 11.9608 8.58577 11.5858C8.96084 11.2107 9.46955 11 9.99998 11C10.5304 11 11.0391 11.2107 11.4142 11.5858C11.7893 11.9608 12 12.4696 12 13V19H16C16.5304 19 17.0391 18.7893 17.4142 18.4142C17.7893 18.0391 18 17.5304 18 17V8.99999H18.997C19.949 8.99999 20.365 7.79499 19.612 7.20899L11.228 0.688989V0.687989Z"
      fill={fill}
    />
  </svg>
);

export default IconHomeUser;
