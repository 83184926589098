import { useEffect, useState, useContext } from 'react';
import { func, shape } from 'prop-types';

import {
 Button, Link, Typography, Checkbox 
} from '#atoms/index';
import { SideModal } from '#components/index';
import { useUserContext } from '#hooks/index';
import { getTimeZone, parseDay } from '#helpers/parsers';
import { event } from '#helpers/gtag';
import LayoutContext from '#layout/context';
import {
  getPurchaseNumber,
  getToken,
  getSessionKey,
  postTransaction,
  patchPurchaseNumber
} from '#api/niubiz';
import { postEnrollCourse } from '#api/courses';

import Loader from './loader';
import SuccessMessage from './sucess-message';
import FailureMessage from './failure-message';

const MERCHANT_ID_PEN = process.env.NEXT_PUBLIC_NIUBIZ_MERCHANT_ID_PEN;
const MERCHANT_ID_USD = process.env.NEXT_PUBLIC_NIUBIZ_MERCHANT_ID_USD;
const NIUBIZ_SRC = process.env.NEXT_PUBLIC_NIUBIZ_SRC;

const NiubizPaymentModal = ({ courseData, onClose, paymentModalData }) => {
  const [formState, setFormState] = useState('IDLE');
  const [loadingForm, setLoadingForm] = useState(true);
  const [purchaseNumber, setPurchaseNumber] = useState(null);
  const [token, setToken] = useState(null);
  const [merchantId, setMerchantId] = useState(null);
  const [isPaying, setIsPaying] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [transactionResponse, setTransactionResponse] = useState(null);
  const { data: userData } = useUserContext();
  const { setIsLoginOpen } = useContext(LayoutContext);

  const loadPayment = async ({ currencyCode, price }) => {
    setLoadingForm(true);
    // Set merchant id
    const merchantId = currencyCode === 'PEN' ? MERCHANT_ID_PEN : MERCHANT_ID_USD;
    setMerchantId(merchantId);
    // Get purchase id
    const responsePurchaseData = await getPurchaseNumber(merchantId);
    setPurchaseNumber(responsePurchaseData.data.purchase_id);
    // Get token
    const responseToken = await getToken(merchantId);
    setToken(responseToken.token);
    // Get Session Key
    const responseSession = await getSessionKey(responseToken.token, price, merchantId);
    // Add Custom Script
    try {
      await addCustomPayScript({
        sessionKey: responseSession.data.sessionKey,
        merchantId,
        purchaseNumber: responsePurchaseData.data.purchase_id,
        amount: price
      });
      setLoadingForm(false);
    } catch {
      <Typography variant="body">¡Ups!,algo salio mal,vuelva a intentarlo</Typography>;
    }
  };

  const addCustomPayScript = ({
 sessionKey, merchantId, purchaseNumber, amount 
}) => {
    return new Promise(resolve => {
      const script = document.createElement('script');
      script.src = NIUBIZ_SRC;
      script.id = 'NIUBIZ_SCRIPT';
      script.async = true;
      script.onload = () =>
        setConfigurationScriptLoaded({
          sessionKey,
          merchantId,
          purchaseNumber,
          amount
        });
      document.head.appendChild(script);
      resolve();
    });
  };

  const setConfigurationScriptLoaded = ({
    sessionKey,
    merchantId,
    purchaseNumber,
    amount
  }) => {
    const configuration = {
      sessionkey: sessionKey,
      channel: 'web',
      merchantid: merchantId,
      purchasenumber: purchaseNumber,
      amount,
      language: 'es',
      merchantConfiguration: {
        recurrencyEnabled: false,
        tokenizationEnabled: false
      }
    };
    window.configuration = configuration;
    window.payform.setConfiguration(configuration);
    createForm();
  };

  const createForm = () => {
    window.cardNumber = window.payform.createElement(
      'card-number',
      {
        style: elementStyles,
        placeholder: 'Número de Tarjeta'
      },
      'cardNumberWrapper'
    );
    window.cardExpiry = window.payform.createElement(
      'card-expiry',
      {
        style: elementStyles,
        placeholder: 'MM/AA'
      },
      'dueDateWrapper'
    );
    window.cardCvv = window.payform.createElement(
      'card-cvc',
      {
        style: elementStyles,
        placeholder: 'CVV'
      },
      'cvvWrapper'
    );
  };

  // * This function verify if exist token before registering purchase
  const verifyToken = (amount, purchaseNumber, merchantId, token, userData) => {
    if (
      localStorage.getItem('accessToken') !== null &&
      localStorage.getItem('refreshToken') !== null
    ) {
      getTransactionToken(amount, purchaseNumber, merchantId, token, userData);
    } else {
      handleOnClose();
      setIsLoginOpen(true);
    }
  };

  const getTransactionToken = async ({
    amount,
    purchaseNumber,
    merchantId,
    token,
    userData
  }) => {
    setIsPaying(true);
    var data = {
      name: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      phoneNumber: userData.phone,
      currencyConversion: false,
      recurrence: false,
      alias: 'mialias'
    };
    try {
      const response = await window.payform.createToken(
        [window.cardNumber, window.cardExpiry, window.cardCvv],
        data
      );

      const transactionResponse = await postTransaction({
        merchantId,
        amount,
        purchaseNumber,
        transactionToken: response.transactionToken,
        token
      });
      if (transactionResponse.code === 200) {
        setTransactionResponse(transactionResponse.data);
        if (
          courseData.fields.slug === 'curso-de-videojuegos-con-roblox' ||
          courseData.fields.slug === 'curso-de-youtuber-kids'
        ) {
          await postEnrollCourse({
            course_id: courseData.sys.id,
            course_name: courseData.fields.name,
            module_id: paymentModalData.product.sys.id,
            module_name: paymentModalData.product.fields.concept,
            group_id: paymentModalData.group.sys.id,
            group_name: paymentModalData.group.fields.name,
            initial_date: paymentModalData.group.fields.startDate,
            schedules: [
              {
                day: parseDay(paymentModalData.group.fields.startDate),
                hour: paymentModalData.group.fields.startHour
              }
            ],
            timezone_name: getTimeZone(),
            purchase: purchaseNumber
          });
        } else {
          await postEnrollCourse({
            course_id: courseData.sys.id,
            course_name: courseData.fields.name,
            module_id: paymentModalData.product.sys.id,
            module_name: paymentModalData.product.fields.concept,
            initial_date: courseData.fields.initialDate,
            timezone_name: getTimeZone(),
            purchase: purchaseNumber
          });
        }
        await patchPurchaseNumber(purchaseNumber, {
          amount: paymentModalData.price,
          currency: paymentModalData.currencyCode,
          transaction_id: transactionResponse.data.order.transactionId
        });
        event('pay_item', 'purchase-flow', 'Click en Pagar de niubiz'); //*gta event
        setFormState('SUCCESS');
      } else {
        setTransactionResponse(transactionResponse.niubiz_response);
        event(
          'checkout_failure',
          'purchase-flow',
          'Problemas al momento de Confirmar de Pago de Compra'
        ); //*gta event
        setFormState('FAILURE');
      }
    } catch (e) {
      setIsPaying(false);
    }
  };

  const handleOnClose = () => {
    const scriptToDelete = document.getElementById('NIUBIZ_SCRIPT');
    scriptToDelete.parentNode.removeChild(scriptToDelete);
    onClose();
  };

  useEffect(() => {
    loadPayment(paymentModalData);
  }, [paymentModalData]);

  return (
    <SideModal title="Finalizar Pago" onClose={handleOnClose}>
      {loadingForm && (
        <Loader title="Procesando formulario de pago, verificando que todo este correcto" />
      )}
      {!loadingForm && formState === 'IDLE' && (
        <>
          <section className="niubiz-inputs-wrapper">
            <div className="__text">
              <Typography variant="body">
                Ingrese los datos de su tarjeta de crédito o débito
              </Typography>
            </div>
            <div id="cardNumberWrapper" className="__input-wrapper" />
            <div className="__row">
              <div id="dueDateWrapper" className="__input-wrapper" />
              <div id="cvvWrapper" className="__input-wrapper" />
            </div>

            <div className="__security-info">
              <Typography variant="body">
                Pago 100% seguro, este certificado garantiza la seguridad de todas tus
                conexiones mediante cifrado.
              </Typography>
              <div className="__logos">
                <img src="/niubiz/pci.png" />
                <div>
                  <img src="/niubiz/visa.png" />
                  <img src="/niubiz/mc.png" />
                  <img src="/niubiz/dc.png" />
                  <img src="/niubiz/amex.png" />
                </div>
              </div>
            </div>
            <div className="__product-info">
              <Typography variant="body" color="grey">
                Vas a pagar por:
              </Typography>
              <Typography variant="h4">{courseData.fields.name}</Typography>
              <Typography variant="body">
                {paymentModalData.product.fields.concept}
              </Typography>
            </div>

            <div className="__terms-and-condition">
              <Checkbox
                name="terms-and-conditions"
                checked={acceptTerms}
                onChange={(_, value) => setAcceptTerms(value)}
              />
              <Typography variant="body" color="grey">
                Acepto los
                <Link
                  href="/terminos-y-condiciones"
                  target="_blank"
                  rel="noreferrer"
                  variant="underline"
                >
                  Terminos y Condiciones
                </Link>
              </Typography>
            </div>
          </section>
          <footer className="niubiz-footer">
            <div className="niubiz-footer__total">
              <Typography variant="small">TOTAL</Typography>
              <div>
                <Typography variant="h3">
                  {paymentModalData.currencySymbol} {paymentModalData.price}
                </Typography>
                <Typography variant="small">{paymentModalData.currencyCode}</Typography>
              </div>
            </div>
            <Button
              onClick={() =>
                verifyToken({
                  amount: paymentModalData.price,
                  purchaseNumber,
                  merchantId,
                  token,
                  userData
                })
              }
              disabled={isPaying || !acceptTerms}
              color="sky"
            >
              {!isPaying ? 'PAGAR' : 'PAGANDO...'}
            </Button>
          </footer>
        </>
      )}
      {!loadingForm && formState === 'SUCCESS' && transactionResponse && (
        <SuccessMessage
          transactionData={transactionResponse}
          userData={userData}
          courseData={courseData}
          productData={paymentModalData}
        />
      )}
      {!loadingForm && formState === 'FAILURE' && (
        <FailureMessage
          transactionData={transactionResponse}
          purchaseNumber={purchaseNumber}
        />
      )}
    </SideModal>
  );
};

NiubizPaymentModal.propTypes = {
  courseData: shape(),
  paymentModalData: shape(),
  onClose: func
};

const elementStyles = {
  base: {
    color: '#666666',
    fontWeight: 400,
    fontFamily: "'Roca One', sans-serif",
    fontSize: '16px',
    fontSmoothing: 'antialiased',
    placeholder: { color: '#999999' },
    autofill: { color: '#e39f48' }
  },
  invalid: {
    color: '#E25950',
    '::placeholder': { color: '#FFCCA5' }
  }
};

export default NiubizPaymentModal;
