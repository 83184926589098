/* eslint-disable react/prop-types */
import Image from 'next/image';

import { CardTypeProgram } from '#components/index';
import { Button, H3, Paragraph } from '#styleguide/index';

const CardProgramVacation = ({
  title,
  subtitle,
  gradient,
  mainColor,
  secondaryColor,
  supportColor,
  typePrograms,
  myRef,
  myRef1,
  myRef2,
  id,
  ageRange,
  thumbnailCard,
  onClickRefContact
}) => {
  return (
    <div
      className="card-program-vacation"
      ref={id === 0 ? myRef : id === 1 ? myRef1 : myRef2}
    >
      <div className="card-program-vacation__first-section">
        <div className="card-program-vacation__header-image">
          <Image
            className="card-program-vacation__img"
            src={thumbnailCard}
            alt={`${subtitle} | Crack The Code`}
            width="264"
            height="264"
          />
        </div>
        <div className="card-program-vacation__header-detail">
          <H3 className="card-program-vacation__title" color={supportColor}>
            Niñ@s de
            <br className="card-program-vacation__br" />
            <span> {title}</span>
          </H3>
          <div className="card-program-vacation__section-button">
            <Button align="left" onClick={onClickRefContact}>
              <Paragraph size="21" color="#FFFFFF">
                Sí, quiero más info
              </Paragraph>
            </Button>
          </div>
        </div>
      </div>
      <div className="card-program-vacation__section-programs">
        {typePrograms.map((typeProgram, i) => (
          <CardTypeProgram
            typeProgram={typeProgram}
            key={i}
            index={i + 1}
            gradient={gradient}
            name={typeProgram?.name}
            namePack={typeProgram?.namePack}
            description={typeProgram?.description}
            slug={typeProgram?.slug}
            thumbnail={typeProgram?.thumbnail}
            thumbnailMob={typeProgram?.thumbnailMob}
            tag={typeProgram?.tag}
            mainColor={mainColor}
            secondaryColor={secondaryColor}
            supportColor={supportColor}
            quantity={typePrograms?.length}
            ageRange={ageRange}
            link={typeProgram?.link}
            silabo={typeProgram?.silabo}
          />
        ))}
      </div>
    </div>
  );
};

export default CardProgramVacation;
