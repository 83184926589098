import React from 'react';
import dynamic from 'next/dynamic';

import IconShoppingCart from '#styleguide/atoms/icons/icon-shopping-cart';
import IconCertificateNew from '#styleguide/atoms/icons/icon-certificate-new';
import { Paragraph } from '#styleguide/index';
import { Link } from '#atoms/index';
import { event } from '#helpers/gtag';

// Dynamic Components
const DynamicModal = dynamic(() => import('../../components/modal'));
const DynamicCertificate = dynamic(() =>
  import('../../components/Certificate/certificate')
);

const eventsAnalytics = type => {
  if (type === 'compra-ruta') {
    event('compra-ruta', 'zona-padres', 'clic en botón compra ruta en zona de padres');
  }
};

const CardCertificateBlocked = ({
 hasCertificatePath, childName, child, path 
}) => {
  return (
    <div className="ui-card-certificate-blocked">
      <div className="ui-card-certificate-blocked__container-certificate">
        <IconCertificateNew />
      </div>
      <Paragraph size="16" align="center">
        Cuando {childName} complete los cursos de la ruta podrás descargar aquí su
        certificado final
      </Paragraph>
      <div
        className="ui-card-certificate-blocked__button-filled"
        onClick={() => eventsAnalytics('compra-ruta')}
      >
        <Link href={'#'} external={true}>
          <Paragraph size="16" weight={700} color="#FFFFFF">
            Adquirir ruta completa
          </Paragraph>
          <Paragraph size="16" weight={700}>
            <IconShoppingCart fill={'#FFFFFF'} />
          </Paragraph>
        </Link>
      </div>
    </div>
  );
};

export default CardCertificateBlocked;
