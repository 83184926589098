const TimerClockIcon = () => (
  <svg
    width="23"
    height="44"
    viewBox="0 0 23 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 0.874512V13.5128H0.518334L0.5 13.5339L7.83333 21.9383L0.5 30.3639L0.518334 30.3849H0.5V43.0022H22.5V30.3849H22.4817L22.5 30.3639L15.1667 21.9383L22.5 13.5339L22.4817 13.5128H22.5V0.874512H0.5ZM18.8333 31.4171V38.7894H4.16667V31.4171L11.5 22.9915L18.8333 31.4171ZM11.5 20.8852L4.16667 12.4596V5.08728H18.8333V12.4596L11.5 20.8852Z"
      fill="#A8AFB3"
    />
  </svg>
);

export default TimerClockIcon;
