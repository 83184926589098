const IconArrow = ({ fill = '#000000', className, dataTestid = 'icon-arrow' }) => (
  <svg
    data-testid={dataTestid}
    className={className}
    width="15"
    height="9"
    viewBox="0 0 15 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={`${className}-path`}
      data-testid={`${dataTestid}-path`}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6926 6.84844C15.1035 7.24837 15.1035 7.90844 14.6926 8.30837C14.2971 8.69336 13.667 8.69336 13.2715 8.30837L7.99972 3.1767L2.72796 8.30837C2.33247 8.69336 1.70232 8.69336 1.30682 8.30837C0.895977 7.90844 0.895978 7.24837 1.30682 6.84844L7.99972 0.333393L14.6926 6.84844Z"
      fill={fill}
    />
  </svg>
);

export default IconArrow;
