import React from 'react';

import IconClockBold from '#app/UI/atoms/icons/clockBold';
import IconCheckCircle from '#app/UI/atoms/icons/checkCircle';
import IconCancelOutline from '#app/UI/atoms/icons/cancelOutline';
import IconProgressCheck from '#app/UI/atoms/icons/progressCheck';
import IconQuestionMark from '#app/UI/atoms/icons/questionMark';
import { Paragraph } from '#styleguide/index';

const Chip = ({ children, color, fill }) => {
  return (
    <div
      className="ui-chip"
      style={{
        background: fill,
        borderRadius: '0 0 12px 12px',
        marginTop: '-5px',
        height: '32px',
        width: '154px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {children === 'Completado' ? (
        <IconCheckCircle fill="#9C56E0" />
      ) : children === 'En progreso' ? (
        <IconProgressCheck fill="#fff" />
      ) : children === 'Por definir' ? (
        <IconQuestionMark fill="#fff" />
      ) : children === 'Programado' ? (
        <IconClockBold fill="#fff" />
      ) : children === 'No completado' ? (
        <IconCancelOutline fill="#555555" />
      ) : (
        ''
      )}
      <Paragraph size="14" weight={400} color={color}>
        {children}
      </Paragraph>
    </div>
  );
};

export default Chip;
