import React from 'react';

const SliderContent = ({
 itemSlider, dragHeadRef, zIndex, onClickPlay, isPlay 
}) => {
  return (
    <>
      <div className="slider-home__section-slide" style={{ zIndex: zIndex }}>
        <section className="slider-home__content" dragHeadRef={dragHeadRef}>
          <span>
            <p className={`slider-home__title `}>{itemSlider?.title}</p>
            <p className={`slider-home__description `}>{itemSlider?.subtitle}</p>
          </span>
          <section className="slider-home__container-buttons">
            {itemSlider?.is_primary_button_enabled && (
              <>
                {itemSlider?.primary_button_type === 'video' ? (
                  <div
                    onClick={onClickPlay}
                    className={`slider-home__gen-btn slider-home__first-btn`}
                    style={{ background: itemSlider?.primary_button_background_color }}
                  >
                    <span style={{ color: itemSlider?.primary_button_text_color }}>
                      {itemSlider?.primary_button_text}
                    </span>
                    <div className="btn-play" onClick={onClickPlay}>
                      <button className="btn">
                        <span className={isPlay ? 'play ' : 'play'}></span>
                      </button>
                    </div>
                  </div>
                ) : itemSlider?.primary_button_type === 'page' ? (
                  <div
                    className={`slider-home__gen-btn slider-home__first-btn`}
                    style={{ background: itemSlider?.primary_button_background_color }}
                    /* onClickSecondaryRedirectPage={() =>
                      onClickRedirectPage(itemSlider?.primary_button_url)
                    } */
                  >
                    <span style={{ color: itemSlider?.primary_button_text_color }}>
                      {itemSlider?.primary_button_text}
                    </span>
                  </div>
                ) : (
                  ''
                )}
              </>
            )}
            {itemSlider?.is_secondary_button_enabled && (
              <>
                {itemSlider?.secondary_button_text.includes('Escríbenos') ? (
                  <a
                    href={`https://wa.me/51975071866?text=¡Hola! Me gustaria recibir información.`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className={`slider-home__gen-btn slider-home__second-btn`}>
                      {/* <WhatsAppLogo fill="#000000" /> */}
                      <span style={{ marginLeft: '10px' }}>
                        {itemSlider?.secondary_button_text}
                      </span>
                    </div>
                  </a>
                ) : (
                  <div
                    className={`slider-home__gen-btn slider-home__second-btn`}
                    /* onClick={onClickSecondaryRedirectPage} */
                  >
                    <span>{itemSlider?.secondary_button_text}</span>
                  </div>
                )}
              </>
            )}
          </section>
        </section>
      </div>
    </>
  );
};

export default SliderContent;
