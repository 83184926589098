const IconContactUs = ({ fill = '#000000', className }) => (
  <svg
    className={className}
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.318 0C17.0818 0 17.8142 0.303391 18.3543 0.843432C18.8943 1.38347 19.1977 2.11592 19.1977 2.87966V12.4785C19.1977 13.2422 18.8943 13.9747 18.3543 14.5147C17.8142 15.0548 17.0818 15.3582 16.318 15.3582H5.11907L1.91977 17.7579C1.12882 18.3511 0 17.7867 0 16.798V2.87966C0 2.11592 0.303391 1.38347 0.843432 0.843432C1.38347 0.303391 2.11592 0 2.87965 0H16.318ZM8.63896 8.63897H5.75931C5.50473 8.63897 5.26058 8.7401 5.08057 8.92011C4.90056 9.10012 4.79943 9.34427 4.79943 9.59885C4.79943 9.85343 4.90056 10.0976 5.08057 10.2776C5.26058 10.4576 5.50473 10.5587 5.75931 10.5587H8.63896C8.89354 10.5587 9.13769 10.4576 9.31771 10.2776C9.49772 10.0976 9.59885 9.85343 9.59885 9.59885C9.59885 9.34427 9.49772 9.10012 9.31771 8.92011C9.13769 8.7401 8.89354 8.63897 8.63896 8.63897ZM13.4384 4.79943H5.75931C5.51466 4.7997 5.27934 4.89338 5.10144 5.06133C4.92353 5.22928 4.81648 5.45882 4.80214 5.70306C4.7878 5.94729 4.86726 6.18778 5.02429 6.3754C5.18132 6.56301 5.40406 6.68358 5.647 6.71248L5.75931 6.7192H13.4384C13.683 6.71892 13.9184 6.62524 14.0963 6.45729C14.2742 6.28934 14.3812 6.0598 14.3956 5.81556C14.4099 5.57133 14.3304 5.33084 14.1734 5.14323C14.0164 4.95561 13.7936 4.83504 13.5507 4.80614L13.4384 4.79943Z"
      fill={fill}
    />
  </svg>
);

export default IconContactUs;
