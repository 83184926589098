import { faqs_paths } from '#helpers/parseOptions';

import { FaqTabUpd } from './Tab';

const TrustUs = ({ faq = true, gamejam, eventNameAnalytics }) => {
  const faqs = gamejam ? faqs_paths.gameJam : faqs_paths.rutas;

  return (
    <section className={`trust_us-Wrapper ${gamejam}`}>
      {/* F.A.Q */}
      <div className="contact_faq">
        {faq && (
          <FaqTabUpd
            faqs={faqs}
            fill={'red'}
            eventNameAnalytics={!gamejam && eventNameAnalytics}
          />
        )}
      </div>
    </section>
  );
};

export default TrustUs;
