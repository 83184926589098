const IconPayments = ({ fill, className }) => (
  <svg
    className={className}
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.125 0.5C2.2962 0.5 1.50134 0.82924 0.915291 1.41529C0.32924 2.00134 0 2.7962 0 3.625V5.25H20V3.625C20 2.7962 19.6708 2.00134 19.0847 1.41529C18.4987 0.82924 17.7038 0.5 16.875 0.5H3.125ZM0 12.375V6.5H20V12.375C20 13.2038 19.6708 13.9987 19.0847 14.5847C18.4987 15.1708 17.7038 15.5 16.875 15.5H3.125C2.2962 15.5 1.50134 15.1708 0.915291 14.5847C0.32924 13.9987 0 13.2038 0 12.375ZM13.625 10.75C13.4592 10.75 13.3003 10.8158 13.1831 10.9331C13.0658 11.0503 13 11.2092 13 11.375C13 11.5408 13.0658 11.6997 13.1831 11.8169C13.3003 11.9342 13.4592 12 13.625 12H16.375C16.5408 12 16.6997 11.9342 16.8169 11.8169C16.9342 11.6997 17 11.5408 17 11.375C17 11.2092 16.9342 11.0503 16.8169 10.9331C16.6997 10.8158 16.5408 10.75 16.375 10.75H13.625Z"
      fill={fill}
    />
  </svg>
);

export default IconPayments;
