import React, { useState } from 'react';

const Button = props => {
  const {
    type,
    href,
    onClick,
    children,
    iconLeft,
    iconRight,
    width = 'auto',
    align = 'center',
    size = 'default',
    level = 'primary',
    underline = false,
    isNightMode = false,
    isFullWidth = false,
    isDisabled = false,
    hasPadding = true,
    bgColor,
    padding
  } = props;
  const [hover, setHover] = useState(false);

  const ButtonLinearStyle = ({ onMouseEnter, onMouseLeave, children }) => (
    <button
      type={type}
      href={href}
      onClick={onClick}
      style={{
        borderRadius: '8px',
        display: 'flex',
        gap: '0.31rem',
        alignItems: 'center',
        justifyContent: 'center',

        margin:
          align === 'center' ? '0 auto' : align === 'left' ? '0 auto 0 0' : '0 0 0 auto',
        width: isFullWidth ? '100%' : width ? `${width}px` : 'auto',
        height: size === 'small' ? '32px' : size === 'default' ? '48px' : '56px',
        cursor: isDisabled === true ? 'no-drop' : 'pointer',
        transition: 'all 0.06 ease',
        fontSize: size === 'small' ? '16px' : size === 'default' ? '21px' : '21px',
        lineHeight: size === 'small' ? '16px' : size === 'default' ? '21px' : '21px',

        padding:
          hasPadding && padding
            ? padding
            : hasPadding && size === 'small'
            ? '0 20px'
            : hasPadding && size === 'default'
            ? '0 30px'
            : hasPadding && size === 'big'
            ? '0 40px'
            : '',
        color: isDisabled === true ? 'rgba(0, 0, 0, 0.3)' : '#000000',
        background:
          hover && isDisabled === true
            ? 'transparent'
            : hover && isDisabled === false
            ? 'rgba(0, 0, 0, 0.1)'
            : !hover
            ? 'transparent'
            : null,
        fontWeight: 500,
        textDecorationLine: underline ? 'underline' : 'none'
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </button>
  );
  const ButtonSecondaryStyle = ({ onMouseEnter, onMouseLeave, children }) => (
    <button
      type={type}
      href={href}
      onClick={onClick}
      style={{
        borderRadius: '8px',
        display: 'flex',
        gap: '0.31rem',
        alignItems: 'center',
        justifyContent: 'center',

        margin:
          align === 'center' ? '0 auto' : align === 'left' ? '0 auto 0 0' : '0 0 0 auto',
        width: isFullWidth ? '100%' : width ? `${width}px` : 'auto',
        height: size === 'small' ? '32px' : size === 'default' ? '48px' : '56px',
        cursor: isDisabled === true ? 'no-drop' : 'pointer',
        transition: 'all 0.06 ease',
        fontSize: size === 'small' ? '16px' : size === 'default' ? '21px' : '21px',
        lineHeight: size === 'small' ? '16px' : size === 'default' ? '21px' : '21px',

        padding:
          hasPadding && padding
            ? padding
            : hasPadding && size === 'small'
            ? '0 20px'
            : hasPadding && size === 'default'
            ? '0 30px'
            : hasPadding && size === 'big'
            ? '0 40px'
            : '',
        color: isNightMode === true ? '#FFFFFF' : '#000000',
        background:
          hover && isDisabled === true
            ? 'transparent'
            : hover && isDisabled === false
            ? 'rgba(0, 0, 0, 0.1)'
            : !hover
            ? 'transparent'
            : null,
        fontWeight: 500,
        textDecorationLine: underline ? 'underline' : 'none',
        border: isNightMode === true ? '2px solid #FFFFFF' : '2px solid #000000'
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </button>
  );
  const ButtonPrimaryStyle = ({ onMouseEnter, onMouseLeave, children }) => (
    <button
      type={type}
      href={href}
      onClick={onClick}
      style={{
        borderRadius: '8px',
        display: 'flex',
        gap: '0.31rem',
        alignItems: 'center',
        justifyContent: 'center',

        margin:
          align === 'center' ? '0 auto' : align === 'left' ? '0 auto 0 0' : '0 0 0 auto',
        width: isFullWidth ? '100%' : width ? `${width}px` : 'auto',
        height: size === 'small' ? '32px' : size === 'default' ? '48px' : '56px',
        cursor: isDisabled === true ? 'no-drop' : 'pointer',
        transition: 'all 0.06 ease',
        fontSize: size === 'small' ? '16px' : size === 'default' ? '21px' : '21px',
        lineHeight: size === 'small' ? '16px' : size === 'default' ? '21px' : '21px',

        padding:
          hasPadding && padding
            ? padding
            : hasPadding && size === 'small'
            ? '0 20px'
            : hasPadding && size === 'default'
            ? '0 30px'
            : hasPadding && size === 'big'
            ? '0 40px'
            : '',
        color: '#FFFFFF',
        background: bgColor
          ? bgColor
          : isDisabled === true
          ? 'rgba(0, 0, 0, 0.5)'
          : hover && isDisabled === false
          ? 'rgba(0, 0, 0, 0.8)'
          : !hover
          ? '#000000'
          : null,
        fontWeight: 500,
        opacity: isDisabled === true ? '0.1' : '1',
        textDecorationLine: underline ? 'underline' : 'none'
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </button>
  );

  return (
    <>
      {level === 'primary' ? (
        <ButtonPrimaryStyle
          type={type}
          href={href}
          onClick={onClick}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {iconLeft}
          {children}
          {iconRight}
        </ButtonPrimaryStyle>
      ) : level === 'linear' ? (
        <ButtonLinearStyle
          type={type}
          href={href}
          onClick={onClick}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {iconLeft}
          {children}
          {iconRight}
        </ButtonLinearStyle>
      ) : level === 'secondary' ? (
        <ButtonSecondaryStyle
          type={type}
          href={href}
          onClick={onClick}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {iconLeft}
          {children}
          {iconRight}
        </ButtonSecondaryStyle>
      ) : (
        ''
      )}
    </>
  );
};

export default Button;
