import cx from 'classnames';
import { useRef, useState } from 'react';
import Link from 'atoms/link';

import DropdownTinyIcon from '#helpers/icons/dropdown-tiny-icon';

const LinkDropdown = ({ title, items }) => {
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const [setRotate, setRotateState] = useState('accordion__icon');

  const content = useRef(null);

  const toggleAccordion = () => {
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`);
    setRotateState(setActive === 'active' ? 'accordion__icon' : 'accordion__icon rotate');
  };

  return (
    <div className={`link_dropdown-Wrapper ${setActive}`} onClick={toggleAccordion}>
      <li className="accordion">
        <p className="accordion_title">{title}</p>
        <DropdownTinyIcon className={`${setRotate}`} />
      </li>

      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className={cx('accordion__content', {['accordion__content--active']: setActive})}
      >
        <div className="link_dropdown-List">
          {items.map((item, idx) => (
            <>
              {item?.redirect ? (
                <Link target="_blank" passHref={true} href={item?.redirect} key={idx}>
                  {item.title}
                </Link>
              ) : (
                <Link href={item?.url} key={idx}>
                  {item.title}
                </Link>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LinkDropdown;
