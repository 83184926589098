import React, { useEffect, useState } from 'react';

import IconArrow from '#app/UI/atoms/icons/IconArrow';
import { Hr, BodyCardCourse } from '#styleguide/index';
import {
  getTimeNumber,
  getTimeZone,
  renderArrayDaySchedule,
  renderRecurringDaysCalendar
} from '#helpers/parsers';
import { renameCoursePerYear } from '#helpers/path-learning';

import HeaderCardCourseProgress from './header-card-course-progress';

const CardFreeCourseProgress = ({
  data,
  colorsCourse,
  type,
  child,
  setObjectCalendar,
  isCourse,
  hasEndedCI
}) => {
  const [isClose, setIsClose] = useState(hasEndedCI);
  useEffect(() => {
    if (isCourse) {
      setObjectCalendar({
        name: `🚀 ${data?.course?.name} - ${data?.student?.full_name} - Crack The Code`,
        description: data.course.description,
        startDate: data?.group?.start_date,
        endDate: data?.group?.start_date,
        startTime: getTimeNumber(data?.group?.frequency[0]?.time_start),
        endTime: getTimeNumber(data?.group?.frequency[0]?.time_end),
        options: ['Apple', 'Google', 'iCal'],
        timeZone: getTimeZone(),
        iCalFileName: data?.course?.name,
        recurrence: `RRULE: FREQ=WEEKLY;INTERVAL=1;BYDAY=${renderRecurringDaysCalendar(
          data?.group?.frequency
        )};COUNT=${data?.course?.number_of_sessions / data?.group?.frequency_numeric}`
      });
    }
  }, [isCourse]);

  return (
    <div
      className="ui-card-free-course-progress"
      style={{ background: colorsCourse?.secondary_color }}
    >
      <HeaderCardCourseProgress
        state={data?.state}
        status={data?.status}
        courseName={renameCoursePerYear(data?.course?.name)}
        orderInListPath={data?.course?.order_in_learning_path}
        progress={data?.progress}
        colorsCourse={colorsCourse}
        type="free"
        isFreeCourse={true}
        startDate={data?.group?.start_date}
        duration={data?.course?.duration}
        numberOfSessions={data?.course?.number_of_sessions}
        frequencyNumber={data?.group?.frequency_numeric}
        hasEnded={hasEndedCI}
      />
      <Hr />
      <section
        className={`ui-card-free-course-progress__${
          isClose ? 'section-close' : 'section-open'
        }`}
      >
        <BodyCardCourse
          zoomUrl={data?.zoom_url}
          thumbnail={data?.course?.thumbnail}
          courseName={renameCoursePerYear(data?.course?.name)}
          status={data.status}
          course={data}
          child={child}
          teacher={data?.teacher || 'No asignado'}
          projectDesc={data?.course?.project_summary}
          groupDayName={renderArrayDaySchedule(data?.group?.frequency)}
          groupTimeStart={data?.group?.frequency[0]?.time_start}
          groupTimeEnd={data?.group?.frequency[0]?.time_end}
          hrefInstructive={data?.course?.instructive}
          hrefCurriculum={data?.course?.curriculum}
        />
      </section>
      {hasEndedCI && (
        <div
          className="ui-card-free-course-progress__container-icon-close"
          onClick={() => setIsClose(!isClose)}
        >
          <IconArrow
            className={`ui-card-free-course-progress__${
              isClose ? 'icon-close' : 'icon-open'
            }`}
          />
        </div>
      )}
    </div>
  );
};

export default CardFreeCourseProgress;
