import { func, string } from 'prop-types';

const Checkbox = ({
 name, checked, register, id, onChange = () => {}, testid 
}) => (
  <input
    data-testid={testid}
    className="checkbox"
    type="checkbox"
    name={name}
    checked={checked}
    onChange={e => onChange(name, e.target.checked)}
    ref={register}
    id={id}
  />
);

Checkbox.propTypes = {
  name: string.isRequired,
  checked: string,
  onChange: func
};

export default Checkbox;
