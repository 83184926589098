export const asksFrecuencyContactUs = [
  {
    ask: '¿Las clases son recuperables?',
    answer:
      'Si el niño/a necesita ayuda adicional con el proceso de las clases, se coordinará con el maestro/a para enviarle material de apoyo audiovisual. Sin embargo, las clases no son recuperables'
  },
  {
    ask: '¿Las clases son grabadas?',
    answer:
      'Las clases son en vivo. Todas las clases son grabadas para tener registro de ellas, sin embargo no pueden ser compartidas debido a las políticas de privacidad.'
  }
];

export const asksFrecuencyContactUsDashboard = [
  {
    ask: 'Leer Acuerdos de clase',
    moreInfo: 'https://web.crackthecode.la/acuerdos-de-clase'
  },
  {
    ask: '¿Las clases son recuperables?',
    answer:
      'Si el niño/a necesita ayuda adicional con el proceso de las clases, se coordinará con el maestro/a para enviarle material de apoyo audiovisual. Sin embargo, las clases no son recuperables'
  },
  {
    ask: '¿Las clases son grabadas?',
    answer:
      'Las clases son en vivo. Todas las clases son grabadas para tener registro de ellas, sin embargo no pueden ser compartidas debido a las políticas de privacidad.'
  },
  {
    ask: '¿Qué pasa después de inscribir a mi hij@?',
    answer:
      'Una vez realizada la inscripción recibirá un correo en el que encontrará todos los pasos a seguir, incluyendo los tutoriales con las descargas necesarias para cada curso.'
  }
];
