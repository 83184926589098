/* eslint-disable react/prop-types */
import dynamic from 'next/dynamic';

import { ButtonComponentVacation } from '#atoms/index';

const Program1Icon = dynamic(() =>
  import('#helpers/icons/vacation-icons').then(module => module.Program1Icon)
);

const Program2Icon = dynamic(() =>
  import('#helpers/icons/vacation-icons').then(module => module.Program2Icon)
);

const ProgramPlusIcon = dynamic(() =>
  import('#helpers/icons/vacation-icons').then(module => module.ProgramPlusIcon)
);

const CheckIcon = dynamic(() =>
  import('#helpers/icons/vacation-icons').then(module => module.CheckIcon)
);

const CardPaymentVacation = props => {
  const {
    mainColor,
    secondaryColor,
    index,
    name,
    courses,
    frequency,
    program,
    setProgram,
    ageRange
  } = props;
  const comercialName = name?.split(',');
  const coloGray = '#F0F0F0';
  const renderIcon = currentPosition => {
    switch (currentPosition) {
      case 0:
        return <Program1Icon fill={program === index ? secondaryColor : '#000'} />;
      case 1:
        return <Program2Icon fill={program === index ? secondaryColor : '#000'} />;
      default:
        return <ProgramPlusIcon fill={program === index ? secondaryColor : '#000'} />;
    }
  };
  const handleChange = (e, index) => {
    e.preventDefault();
    setProgram(index);
  };
  let qty_courses =
    courses.length > 1
      ? courses[0]?.modules.concat(courses[1].modules)
      : courses[0]?.modules;

  return (
    <div className="card-payment-vacation">
      <div
        className={`card-payment-vacation__container card-payment-vacation__container--hover-${ageRange}`}
        style={{
          transition: 'all 0.6s ease',
          background: program === index ? mainColor : '#FFF',
          border:
            program === index ? `2px solid ${secondaryColor}` : `2px solid ${coloGray}`
        }}
      >
        {index === 2 && (
          <div
            style={{ background: mainColor, color: secondaryColor }}
            className="card-payment-vacation__tooltip"
          >
            <p className="card-payment-vacation__tooltip--bold">Recomendado</p>
          </div>
        )}
        <p className="card-payment-vacation__title">
          {renderIcon(index)}
          <span
            className="card-payment-vacation__title--bold"
            style={{ color: program === index ? secondaryColor : '#000' }}
          >
            {comercialName ? comercialName[0] : ''}
          </span>
        </p>
        <hr
          className="card-payment-vacation__hr"
          style={{
            border:
              program === index ? `1px solid ${secondaryColor}` : `1px solid ${coloGray}`
          }}
        />
        <div>
          <p className="card-payment-vacation__text">
            Contiene {qty_courses?.length}{' '}
            {courses ? (
              <span className="card-payment-vacation__text--bold">
                {qty_courses?.length === 1 ? 'curso' : 'cursos'}
              </span>
            ) : (
              ''
            )}
          </p>
          {courses ? (
            <ul className="card-payment-vacation__container-modules">
              {courses?.length === 1 ? (
                courses[0]?.modules?.map((course, idx) => (
                  <li key={idx} className="card-payment-vacation__modules">
                    <span>
                      <CheckIcon fill={program === index ? secondaryColor : coloGray} />
                    </span>
                    {course?.name}
                  </li>
                ))
              ) : (
                <>
                  {courses[0]?.modules?.map((course, idx) => (
                    <li key={idx} className="card-payment-vacation__modules">
                      <span className="card-payment-vacation__icon-modules">
                        <CheckIcon fill={program === index ? secondaryColor : coloGray} />
                      </span>
                      {course?.name}
                    </li>
                  ))}
                  {courses[1]?.modules?.map((course, idx) => (
                    <li key={idx} className="card-payment-vacation__modules">
                      <span className="card-payment-vacation__icon-modules">
                        <CheckIcon fill={program === index ? secondaryColor : coloGray} />
                      </span>
                      {course?.name}
                    </li>
                  ))}
                </>
              )}
            </ul>
          ) : (
            <p>Sin cursos</p>
          )}
          {index === 2 ? (
            <>
              <p className="card-payment-vacation__text">
                <span className="card-payment-vacation__text--bold">
                  4 días a la semana{' '}
                </span>
                del 4 al 31 de enero
              </p>
            </>
          ) : (
            <>
              {frequency > 1 && (
                <p className="card-payment-vacation__text">
                  <span className="card-payment-vacation__text--bold">
                    {frequency > 1
                      ? `${frequency} días a la semana `
                      : `${frequency} día a la semana `}
                  </span>
                  {index === 0 ? 'del 2 al 28 de Febrero' : '1 al 24 de Febrero'}
                </p>
              )}
            </>
          )}
        </div>
      </div>
      <div className="card-payment-vacation__container-button">
        <ButtonComponentVacation
          description={comercialName ? comercialName[0] : ''}
          background={program === index ? mainColor : coloGray}
          textColor={program === index ? secondaryColor : '#000'}
          variantButton="containedColorAlternative"
          hoverClassColor={ageRange}
          boxShadow={false}
          width="100%"
          download={false}
          external={false}
          onClick={e => handleChange(e, index)}
        />
      </div>
    </div>
  );
};

export default CardPaymentVacation;
