import { node, string } from 'prop-types';
import cx from 'classnames';

const Tag = ({ children, color }) => (
  <p className={cx('tag', { [`tag--color-${color}`]: color })}>{children}</p>
);

Tag.propTypes = {
  children: node.isRequired,
  color: string
};

export default Tag;
