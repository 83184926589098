const IconShoppingCart = ({ fill, className }) => (
  <svg
    className={className}
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 18.5C5.9 18.5 5.01 19.4 5.01 20.5C5.01 21.6 5.9 22.5 7 22.5C8.1 22.5 9 21.6 9 20.5C9 19.4 8.1 18.5 7 18.5ZM1 2.5V4.5H3L6.6 12.09L5.25 14.54C5.09 14.82 5 15.15 5 15.5C5 16.6 5.9 17.5 7 17.5H19V15.5H7.42C7.28 15.5 7.17 15.39 7.17 15.25L7.2 15.13L8.1 13.5H15.55C16.3 13.5 16.96 13.09 17.3 12.47L20.88 5.98C20.9625 5.82739 21.004 5.65598 21.0005 5.48253C20.9969 5.30908 20.9485 5.13951 20.8598 4.99039C20.7711 4.84127 20.6453 4.7177 20.4946 4.63175C20.3439 4.54579 20.1735 4.5004 20 4.5H5.21L4.27 2.5H1ZM17 18.5C15.9 18.5 15.01 19.4 15.01 20.5C15.01 21.6 15.9 22.5 17 22.5C18.1 22.5 19 21.6 19 20.5C19 19.4 18.1 18.5 17 18.5Z"
      fill={fill}
    />
  </svg>
);

export default IconShoppingCart;
