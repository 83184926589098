import React from 'react';

import {
  Hr,
  BodyCardCourse,
  HeaderCardCourseProgress,
  CardCertificateContent,
  CardCertificateDetails
} from '#styleguide/index';
import { renderArrayDaySchedule } from '#helpers/parsers';
import { renameCoursePerYear } from '#helpers/path-learning';

import CardPathCourseBlocked from './card-path-course-blocked';
import CardCertificateBlocked from './card-certificate-blocked';

const CardPathCourseProgress = ({
  data,
  colorsCourse,
  currentCourse,
  hasEnded,
  numberLastElement,
  childName,
  child,
  hasCertificatePath,
  pathDetails
}) => {
  let transform = `translate(calc(${-100 * currentCourse}% - ${
    currentCourse * 16
  }px) ,0)`;

  return (
    <>
      <div
        className="ui-card-path-course-progress"
        style={{
          background:
            data?.status === 'has_not_started'
              ? '#F9F8F9'
              : hasEnded
              ? '#F6F6F6'
              : colorsCourse?.secondary_color,
          transform: transform
        }}
      >
        {currentCourse === numberLastElement ? (
          ''
        ) : (
          <CardPathCourseBlocked
            data={data}
            status={data?.status}
            childName={childName}
          />
        )}
        {currentCourse === numberLastElement ? (
          <>
            {hasCertificatePath ? (
              <>
                <CardCertificateContent
                  hasCertificatePath={hasCertificatePath}
                  childName={childName}
                  child={child}
                  path={pathDetails}
                />
                <CardCertificateDetails
                  learning_path_next={pathDetails.learning_path_next}
                  colorsCourse={colorsCourse}
                />
              </>
            ) : (
              <CardCertificateBlocked
                hasCertificatePath={hasCertificatePath}
                childName={childName}
                child={child}
                path={pathDetails}
              />
            )}
          </>
        ) : (
          <>
            <HeaderCardCourseProgress
              state={data?.state} //TODO: Change --> It's a test
              status={data?.status}
              courseName={renameCoursePerYear(data?.course?.name)}
              orderInListPath={data?.course?.order_in_learning_path}
              progress={data?.progress}
              colorsCourse={colorsCourse}
              startDate={data?.group?.start_date}
              duration={data?.course?.duration}
              numberOfSessions={data?.course?.number_of_sessions}
              frequencyNumber={data?.group?.frequency_numeric}
              hasEnded={hasEnded}
            />
            <Hr />
            <BodyCardCourse
              zoomUrl={data?.zoom_url}
              thumbnail={data?.course?.thumbnail}
              courseName={renameCoursePerYear(data?.course?.name)}
              status={data.status}
              child={child}
              course={data}
              teacher={data?.teacher || 'No asignado'}
              projectDesc={data?.course?.project_summary}
              groupDayName={renderArrayDaySchedule(data?.group?.frequency)}
              groupTimeStart={data?.group?.frequency[0]?.time_start}
              groupTimeEnd={data?.group?.frequency[0]?.time_end}
              hrefInstructive={data?.course?.instructive}
              hrefCurriculum={data?.course?.curriculum}
            />
          </>
        )}
      </div>
    </>
  );
};

export default CardPathCourseProgress;
