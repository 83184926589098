/* eslint-disable react/prop-types */
/* import { ButtonComponentVacation } from '#atoms/index'; */
import {
 Button, H3, IconArrowRight, Paragraph 
} from '#styleguide/index';

const AgeRangePrograms = ({
 dataPrograms, setIdButton, myRef, myRef1, myRef2 
}) => {
  const ItemAgeRange = ({
    mainColor,
    supportColor,
    name,
    setIdButton,
    id,
    myRef,
    myRef1,
    myRef2,
    ageRange
  }) => {
    const executeScroll = (e, id) => {
      e.preventDefault();
      setIdButton(id);
      if (id === 0) {
        myRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
      } else if (id === 1) {
        myRef1.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
      } else {
        myRef2.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    };

    return (
      <div
        className={`item-range-age item-range-age--hover-${ageRange}`}
        style={{ background: mainColor }}
        onClick={e => executeScroll(e, id)}
      >
        <div>
          <H3 align="center" color={supportColor}>
            {name}
          </H3>
          <Paragraph align="center" size="16" color={supportColor}>
            años de edad
          </Paragraph>
        </div>
        <Button
          level="linear"
          width="114"
          hasPadding={false}
          iconRight={<IconArrowRight />}
          onClick={e => executeScroll(e, id)}
        >
          <Paragraph align="center" size="16">
            Ver cursos
          </Paragraph>
        </Button>
      </div>
    );
  };

  return (
    <div className="age-range-programs">
      {dataPrograms.map((program, i) => (
        <ItemAgeRange
          key={i}
          mainColor={program?.main_color && program?.main_color}
          secondaryColor={program?.secondary_color && program?.secondary_color}
          supportColor={program?.support_color && program?.support_color}
          name={program?.name && program?.name}
          setIdButton={setIdButton}
          id={i}
          myRef={myRef}
          myRef1={myRef1}
          myRef2={myRef2}
          ageRange={program?.age_range}
        />
      ))}
    </div>
  );
};

export default AgeRangePrograms;
