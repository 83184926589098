import React from 'react';

import { useUserContext } from '#hooks/index';
import { Step, IconArrowRightSimple } from '#styleguide/index';
import { event } from '#helpers/gtag';
import { renameCoursePerYear } from '#helpers/path-learning';

const Stepper = ({
  dataStepper,
  currentTab,
  currentCourse,
  setCurrentCourse,
  fill,
  childName,
  quantitySteps,
  setObjectCalendar,
  fullInfoCurrentCourse,
  hasEnded,
  setCalendarRecurrence,
  setCalendarSplitRecurrence,
  setCalendarOptions
}) => {
  const { data: userData } = useUserContext();

  const handleGetCertificateStepper = () => {
    setCurrentCourse(dataStepper.length);
    event(
      'linea-de-tiempo-cursos',
      'zona-padres',
      'clic en linea de tiempo en zona de padres'
    );
    event(
      `linea-de-tiempo-cursos: ${userData.email}`,
      'zona-padres',
      'clic en linea de tiempo en zona de padres'
    );
  };
  const handleGetIdxCourse = idx => {
    setCurrentCourse(idx);
    event(
      'linea-de-tiempo-cursos',
      'zona-padres',
      'clic en linea de tiempo en zona de padres'
    );
    event(
      `linea-de-tiempo-cursos: ${userData.email}`,
      'zona-padres',
      'clic en linea de tiempo en zona de padres'
    );
  };

  const handleDecreaseStep = () => {
    if (currentCourse === 0) {
      setCurrentCourse(quantitySteps);
    } else {
      setCurrentCourse(currentCourse - 1);
    }
  };
  const handleIncreaseStep = () => {
    if (currentCourse === quantitySteps) {
      setCurrentCourse(0);
    } else {
      setCurrentCourse(currentCourse + 1);
    }
  };

  return (
    <div className="ui-stepper">
      <div className="ui-stepper__circle-arrow" onClick={handleDecreaseStep}>
        <IconArrowRightSimple fill="#fff" />
      </div>
      <div className="ui-stepper__container-step">
        {dataStepper && dataStepper.length > 0
          ? dataStepper.map((step, idx) => (
              <Step
                setCalendarSplitRecurrence={setCalendarSplitRecurrence}
                setCalendarRecurrence={setCalendarRecurrence}
                setCalendarOptions={setCalendarOptions}
                key={idx}
                name={renameCoursePerYear(step.course.name)}
                currentTab={currentTab}
                number={step.course.order_in_learning_path}
                currentCourse={currentCourse}
                setCurrentCourse={setCurrentCourse}
                index={idx}
                /* isHere={step.status === 'in_progress'} */
                isHere={currentCourse === idx}
                isComplete={step.status === 'has_ended'}
                onClick={() => handleGetIdxCourse(idx)}
                fill={fill}
                status={step.status}
                childName={childName}
                dataCourses={dataStepper}
                length={dataStepper.length}
                coursesEnded={dataStepper.filter(step => step.status === 'has_ended')}
                setObjectCalendar={setObjectCalendar}
                fullInfoCurrentCourse={fullInfoCurrentCourse}
                hasEnded={hasEnded}
              />
            ))
          : null}
        {dataStepper && dataStepper.length > 0 ? (
          <Step
            setCalendarSplitRecurrence={setCalendarSplitRecurrence}
            setCalendarRecurrence={setCalendarRecurrence}
            setCalendarOptions={setCalendarOptions}
            name="Certificado Final"
            number="Obtener"
            isLastChild={true}
            isHere={currentCourse === dataStepper.length}
            onClick={handleGetCertificateStepper}
            status={dataStepper[dataStepper.length - 1]?.status}
            isStarShape={true}
            index={dataStepper.length}
            length={dataStepper.length}
            fill={fill}
            childName={childName}
            dataCourses={dataStepper}
            currentCourse={currentCourse}
            setCurrentCourse={setCurrentCourse}
            coursesEnded={dataStepper.filter(step => step.status === 'has_ended')}
            setObjectCalendar={setObjectCalendar}
            fullInfoCurrentCourse={fullInfoCurrentCourse}
            hasEnded={hasEnded}
          />
        ) : null}
      </div>
      <div
        className="ui-stepper__circle-arrow ui-stepper__circle-arrow--left"
        onClick={handleIncreaseStep}
      >
        <IconArrowRightSimple fill="#fff" />
      </div>
    </div>
  );
};

export default Stepper;
