import { shape, string } from 'prop-types';

import { Typography } from '#atoms/index';

const FailureMessage = ({ transactionData = {}, purchaseNumber }) => {
  const weirdDateFormat =
    transactionData.text && String(transactionData.text.data.TRANSACTION_DATE);
  const dateParsed =
    transactionData.text &&
    `${weirdDateFormat[4]}${weirdDateFormat[5]}/${weirdDateFormat[2]}${weirdDateFormat[3]}/${weirdDateFormat[0]}${weirdDateFormat[1]} 
    ${weirdDateFormat[6]}${weirdDateFormat[7]}:${weirdDateFormat[8]}${weirdDateFormat[9]}:${weirdDateFormat[10]}${weirdDateFormat[11]}`;

  return (
    <section className="niubiz-message">
      <Typography variant="h2">¡Operación denegada! :(</Typography>

      <div className="__inline-info">
        <Typography variant="body">Número de pedido:</Typography>
        <Typography variant="h3">{purchaseNumber}</Typography>
      </div>
      <div className="__inline-info">
        <Typography variant="body">Fecha y hora del pedido:</Typography>
        <Typography variant="h3">{dateParsed}</Typography>
      </div>
      <div>
        <Typography variant="body">Descripción de la denegación:</Typography>
        {transactionData.text.data.ACTION_CODE === '180' && (
          <Typography variant="h3">
            La tarjeta ingresada es inválida. Por favor, comunícate con tu banco para
            mayor información.
          </Typography>
        )}
        {transactionData.text.data.ACTION_CODE !== '180' && (
          <Typography variant="h3">
            Por favor, comunícate con tu banco para mayor información.
          </Typography>
        )}
      </div>
    </section>
  );
};

FailureMessage.propTypes = {
  transactionData: shape(),
  purchaseNumber: string
};

export default FailureMessage;
