import cx from 'classnames';

import styles from './index.module.scss';

const Typography = props => {
  const {
 tag, className, children, dataTestid = 'typography-updated', ...rest 
} = props;

  switch (tag) {
    case 'h1':
      return (
        <h1
          data-testid={`${dataTestid}-h1`}
          className={cx(styles.h1, className)}
          {...rest}
        >
          {children}
        </h1>
      );
    case 'h2':
      return (
        <h2
          data-testid={`${dataTestid}-h2`}
          className={cx(styles.h2, className)}
          {...rest}
        >
          {children}
        </h2>
      );
    case 'h3':
      return (
        <h3
          data-testid={`${dataTestid}-h3`}
          className={cx(styles.h3, className)}
          {...rest}
        >
          {children}
        </h3>
      );
    case 'h4':
      return (
        <h4
          data-testid={`${dataTestid}-h4`}
          className={cx(styles.h4, className)}
          {...rest}
        >
          {children}
        </h4>
      );
    case 'body-big':
      return (
        <p
          data-testid={`${dataTestid}-body-big`}
          className={cx(styles.body_big, className)}
          {...rest}
        >
          {children}
        </p>
      );
    case 'body-small':
      return (
        <p
          data-testid={`${dataTestid}-body-small`}
          className={cx(styles.body_small, className)}
          {...rest}
        >
          {children}
        </p>
      );
    case 'body-extra-small':
      return (
        <p
          data-testid={`${dataTestid}-body-extra-small`}
          className={cx(styles.body_extra_small, className)}
          {...rest}
        >
          {children}
        </p>
      );
    case 'tags':
      return (
        <span
          data-testid={`${dataTestid}-tags`}
          className={cx(styles.tags, className)}
          {...rest}
        >
          {children}
        </span>
      );
    default:
      return (
        <span
          data-testid={`${dataTestid}-default`}
          className={cx(styles.span, className)}
          {...rest}
        >
          {children}
        </span>
      );
  }
};

export default Typography;
