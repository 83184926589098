const ourValues = [
  {
    name: 'Curiosidad',
    description:
      'Creamos un espacio que incentive la curiosidad intelectual entre nuestros estudiantes.',
    image: '/about-us/curiosity.webp'
  },
  {
    name: 'Innovación',
    description:
      'Somos una empresa que busca transformar vidas a través de la educación y el uso de tecnología.',
    image: '/about-us/innovation.webp'
  },
  {
    name: 'Revolución educativa',
    description:
      'Buscamos transformar la forma en la que se enseña, dando herramientas para el futuro. ',
    image: '/about-us/educational-revolution.webp'
  }
];
const OurValues = () => {
  return (
    <div className="our-values">
      <h2 className="our-values__title our-values__title--bold">Nuestros Valores</h2>
      <div className="our-values__container-item">
        {ourValues.map((value, idx) => (
          <section key={idx} className="our-values__item">
            <img
              className="our-values__item-image"
              src={value.image}
              alt={`${value.name} | Crack The Code`}
            />
            <div className="our-values__item-details">
              <h3 className="our-values__item-title our-values__item-title--bold">
                {value.name}
              </h3>
              <p className="our-values__item-description">{value.description}</p>
            </div>
          </section>
        ))}
      </div>
    </div>
  );
};

export default OurValues;
