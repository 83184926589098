const IconCircleStep = ({
 status = 'has_not_started', fill, className, hasEnded 
}) => (
  <svg
    className={className}
    width={status === 'has_ended' || status === 'in_progress' ? '16' : '24'}
    height={status === 'has_ended' || status === 'in_progress' ? '16' : '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="12"
      r="12"
      fill={status === 'has_ended' || status === 'in_progress' ? fill : '#DDDDDD'}
    />
    <path
      d="M8.40001 16.8C8.07001 16.8 7.78741 16.6992 7.55221 16.4976C7.31701 16.296 7.19961 16.0539 7.20001 15.7714V10.6286C7.20001 10.3457 7.31761 10.1035 7.55281 9.90189C7.78801 9.70029 8.07041 9.59966 8.40001 9.6H9.00001V8.57143C9.00001 7.86 9.29261 7.25349 9.87781 6.75189C10.463 6.25029 11.1704 5.99966 12 6C12.83 6 13.5376 6.2508 14.1228 6.7524C14.708 7.254 15.0004 7.86034 15 8.57143V9.6H15.6C15.93 9.6 16.2126 9.7008 16.4478 9.9024C16.683 10.104 16.8004 10.3461 16.8 10.6286V15.7714C16.8 16.0543 16.6824 16.2965 16.4472 16.4981C16.212 16.6997 15.9296 16.8003 15.6 16.8H8.40001ZM12 14.2286C12.33 14.2286 12.6126 14.1278 12.8478 13.9262C13.083 13.7246 13.2004 13.4825 13.2 13.2C13.2 12.9171 13.0824 12.6749 12.8472 12.4733C12.612 12.2717 12.3296 12.1711 12 12.1714C11.67 12.1714 11.3874 12.2722 11.1522 12.4738C10.917 12.6754 10.7996 12.9175 10.8 13.2C10.8 13.4829 10.9176 13.7251 11.1528 13.9267C11.388 14.1283 11.6704 14.2289 12 14.2286ZM10.2 9.6H13.8V8.57143C13.8 8.14286 13.625 7.77857 13.275 7.47857C12.925 7.17857 12.5 7.02857 12 7.02857C11.5 7.02857 11.075 7.17857 10.725 7.47857C10.375 7.77857 10.2 8.14286 10.2 8.57143V9.6Z"
      fill={status === 'has_ended' || status === 'in_progress' ? fill : '#555555'}
    />
    <path
      d="M8 13.001L9.95106 15.001L15.9511 9"
      stroke={
        status === 'has_ended'
          ? hasEnded
            ? fill
            : '#FFFFFF'
          : status === 'in_progress'
          ? fill
          : '#555555'
      }
      strokeOpacity={status === 'has_ended' ? '1' : status === 'in_progress' ? '1' : '0'}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default IconCircleStep;
