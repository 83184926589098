import { func, string } from 'prop-types';

const TextareaField = ({
  name,
  value,
  placeholder,
  register,
  isRequired,
  variant,
  onChange
}) => {
  return (
    <textarea
      className={variant ? `textarea-${variant}` : 'textarea'}
      name={name}
      value={value}
      placeholder={placeholder}
      ref={register}
      required={isRequired ? true : false}
      onChange={onChange}
    />
  );
};

TextareaField.propTypes = {
  name: string,
  value: string,
  placeholder: string,
  register: func,
  variant: string
};

export default TextareaField;
