import React from 'react';

const H2 = ({
 children, align, color = '#000000', weight = '700' 
}) => {
  return (
    <h2
      className="typography-h2"
      style={{ textAlign: align, color: color, fontWeight: weight }}
    >
      {children}
    </h2>
  );
};

export default H2;
