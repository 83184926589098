export default [
  { value: 'PE', label: 'Perú' },
  { value: 'AF', label: 'Afganistán' },
  { value: 'AL', label: 'Albania' },
  { value: 'DE', label: 'Alemania' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AO', label: 'Angola' },
  { value: 'AI', label: 'Anguila' },
  { value: 'AQ', label: 'Antártida' },
  { value: 'AG', label: 'Antigua y Barbuda' },
  { value: 'SA', label: 'Arabia Saudí' },
  { value: 'DZ', label: 'Argelia' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AM', label: 'Armenia' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AU', label: 'Australia' },
  { value: 'AT', label: 'Austria' },
  { value: 'AZ', label: 'Azerbaiyán' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BD', label: 'Bangladés' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BH', label: 'Baréin' },
  { value: 'BE', label: 'Bélgica' },
  { value: 'BZ', label: 'Belice' },
  { value: 'BJ', label: 'Benín' },
  { value: 'BM', label: 'Bermudas' },
  { value: 'BY', label: 'Bielorrusia' },
  { value: 'BO', label: 'Bolivia' },
  { value: 'BQ', label: 'Bonaire, San Eustaquio y Saba' },
  { value: 'BA', label: 'Bosnia y Herzegovina' },
  { value: 'BW', label: 'Botsuana' },
  { value: 'BR', label: 'Brasil' },
  { value: 'BN', label: 'Brunéi Darusalam' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'BT', label: 'Bután' },
  { value: 'CV', label: 'Cabo Verde' },
  { value: 'KH', label: 'Camboya' },
  { value: 'CM', label: 'Camerún' },
  { value: 'CA', label: 'Canadá' },
  { value: 'TD', label: 'Chad' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China' },
  { value: 'CY', label: 'Chipre' },
  { value: 'CO', label: 'Colombia' },
  { value: 'KM', label: 'Comoras' },
  { value: 'CG', label: 'Congo' },
  { value: 'CD', label: 'República Democrática del Congo' },
  { value: 'KR', label: 'República de Corea' },
  { value: 'KP', label: 'República Popular Democrática de Corea' },
  { value: 'CI', label: 'Costa de Marfil' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'HR', label: 'Croacia' },
  { value: 'CU', label: 'Cuba' },
  { value: 'CW', label: 'Curazao' },
  { value: 'DK', label: 'Dinamarca' },
  { value: 'DM', label: 'Dominica' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EG', label: 'Egipto' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'AE', label: 'Emiratos Árabes Unidos' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'SK', label: 'Eslovaquia' },
  { value: 'SI', label: 'Eslovenia' },
  { value: 'ES', label: 'España' },
  { value: 'US', label: 'Estados Unidos de América' },
  { value: 'EE', label: 'Estonia' },
  { value: 'ET', label: 'Etiopía' },
  { value: 'RU', label: 'Federación de Rusia' },
  { value: 'PH', label: 'Filipinas' },
  { value: 'FI', label: 'Finlandia' },
  { value: 'FJ', label: 'Fiyi' },
  { value: 'FR', label: 'Francia' },
  { value: 'GA', label: 'Gabón' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GE', label: 'Georgia' },
  { value: 'GS', label: 'Georgia del Sur e Islas Sandwich del Sur' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GD', label: 'Granada' },
  { value: 'GR', label: 'Grecia' },
  { value: 'GL', label: 'Groenlandia' },
  { value: 'GP', label: 'Guadalupe' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GF', label: 'Guayana Francesa' },
  { value: 'GG', label: 'Guernesey' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GQ', label: 'Guinea Ecuatorial' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haití' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HU', label: 'Hungría' },
  { value: 'IN', label: 'India' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'IR', label: 'República Islámica del Irán' },
  { value: 'IQ', label: 'Iraq' },
  { value: 'IE', label: 'Irlanda' },
  { value: 'BV', label: 'Isla Bouvet' },
  { value: 'CX', label: 'Isla Christmas' },
  { value: 'IM', label: 'Isla de Man' },
  { value: 'IS', label: 'Islandia' },
  { value: 'NU', label: 'Isla Niue' },
  { value: 'NF', label: 'Isla Norfolk' },
  { value: 'PN', label: 'Isla Pitcairn' },
  { value: 'AX', label: 'Islas Åland' },
  { value: 'KY', label: 'Islas Caimán' },
  { value: 'CC', label: 'Islas Cocos (Keeling)' },
  { value: 'CK', label: 'Islas Cook' },
  { value: 'FO', label: 'Islas Feroe' },
  { value: 'HB', label: 'Islas Heard y McDonald' },
  { value: 'FK', label: 'Islas Malvinas (Falkland)' },
  { value: 'SB', label: 'Islas Salomón' },
  { value: 'SJ', label: 'Islas Svalbard y Jan Mayen' },
  { value: 'TC', label: 'Islas Turcas y Caicos' },
  { value: 'VG', label: 'Islas Vírgenes Británicas' },
  { value: 'WF', label: 'Islas Wallis y Futuna' },
  { value: 'IL', label: 'Israel' },
  { value: 'IT', label: 'Italia' },
  { value: 'JM', label: 'Jamaica' },
  { value: 'JP', label: 'Japón' },
  { value: 'JE', label: 'Jersey' },
  { value: 'JO', label: 'Jordania' },
  { value: 'KZ', label: 'Kazajistán' },
  { value: 'KE', label: 'Kenia' },
  { value: 'KG', label: 'Kirguistán' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'LA', label: 'República Democrática Popular de Laos' },
  { value: 'LS', label: 'Lesoto' },
  { value: 'LV', label: 'Letonia' },
  { value: 'LB', label: 'Líbano' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Lituania' },
  { value: 'LU', label: 'Luxemburgo' },
  { value: 'MO', label: 'Macao' },
  { value: 'MK', label: 'Antigua República Yugoslava de Macedonia' },
  { value: 'MG', label: 'Madagascar' },
  { value: 'MY', label: 'Malasia' },
  { value: 'MW', label: 'Malaui' },
  { value: 'MV', label: 'Maldivas' },
  { value: 'ML', label: 'Mali' },
  { value: 'MT', label: 'Malta' },
  { value: 'MA', label: 'Marruecos' },
  { value: 'MQ', label: 'Martinica' },
  { value: 'MU', label: 'Mauricio' },
  { value: 'MR', label: 'Mauritania' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'MX', label: 'México' },
  { value: 'MD', label: 'República de Moldavia' },
  { value: 'MC', label: 'Mónaco' },
  { value: 'MN', label: 'Mongolia' },
  { value: 'ME', label: 'Montenegro' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MZ', label: 'Mozambique' },
  { value: 'MM', label: 'Myanmar' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NE', label: 'Níger' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NO', label: 'Noruega' },
  { value: 'NC', label: 'Nueva Caledonia' },
  { value: 'NZ', label: 'Nueva Zelanda' },
  { value: 'OM', label: 'Omán' },
  { value: 'NL', label: 'Países Bajos' },
  { value: 'PK', label: 'Pakistán' },
  { value: 'PS', label: 'Palestina' },
  { value: 'PA', label: 'Panamá' },
  { value: 'PG', label: 'Papúa Nueva Guinea' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PF', label: 'Polinesia Francesa' },
  { value: 'PL', label: 'Polonia' },
  { value: 'PT', label: 'Portugal' },
  { value: 'QA', label: 'Qatar' },
  { value: 'GB', label: 'Reino Unido' },
  { value: 'SY', label: 'República Árabe Siria' },
  { value: 'CF', label: 'República Centroafricana' },
  { value: 'CZ', label: 'República Checa' },
  { value: 'DO', label: 'República Dominicana' },
  { value: 'RE', label: 'Reunión' },
  { value: 'RW', label: 'Ruanda' },
  { value: 'RO', label: 'Rumanía' },
  { value: 'EH', label: 'Sáhara Occidental' },
  { value: 'KN', label: 'Saint Kitts y Nevis' },
  { value: 'WS', label: 'Samoa' },
  { value: 'SM', label: 'San Marino' },
  { value: 'MF', label: 'San Martín (parte francesa)' },
  { value: 'SX', label: 'San Martín (parte holandesa)' },
  { value: 'PM', label: 'San Pedro y Miquelón' },
  { value: 'SH', label: 'Santa Elena, Ascensión y Tristán da Cunha' },
  { value: 'LC', label: 'Santa Lucía' },
  { value: 'ST', label: 'Santo Tomé y Príncipe' },
  { value: 'VC', label: 'San Vicente y las Granadinas' },
  { value: 'SN', label: 'Senegal' },
  { value: 'RS', label: 'Serbia' },
  { value: 'SC', label: 'Seychelles' },
  { value: 'SL', label: 'Sierra Leona' },
  { value: 'SG', label: 'Singapur' },
  { value: 'SO', label: 'Somalia' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'SZ', label: 'Suazilandia' },
  { value: 'ZA', label: 'Sudáfrica' },
  { value: 'SD', label: 'Sudán' },
  { value: 'SS', label: 'Sudán del Sur' },
  { value: 'SE', label: 'Suecia' },
  { value: 'CH', label: 'Suiza' },
  { value: 'SR', label: 'Surinam' },
  { value: 'TH', label: 'Tailandia' },
  { value: 'TW', label: 'Taiwán' },
  { value: 'TZ', label: 'Tanzania, República Unida de' },
  { value: 'TJ', label: 'Tayikistán' },
  { value: 'IO', label: 'Territorio Británico del Océano Índico' },
  { value: 'TF', label: 'Territorios Australes Franceses' },
  { value: 'TL', label: 'Timor Oriental' },
  { value: 'TG', label: 'Togo' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TT', label: 'Trinidad y Tobago' },
  { value: 'TN', label: 'Túnez' },
  { value: 'TM', label: 'Turkmenistán' },
  { value: 'TR', label: 'Turquía' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'UA', label: 'Ucrania' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Uzbekistán' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VE', label: 'República Bolivariana de Venezuela' },
  { value: 'VN', label: 'Vietnam' },
  { value: 'YE', label: 'Yemen' },
  { value: 'DJ', label: 'Yibuti' },
  { value: 'ZM', label: 'Zambia' },
  { value: 'ZW', label: 'Zimbabue' }
];
