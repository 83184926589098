import axios from 'axios';

import { handleInvalidToken } from './user';
import { baseUrl } from './baseUrl';

export const getPurchaseNumber = async merchantId => {
  const response = await fetch(`${baseUrl}/api/purchases/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}`
    },
    body: JSON.stringify({ MERCHANT_ID: merchantId })
  });
  response.status === 403 && handleInvalidToken();
  return response.json();
};

export const getToken = async merchantId => {
  const response = await fetch(`${baseUrl}/api/niubiz/token/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}`
    },
    body: JSON.stringify({ MERCHANT_ID: merchantId })
  });
  return response.json();
};

export const getSessionKey = async (token, amount, merchantId) => {
  const response = await fetch(`${baseUrl}/api/niubiz/session/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}`
    },
    body: JSON.stringify({
      MERCHANT_ID: merchantId,
      amount,
      token
    })
  });
  return response.json();
};

export const postTransaction = async ({
  merchantId,
  amount,
  purchaseNumber,
  transactionToken,
  token
}) => {
  const response = await fetch(`${baseUrl}/api/niubiz/authorization/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}`
    },
    body: JSON.stringify({
      MERCHANT_ID: merchantId,
      amount,
      purchase_number: purchaseNumber,
      transaction_token: transactionToken,
      token
    })
  });
  return response.json();
};

export const patchPurchaseNumber = async (purchaseNumber, data) => {
  const response = await fetch(`${baseUrl}/api/purchases/${purchaseNumber}/`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}`
    },
    body: JSON.stringify(data)
  });
  return response.json();
};

//* Endpoints to new purchase flow
export const getInvoices = async data => {
  const response = await fetch(`${baseUrl}/api/invoices/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}`
    },
    body: JSON.stringify(data)
  });
  response.status === 403 && console.log('Problemas con el request');
  return response.json();
};

//* Dlocal --> Perú y México
export const payIntentDlocal = async data => {
  const response = await fetch(`${baseUrl}/api/dlocal/payment-information/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}`
    },
    body: JSON.stringify(data)
  });
  response.status === 403 && console.log('Problemas con el request');
  return response.json();
};

//* Dlocal --> México (validate coupon Mastercard)
export const validatePromoMastercardMexico = async data => {
  const response = await fetch(`${baseUrl}/api/cards/mc-validation/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}`
    },
    body: JSON.stringify(data)
  });
  response.status === 403 && console.log('Problemas con el request');
  return response.json();
};

//* Stripe --> otros paises
export const payIntentStripe = async data => {
  const response = await fetch(`${baseUrl}/api/stripe/payment-intent/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}`
    },
    body: JSON.stringify(data)
  });
  response.status === 403 && console.log('Problemas con el request Dlocal');
  return response.json();
};

//adding dataJson and change to post request
export const applyPromotionCode = async (promotionCode, data) => {
  const response = await axios.post(
    `${baseUrl}/api/stripe/promotion-codes/${promotionCode}/`,
    data,
    {headers: { Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}` }}
  );
  return response;
};

//* Stripe subscriptions

//? Created a customer

export const createdStripeCustomer = async data => {
  const response = await fetch(`${baseUrl}/api/stripe/customers/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}`
    },
    body: JSON.stringify(data)
  });
  response.status === 403 && console.log('Problemas con el request');
  return response.json();
};

//? make a subscription
export const createdStripeSubscription = async data => {
  const response = await axios.post(`${baseUrl}/api/stripe/subscriptions/`, data, {headers: { Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}` }});
  return response;
};

//* stripe notificate with slack

export const notifyStripeInSlackSuccess = async data => {
  const response = await fetch(`${baseUrl}/api/stripe/payment/notification/success/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}`
    },
    body: JSON.stringify(data)
  });
  response.status === 403 && console.log('Problemas con el request');
  return response.json();
};

export const notifyStripeInSlackError = async data => {
  const response = await fetch(`${baseUrl}/api/stripe/payment/notification/error/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}`
    },
    body: JSON.stringify(data)
  });
  response.status === 403 && console.log('Problemas con el request');
  return response.json();
};
