import dynamic from 'next/dynamic';

import { useUserGeolocalization } from '#hooks/index';
import { parsePathDate, getTime, parseDayString } from '#helpers/parsers';

const CheckScheduleIcon = dynamic(() =>
  import('#helpers/icons/vacation-icons').then(module => module.CheckScheduleIcon)
);

const ItemScheduleVacation = ({
  schedule,
  setSchedule,
  group,
  id,
  mainColor,
  secondaryColor,
  ageRange
}) => {
  const { data: geolocalizationData } = useUserGeolocalization();

  const handleChange = e => {
    setSchedule(e);
  };

  return (
    <div
      className={`item-schedule-vacation item-schedule-vacation--hover-${ageRange}`}
      style={{
        transition: 'all 0.6s ease',
        border:
          schedule === getTime(group.frequency[0].time_start)
            ? `2px solid ${secondaryColor}`
            : '2px solid #F0F0F0'
      }}
    >
      <label className="item-schedule-vacation__content">
        <div
          className="item-schedule-vacation__check"
          style={{
            transition: 'all 0.6s ease',
            background:
              schedule === getTime(group.frequency[0].time_start)
                ? secondaryColor
                : '#F0F0F0'
          }}
        >
          <CheckScheduleIcon fill="#F0F0F0" />
        </div>
        <div className="item-schedule-vacation__details-group">
          <section className="item-schedule-vacation__first-group">
            <p className="item-schedule-vacation__first-text">
              {group.frequency.map(elem => parseDayString(elem.day_name)).join(', ')}
              <br />
              <span className="item-schedule-vacation__first-text--bold">
                {getTime(group.frequency[0].time_start)} -{' '}
                {getTime(group.frequency[0].time_end)} ({geolocalizationData?.country})
              </span>
            </p>
          </section>
          <section className="item-schedule-vacation__first-group">
            <p
              className="item-schedule-vacation__second-text"
              style={{ background: mainColor, color: secondaryColor }}
            >
              Inicia: {parsePathDate(group.start_date)} <br />
            </p>
            <p className="item-schedule-vacation__third-text">
              Finaliza:{' '}
              <span className="item-schedule-vacation__third-text--bold">
                {parsePathDate(group.end_date)}
              </span>
            </p>
          </section>
        </div>
        <input
          className="item-schedule-vacation__input"
          type="radio"
          checked={schedule === getTime(group.frequency[0].time_start)}
          value={getTime(group.frequency[0].time_start)}
          name={`${group.frequency[0].time_start}_${id}`}
          onChange={e => handleChange(e.target.value, group, id)}
        />
      </label>
    </div>
  );
};

export default ItemScheduleVacation;
