import { string, bool } from 'prop-types';

import { Typography, Link } from '#atoms/index';
const DashboardHeroSection = ({ title, content, existVirtualRoom }) => {
  return (
    <div className="dashboard_hero">
      {title && (
        <div className="title_section">
          <Typography variant="h2">{title}</Typography>
          {existVirtualRoom && (
            <Link
              href="http://aprende.crackthecode.la/my/"
              external
              variant="black-w-icon"
            >
              Aula virtual
            </Link>
          )}
        </div>
      )}
      <Typography variant="body">{content}</Typography>
    </div>
  );
};

DashboardHeroSection.propTypes = {
  title: string,
  content: string,
  existVirtualRoom: bool
};

export default DashboardHeroSection;
