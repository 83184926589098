const IconHomeDashboard = ({
  fill = '#000000',
  className,
  dataTestid = 'home-dashboard'
}) => (
  <svg
    className={className}
    data-testid={dataTestid}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={`${className}-path`}
      data-testid={`${dataTestid}-path`}
      d="M5 21C4.45 21 3.979 20.8043 3.587 20.413C3.19567 20.021 3 19.55 3 19V5C3 4.45 3.19567 3.979 3.587 3.587C3.979 3.19567 4.45 3 5 3H9C9.55 3 10.021 3.19567 10.413 3.587C10.8043 3.979 11 4.45 11 5V19C11 19.55 10.8043 20.021 10.413 20.413C10.021 20.8043 9.55 21 9 21H5ZM15 10C14.45 10 13.9793 9.804 13.588 9.412C13.196 9.02067 13 8.55 13 8V5C13 4.45 13.196 3.979 13.588 3.587C13.9793 3.19567 14.45 3 15 3H19C19.55 3 20.021 3.19567 20.413 3.587C20.8043 3.979 21 4.45 21 5V8C21 8.55 20.8043 9.02067 20.413 9.412C20.021 9.804 19.55 10 19 10H15ZM15 21C14.45 21 13.9793 20.8043 13.588 20.413C13.196 20.021 13 19.55 13 19V14C13 13.45 13.196 12.979 13.588 12.587C13.9793 12.1957 14.45 12 15 12H19C19.55 12 20.021 12.1957 20.413 12.587C20.8043 12.979 21 13.45 21 14V19C21 19.55 20.8043 20.021 20.413 20.413C20.021 20.8043 19.55 21 19 21H15Z"
      fill={fill}
    />
  </svg>
);

export default IconHomeDashboard;
