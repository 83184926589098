import { string } from 'prop-types';

import { Link } from '#atoms/index';

const WhitoutCourses = ({ title, description }) => {
  return (
    <div className="whitout-courses-wrapper">
      <img className="whitout-courses-welcome-img" src="/without.svg" />
      <h1 className="whitout-courses-title">{title}</h1>
      <p className="whitout-courses-content">{description}</p>
      <div className="button-wrapper">
        <Link variant="outline-w-icon" href="/cursos">
          Ver cursos
        </Link>
      </div>
    </div>
  );
};

WhitoutCourses.propTypes = {
  title: string.isRequired,
  description: string.isRequired
};

export default WhitoutCourses;
