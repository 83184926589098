import { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
const PhoneInputField = ({
  name,
  classStyles,
  countryDefault,
  placeholder,
  isRequired,
  register
}) => {
  const [phone, setPhone] = useState();

  const handleOnChange = value => {
    setPhone(value);
  };

  const PhoneField = () => (
    <PhoneInput
      inputExtraProps={{
        name: { name },
        required: isRequired,
        autoFocus: true
      }}
      country={'pe'}
      value={phone}
      onChange={handleOnChange}
    />
  );

  return [phone, PhoneField];
};

export default PhoneInputField;
