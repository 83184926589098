import { Paragraph, Button } from '#styleguide/index';

const CalendarButton = ({
  children,
  color,
  variant,
  onClick,
  type,
  disabled,
  testid,
  text,
  handleClick
}) => {
  return (
    <Button
      hasPadding={true}
      padding="10px 10px 10px 8px"
      iconLeft={<img src="/calendarIcon.png" />}
      bgColor="linear-gradient(180deg, #6DF2F2 0%, #E4C6FA 0.01%, #8E84FD 100%)"
      isFullWidth={true}
      onClick={handleClick}
    >
      <Paragraph size="14" weight="700">
        {text}
      </Paragraph>
    </Button>
  );
};

export default CalendarButton;
