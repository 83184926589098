import { node, string } from 'prop-types';
import cx from 'classnames';

const Typography = ({
  children,
  variant = 'h1',
  tag,
  fontWeight,
  color,
  styleClass,
  ...rest
}) => {
  const classNames = cx(variant, {
    [`weight-${fontWeight}`]: fontWeight,
    [`color-${color}`]: color,
    [styleClass]: styleClass
  });

  if (tag) {
    switch (tag) {
      case 'h1':
        return (
          <h1 className={classNames} {...rest}>
            {children}
          </h1>
        );
      case 'h2':
        return (
          <h2 className={classNames} {...rest}>
            {children}
          </h2>
        );
      case 'h3':
        return (
          <h3 className={classNames} {...rest}>
            {children}
          </h3>
        );
      case 'h4':
        return (
          <h4 className={classNames} {...rest}>
            {children}
          </h4>
        );
      case 'p':
        return (
          <p className={classNames} {...rest}>
            {children}
          </p>
        );
      case 'span':
        return (
          <span className={classNames} {...rest}>
            {children}
          </span>
        );
      default:
        return children;
    }
  }

  switch (variant) {
    case 'h1':
    case 'special-h1':
      return <h1 className={classNames}>{children}</h1>;
    case 'h2':
      return <h2 className={classNames}>{children}</h2>;
    case 'h2-small':
      return <h2 className={classNames}>{children}</h2>;
    case 'h3':
      return <h3 className={classNames}>{children}</h3>;
    case 'h3-small':
      return <h3 className={classNames}>{children}</h3>;
    case 'h4-small':
      return <h4 className={classNames}>{children}</h4>;
    case 'h4':
      return <h4 className={classNames}>{children}</h4>;
    case 'h5':
      return <h5 className={classNames}>{children}</h5>;
    case 'h6':
      return <h6 className={classNames}>{children}</h6>;
    case 'jumbo':
    case 'body':
    case 'body2':
      return <p className={classNames}>{children}</p>;
    case 'small':
      return <small className={classNames}>{children}</small>;

    default:
      return children;
  }
};

Typography.propTypes = {
  children: node,
  variant: string,
  color: string,
  fontWeight: string
};

export default Typography;
