import React from 'react';

const SliderBgImg = ({ itemSlider, bgRef, zIndex }) => {
  return (
    <>
      {itemSlider?.cover && itemSlider?.cover_mobile && (
        <div
          ref={bgRef}
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: zIndex,
            top: 0
          }}
        >
          <img
            className="slider-home__image-desktop"
            src={itemSlider?.cover}
            alt=""
            unsized
          />
          <img
            className="slider-home__image-mobile"
            src={itemSlider?.cover_mobile}
            alt=""
            unsized
          />
        </div>
      )}
    </>
  );
};

export default SliderBgImg;
