import { DashboardIcon, FaqIcon } from '#helpers/icons/dashboard-icons';

export const guardianMenu = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: <DashboardIcon />,
    isToggle: false,
    subMenu: {}
  },
  {
    name: 'Preguntas Frecuentes',
    url: '/dashboard/faq',
    icon: <FaqIcon />,
    isToggle: false,
    subMenu: {}
  }
  /* {
    name: 'Programas',
    url: '/dashboard/programas',
    icon: <PathsAndCourseIcon />,
    isToggle: false,
    subMenu: {},
  }, */
  /* {
    name: 'Mis pagos',
    url: '/dashboard/pagos',
    icon: <PaymentIcon />,
    isToggle: false,
    subMenu: {},
  }, */
  /* {
    name: 'Contáctanos',
    url: '/dashboard/contactar-a-soporte',
    icon: <PaymentIcon />,
    isToggle: false,
    subMenu: {},
  }, */
  /* {
    name: 'Configuración',
    url: '/dashboard/configuracion',
    icon: <SettingsIcon />,
    isToggle: false,
    subMenu: {},
  }, */
];
