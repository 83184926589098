import React, { useEffect, useState } from 'react';

export const ConfirmPaymentCard = () => {
  const [showInfo, setShowInfo] = useState(false);
  const [arrowIcon, setArrowIcon] = useState('/arrow-down.svg');

  const boxShadowClass = showInfo ? 'box-shadow' : '';

  useEffect(() => {
    if (showInfo) {
      setArrowIcon('/arrow-up.svg');
      return;
    }
    setArrowIcon('/arrow-down.svg');
  }, [showInfo]);

  return (
    <div className={`confirm-payment-card confirm-payment-card-${boxShadowClass}`}>
      <div className="confirm-payment-card-header">
        <div className="confirm-payment-card-icon-left">
          <img src="/check.svg" />
        </div>
        <div className="confirm-payment-card-title-description-container">
          <div className="confirm-payment-card-title-description">
            <p>Confírmamos tu pago</p>
            <p>Código de transacción: </p>
          </div>
        </div>
        <div className="confirm-payment-card-icon-right">
          <button
            onClick={() => {
              setShowInfo(prev => !prev);
            }}
          >
            <img src={arrowIcon} />
          </button>
        </div>
      </div>
      {showInfo && (
        <div className="confirm-payment-card-body">
          <p>Pago realizado por: </p>
          <p>Total pagado: </p>
          <p>Compra efectuada el: </p>
          <p>
            Hemos enviado un correo con tu comprobante de pago e información importante
            sobre el curso
          </p>
        </div>
      )}
    </div>
  );
};
