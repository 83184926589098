import { TechnicalCardVacation } from '#components/index';

const TechnicalRequirementsVacation = props => {
  const {
 requirements, mainColor, secondaryColor, supportColor, ageRange, program 
} =
    props;

  return (
    <section className="faq_tab_upd-Wrapper">
      <div className="faq_list">
        <TechnicalCardVacation
          requirements={requirements}
          mainColor={mainColor}
          secondaryColor={secondaryColor}
          supportColor={supportColor}
          ageRange={ageRange}
          program={program}
        />
      </div>
    </section>
  );
};

export default TechnicalRequirementsVacation;
