/* eslint-disable react/prop-types */
import { useState } from 'react';
import { func, shape } from 'prop-types';
import { useRouter } from 'next/router';
import cx from 'classnames';

import IconHomeUser from '#app/UI/atoms/icons/homeUser';
import IconProfileUser from '#app/UI/atoms/icons/profileUser';
import IconLogoutUser from '#app/UI/atoms/icons/logoutUser';
import IconHomeDashboard from '#app/UI/atoms/icons/homeDashboard';
import IconEmail from '#app/UI/atoms/icons/email';
import { Link } from '#atoms/index';
import { event, eventTimer } from '#helpers/gtag';

const UserDropdown = ({ data, onClickLogout, isLetterAvatar = false }) => {
  const [showOptions, setShowOptions] = useState(false);
  const userData = JSON.parse(localStorage.getItem('userData'));
  const router = useRouter();

  const eventsAnalytics = () => {
    event(
      'acceso-perfiles',
      'menu-desplegable',
      'clic en banner informativo en zona de padres'
    );
    event(
      userData ? `acceso-perfiles ${userData.email}` : 'acceso-perfiles',
      'menu-desplegable',
      'clic en banner informativo en zona de padres'
    );
    eventTimer(`tiempo-lectura-perfiles ${userData.email}`, 'menu-desplegable');
  };

  return (
    <div className="user-dropdown">
      {/* Trigger */}
      <button
        className={cx('user-dropdown__trigger', {['user-dropdown__trigger--active']: showOptions})}
        onClick={() => setShowOptions(!showOptions)}
      >
        {/* Name */}
        <span className="user-dropdown__trigger_username">{data.firstName}</span>

        {/* Logo */}
        {isLetterAvatar && (
          <div className="user-dropdown__trigger_logo">
            <p className="user-dropdown__trigger_logo_word">{data.firstName[0]}</p>
          </div>
        )}
      </button>

      {/* Options */}
      {showOptions && (
        <div className="user-dropdown__options">
          {router.pathname.includes('/dashboard') ? (
            <div className="user-dropdown__option">
              <IconHomeUser />
              <Link
                href="/"
                gtagActionClick1="acceso-homepage"
                gtagCategoryClick1="menu-desplegable"
                gtagActionClick2={
                  userData ? `acceso-homepage ${userData.email}` : 'acceso-homepage'
                }
                gtagCategoryClick2="menu-desplegable"
              >
                Homepage
              </Link>
            </div>
          ) : (
            <div className="user-dropdown__option">
              <IconHomeDashboard />
              <Link href="/dashboard" eventName="zona_padres_menu">
                Zona padres
              </Link>
            </div>
          )}
          <div className="user-dropdown__option">
            <IconProfileUser />
            <Link href="/login-perfiles" eventName="perfiles_menu">
              <div onClick={eventsAnalytics}>Perfiles</div>
            </Link>
          </div>
          <div className="user-dropdown__option">
            <IconEmail />
            <Link href="/referidos" eventName="referidos_menu">
              Invita a un amigo
            </Link>
          </div>
          <div className="user-dropdown__option">
            <IconLogoutUser />
            <button onClick={onClickLogout}>Cerrar Sesión</button>
          </div>
        </div>
      )}
    </div>
  );
};

UserDropdown.propTypes = {
  data: shape(),
  onClickLogout: func
};

export default UserDropdown;
