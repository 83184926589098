import { useEffect } from 'react';

import { ItemScheduleVacation } from '#components/index';
import { getTime } from '#helpers/parsers';

const ScheduleVacation = ({
  schedule,
  setSchedule,
  groups,
  mainColor,
  secondaryColor,
  supportColor,
  ageRange,
  daysSchedule
}) => {
  useEffect(() => {
    if (groups.length > 0) {
      setSchedule(getTime(groups[0].frequency[1].time_start));
    }
  }, []);

  return (
    <div className="schedule-vacation">
      <h1 className="schedule-vacation__details-title schedule-vacation__details-title--bold">
        Horarios
      </h1>
      {groups.length > 0 ? (
        groups
          ?.map((group, idx) => (
            <ItemScheduleVacation
              key={idx}
              id={idx}
              schedule={schedule}
              setSchedule={setSchedule}
              group={group}
              mainColor={mainColor}
              secondaryColor={secondaryColor}
              supportColor={supportColor}
              ageRange={ageRange}
              daysSchedule={daysSchedule}
            />
          ))
          .sort((a, b) =>
            a.props.group.frequency[0] < b.props.group.frequency[0] ? -1 : 1
          )
      ) : (
        <p className="schedule-vacation__empty-schedule">Sin horarios disponibles</p>
      )}
    </div>
  );
};

export default ScheduleVacation;
