import { shape } from 'prop-types';

import { Image, Typography } from '#atoms/index';
import { parseDate } from '#helpers/parsers';

const BlogHero = ({ data }) => {
  return (
    <section className="blog-hero">
      <div className="blog-hero__image">
        <Image
          src={data.fields.heroImage && data.fields.heroImage.fields.file.url}
          alt={data.fields.title}
        />
      </div>
      <div className="blog-hero__title">
        <Typography variant="h1" tag="h2">
          {data.fields.title}
        </Typography>
      </div>
      <div className="blog-information">
        <img src="/Icono2.svg" alt="Crack The Code" />
        <div className="blog-information__section">
          <h3>Crack the Code</h3>
          <time>{parseDate(data.fields.publishDate)}</time>
        </div>
      </div>
    </section>
  );
};

BlogHero.propTypes = { data: shape() };

export default BlogHero;
