import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton from 'react-loading-skeleton';
import Typography from 'atoms/typography';

const OutlineCard = ({
 title, description, icon, variantCard, img_class 
}) => {
  const classNames =
    variantCard === 'outline-w-images'
      ? 'outline_card_w_images-Wrapper'
      : 'outline_card-Wrapper';

  return (
    <div className={classNames}>
      {variantCard === 'outline-w-images' ? (
        <LazyLoadImage
          class={img_class}
          effect="blur"
          alt={title}
          src={icon}
          placeholder={<Skeleton width="100%" height="100%" />}
        />
      ) : (
        <div>{icon}</div>
      )}

      <Typography variant="regular-6 text-center" tag="h3" fontWeight="bold">
        {title}
      </Typography>
      <Typography variant="regular-7 text-center" tag="p">
        {description}
      </Typography>
    </div>
  );
};

export default OutlineCard;
