import { useState, useRef } from 'react';
import Image from 'next/image';
import dynamic from 'next/dynamic';

import { FaqIconArrow } from '#helpers/icons/path-learning-icons';

const WifiIcon = dynamic(() =>
  import('#helpers/icons/vacation-icons').then(module => module.WifiIcon)
);

const ComputerIcon = dynamic(() =>
  import('#helpers/icons/vacation-icons').then(module => module.ComputerIcon)
);

const TechnicalCardVacation = ({
  requirements,
  mainColor,
  secondaryColor,
  supportColor,
  ageRange,
  program
}) => {
  const content = useRef(null);
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const [setRotate, setRotateState] = useState('technical-card__icon-vacation');

  const toggleAccordion = () => {
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`);
    setRotateState(
      setActive === 'active'
        ? 'technical-card__icon-vacation'
        : 'technical-card__icon-vacation technical-card__icon-vacation--rotate'
    );
  };

  const renderIcon = index => {
    switch (index) {
      case '1_1':
        return <ComputerIcon fill={secondaryColor && secondaryColor} />;
      case '1_2':
        return <WifiIcon fill={secondaryColor && secondaryColor} />;
      case '2_1':
        return (
          <Image
            src="/vacaciones-tech/windows.webp"
            alt={`Imagen requerimiento_${index} | Crack The Code`}
            width={53}
            height={53}
          />
        );
      case '2_2':
        return (
          <Image
            src="/vacaciones-tech/apple.webp"
            alt={`Imagen requerimiento_${index} | Crack The Code`}
            width={53}
            height={53}
          />
        );
      case '3_1':
        return (
          <Image
            src="/vacaciones-tech/chrome.webp"
            alt={`Imagen requerimiento_${index} | Crack The Code`}
            width={53}
            height={53}
          />
        );
      case '3_2':
        return (
          <Image
            src="/vacaciones-tech/firefox.webp"
            alt={`Imagen requerimiento_${index} | Crack The Code`}
            width={53}
            height={53}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={`technical-card__vacation-wrapper ${setActive}`}>
      <h4
        className="technical-card__vacation-title"
        style={{ color: mainColor, backgroundColor: secondaryColor }}
      >
        Requerimientos técnicos
      </h4>

      <div
        ref={content}
        style={{ maxHeight: `${setHeight}`, background: supportColor }}
        className="technical-card__content-vacation"
      >
        <div style={{ height: '54px' }} />
        {program === 0
          ? requirements[0]?.general_program?.map((requirement, idx) => (
              <div key={idx} className="technical-card__vacation-content">
                <div className="technical-card__icons_container">
                  <div className="technical-card__icon">{renderIcon(`${idx + 1}_1`)}</div>
                  <div className="technical-card__icon">{renderIcon(`${idx + 1}_2`)}</div>
                </div>
                <p className="technical-card__text">{requirement}</p>
              </div>
            ))
          : program === 1 && ageRange === '5_to_7'
          ? requirements[0]?.program_5_to_7?.map((requirement, idx) => (
              <div key={idx} className="technical-card__vacation-content">
                <div className="technical-card__icons_container">
                  <div className="technical-card__icon">{renderIcon(`${idx + 1}_1`)}</div>
                  <div className="technical-card__icon">{renderIcon(`${idx + 1}_2`)}</div>
                </div>
                <p className="technical-card__text">{requirement}</p>
              </div>
            ))
          : program === 1 && ageRange === '8_plus'
          ? requirements[0]?.program_8_plus?.map((requirement, idx) => (
              <div key={idx} className="technical-card__vacation-content">
                <div className="technical-card__icons_container">
                  <div className="technical-card__icon">{renderIcon(`${idx + 1}_1`)}</div>
                  <div className="technical-card__icon">{renderIcon(`${idx + 1}_2`)}</div>
                </div>
                <p className="technical-card__text">{requirement}</p>
              </div>
            ))
          : program === 1 && ageRange === '12_plus'
          ? requirements[0]?.program_12_plus?.map((requirement, idx) => (
              <div key={idx} className="technical-card__vacation-content">
                <div className="technical-card__icons_container">
                  <div className="technical-card__icon">{renderIcon(`${idx + 1}_1`)}</div>
                  <div className="technical-card__icon">{renderIcon(`${idx + 1}_2`)}</div>
                </div>
                <p className="technical-card__text">{requirement}</p>
              </div>
            ))
          : ''}
        <div style={{ height: '54px' }} />
      </div>
      <div
        className="technical-card__show-details"
        style={{ background: mainColor, color: secondaryColor }}
      >
        {setActive === 'active' ? 'Ocultar detalles' : 'Mostrar detalles'}
        <button className="technical-card__accordion-vacation" onClick={toggleAccordion}>
          <FaqIconArrow
            className={`${setRotate}`}
            fill={secondaryColor ? secondaryColor : '#fff'}
          />
        </button>
      </div>
    </div>
  );
};

export default TechnicalCardVacation;
